import { Container } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useGetV1DashboardPeriodicActivityQuery,
  useGetV1DashboardTopTradingPartnersQuery,
  useGetV1DashboardTransactionTotalQuery,
  NormalizedDocumentType
} from '../../api/dashboard.api';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { PageTitleProps } from '../../components/atoms/PageTitle';
import { DataCardProps } from '../../components/molecules/DataCard';
import DashboardTemplate from '../../components/organisms/DashboardTemplate';
import { selectTenantId, selectDateFilterInterval, selectDateFilterTitle } from '../app/AppSlice';
import { clearException, clearOrder } from '../purchaseOrders/PurchaseOrdersSlice';
import { getDocTypeAmount, getDocTypeCount, getDocTypeCurrency, mapTopTradingPartners, mapActivityData } from './DashboardAPI';
import { getSummaryTotalDisplayValue } from '../../utils/helpers/currencyFormatUtil';

export const Dashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const tenantId = useAppSelector(selectTenantId);
  const dateFilterInterval = useAppSelector(selectDateFilterInterval);
  const dateFilterTitle = useAppSelector(selectDateFilterTitle);
  const TOP_TRADING_PARTNERS_CHART_COUNT = 4;

  const { data: transactionsData } = useGetV1DashboardTransactionTotalQuery(
    {
      tenantId: tenantId as string,
      startDate: dateFilterInterval.start.toISO(),
      endDate: dateFilterInterval.end.toISO(),
    },
    {
      skip: !tenantId,
    },
  );

  const { data: topTradingPartnersData } = useGetV1DashboardTopTradingPartnersQuery(
    {
      tenantId: tenantId as string,
      startDate: dateFilterInterval.start.toISO(),
      endDate: dateFilterInterval.end.toISO(),
      count: TOP_TRADING_PARTNERS_CHART_COUNT,
    },
    {
      skip: !tenantId,
    },
  );

  const { data: activityQueryData } = useGetV1DashboardPeriodicActivityQuery(
    {
      tenantId: tenantId as string,
      startDate: dateFilterInterval.start.toISO(),
      endDate: dateFilterInterval.end.toISO(),
    },
    {
      skip: !tenantId,
    },
  );

  useEffect(() => {
    dispatch(clearOrder());
    dispatch(clearException());
  }, []);

  const purchaseOrders: PageTitleProps = {
    title: translate('purchaseOrders.title'),
  };
  const invoices: PageTitleProps = {
    title: translate('invoices.title'),
  };
  const shipments: PageTitleProps = {
    title: translate('shipments.title'),
  };

  const getCurrencyDisplay = (docType: NormalizedDocumentType) => {
    let currency = getDocTypeCurrency(transactionsData, docType);
    let total = getDocTypeAmount(transactionsData, docType);
    let formattedDisplay = getSummaryTotalDisplayValue(total, currency);
    return formattedDisplay;
  };

  const purchaseOrdersCard: DataCardProps = {
    header: dateFilterTitle,
    label1: translate('purchaseOrders.title') as string,
    value1: `${getDocTypeCount(transactionsData, 'purchaseOrder')}`,
    label2: translate('cardSummary.totalValue') as string,
    value2: getCurrencyDisplay('purchaseOrder'),
    onClick: () => {
      navigate('/purchase-orders');
    },
  };
  const invoicesCard: DataCardProps = {
    header: dateFilterTitle,
    label1: translate('invoices.title') as string,
    value1: `${getDocTypeCount(transactionsData, 'invoice')}`,
    label2: translate('cardSummary.totalValue') as string,
    value2: getCurrencyDisplay('invoice'),
    onClick: () => {
      navigate('/invoices');
    },
  };
  const ShipmentsCard: DataCardProps = {
    header: dateFilterTitle,
    label1: translate('shipments.title') as string,
    value1: `${getDocTypeCount(transactionsData, 'shipment')}`,
    onClick: () => {
      navigate('/shipments');
    },
    keepDashStyle: true
  };

  const topTradingPartners = mapTopTradingPartners(topTradingPartnersData);
  const activityData = mapActivityData(activityQueryData);

  const onChartClick = () => {
    navigate('/trading-partners');
  };

  const onTradingPartnerClick = (id: string) => {
    navigate(`/trading-partners/tradingPartner-${id}`);
  };

  const onActivityClick = (docType: NormalizedDocumentType, activityDate?: string) => {
    const datePath = activityDate ? `/date-${activityDate}` : "";
    
    let navigatePath = "";
    if (docType === "purchaseOrder") {
      navigatePath = '/purchase-orders';
    }
    else if (docType === "invoice") {
      navigatePath = '/invoices';
    }
    else if (docType === "shipment") {
      navigatePath = '/shipments';
    }

    if (navigatePath) {
      // navigate to the given docType's list
      navigate(`${navigatePath}${datePath}`);
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <DashboardTemplate
        dateFilterTitle={dateFilterTitle}
        pageTitle1={purchaseOrders}
        pageTitle2={invoices}
        pageTitle3={shipments}
        dataCard1={purchaseOrdersCard}
        dataCard2={invoicesCard}
        dataCard3={ShipmentsCard}
        topTradingPartnersData={topTradingPartners}
        activityData={activityData}
        onChartClick={onChartClick}
        onTradingPartnerClick={onTradingPartnerClick}
        onActivityClick={onActivityClick}
      />
    </Container>
  );
};
