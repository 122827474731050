import { Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { NormalizedDocumentType } from '../../api/dashboard.api';
import { PageTitle, PageTitleProps } from '../../components/atoms/PageTitle';
import ActivityChart, { ActivityChartData } from '../molecules/ActivityChart';
import DataCard, { DataCardProps } from '../molecules/DataCard';
import TopTradingPartnersChart, { TopTradingPartnersChartData } from '../molecules/TopTradingPartnersChart';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  }),
);

interface DashboardTemplateProps {
  dateFilterTitle: string;
  //PageHeaderProps
  pageTitle1: PageTitleProps;
  pageTitle2: PageTitleProps;
  pageTitle3?: PageTitleProps;
  //DataCardProps
  dataCard1: DataCardProps;
  dataCard2: DataCardProps;
  dataCard3?: DataCardProps;
  //TopTradingPartnersChartProps
  topTradingPartnersData: TopTradingPartnersChartData[];
  onChartClick: () => void;
  onTradingPartnerClick: (id: string) => void;
  //ActivityChartProps
  activityData: ActivityChartData[];
  onActivityClick: (docType: NormalizedDocumentType, activityDate?: string) => void;
}

const DashboardTemplate: React.FC<DashboardTemplateProps> = props => {
  const {
    dateFilterTitle,
    pageTitle1,
    pageTitle2,
    pageTitle3,
    dataCard1,
    dataCard2,
    dataCard3,
    topTradingPartnersData,
    onTradingPartnerClick,
    activityData,
    onActivityClick,
    onChartClick,
  } = props;
  const classes = useStyles();
  const mdCardCols = (pageTitle3 && dataCard3) ? 4 : 6;
  return (
    <Grid container className={classes.root} columnSpacing={{ xs: 0, md: 6, lg: 12 }} rowSpacing={3}>
      <Grid item container xs={12} md={mdCardCols}>
        <Grid item container spacing={1} xs={12} justifyContent={{ xs: 'center', md: 'flex-start' }}>
          <Grid item xs={12}>
            <PageTitle {...pageTitle1} />
            <DataCard {...dataCard1} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={mdCardCols}>
        <Grid item container spacing={1} xs={12} justifyContent={{ xs: 'center', md: 'flex-start' }}>
          <Grid item xs={12}>
            <PageTitle {...pageTitle2} />
            <DataCard {...dataCard2} />
          </Grid>
        </Grid>
      </Grid>
      {pageTitle3 && dataCard3 && (
        <Grid item container xs={12} md={mdCardCols}>
        <Grid item container spacing={1} xs={12} justifyContent={{ xs: 'center', md: 'flex-start' }}>
          <Grid item xs={12}>
            <PageTitle {...pageTitle3} />
            <DataCard {...dataCard3} />
          </Grid>
        </Grid>
      </Grid>
      )}
      <Grid item container xs={12} md={6} justifyContent="center">
        <TopTradingPartnersChart
          data={topTradingPartnersData}
          onChartClick={onChartClick}
          onTradingPartnerClick={onTradingPartnerClick}
        />
      </Grid>
      <Grid item container xs={12} md={6} justifyContent="center">
        <ActivityChart 
          data={activityData} 
          dateFilterTitle={dateFilterTitle} 
          onActivityClick={onActivityClick}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardTemplate;
