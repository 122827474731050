import { TradePartner } from '../../utils/types/type-declarations';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { TransactionResource } from '../../api/customers.api';

export interface TradingPartnerState {
  partner?: TradePartner;
  status: 'idle' | 'loading' | 'failed';
  error?: Error;
  tradingPartnerId: string;
  tradingPartnerName: string;
  transactions: TransactionResource[];
  transactionPaginationCount: number;
}

const initialState: TradingPartnerState = {
  partner: undefined,
  status: 'idle',
  error: undefined,
  tradingPartnerId: '',
  tradingPartnerName: '',
  transactions: [],
  transactionPaginationCount: 0,
};

export const slice = createSlice({
  name: 'tradingPartner',
  initialState,
  reducers: {
    fetchError: (state, action: PayloadAction<Error>) => {
      // eslint-disable-next-line no-param-reassign
      state.error = action.payload;
    },
    clearError: state => {
      // eslint-disable-next-line no-param-reassign
      state.error = undefined;
    },
    clearState: state => {
      // eslint-disable-next-line no-param-reassign
      state.partner = undefined;
      // eslint-disable-next-line no-param-reassign
      state.status = 'idle';
      // eslint-disable-next-line no-param-reassign
      state.error = undefined;
      // eslint-disable-next-line no-param-reassign
    },  
    setTradingPartnerId: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.tradingPartnerId = action.payload;
    },
    setTradingPartnerName: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.tradingPartnerName = action.payload;
    },
    setTransactions: (state, action: PayloadAction<TransactionResource[]>) => {
      state.transactions = action.payload;
    },
    setTransactionPaginationCount: (state, action: PayloadAction<number>) => {
      state.transactionPaginationCount = action.payload;
    },
  },
});

export const selectError = (state: RootState): Error | undefined => state.tradingPartner.error;
export const selectTradingPartner = (state: RootState): TradePartner | undefined => state.tradingPartner.partner;
export const selectTradingPartnerId = (state: RootState): string => state.tradingPartner.tradingPartnerId;
export const selectTradingPartnerName = (state: RootState): string => state.tradingPartner.tradingPartnerName;
export const selectTradingPartnerTransactions = (state: RootState): TransactionResource[] => state.tradingPartner.transactions;
export const selectTradingPartnerTransactionCount = (state: RootState): number => state.tradingPartner.transactionPaginationCount;

export const { 
  fetchError, 
  clearError,
  clearState, 
  setTradingPartnerId, 
  setTradingPartnerName,
  setTransactions,
  setTransactionPaginationCount
} = slice.actions;

export default slice.reducer;
