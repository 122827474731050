import { useState } from 'react';
import { Theme, IconButton, Avatar, Menu, Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import PersonIcon from '@mui/icons-material/Person';
import { PortalUserResource } from '../../api/users.api';
import { isApteanSSOProvider } from '../../utils/security';
import SettingsMenuApteanSSO from '../organisms/SettingsMenuApteanSSO';
import SettingsMenuAuth0 from '../organisms/SettingsMenuAuth0';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    settingsBox: {
      width: 400,
    },
  }),
);

interface UserSettingsMenuProps {
  viewerUser?: PortalUserResource;
}

export const UserSettingsMenu: React.FC<UserSettingsMenuProps> = props => {
  const classes = useStyles();
  const { viewerUser } = props;
  const [anchorEl, setAnchorEl] = useState<Element | null>();
   
  const handleClick = (event: { currentTarget: Element }) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  let initials = <PersonIcon />;
  if (viewerUser) {
      let userInitials = viewerUser.firstName ? viewerUser.firstName[0] : '';
      userInitials += viewerUser.lastName ? viewerUser.lastName[0] : '';
      initials = <span>{userInitials}</span>;
  }

  return (
    <>
      <IconButton onClick={handleClick} title="User Settings" data-cy="user-settings">
          <Avatar data-cy="user-avatar">{initials}</Avatar>
      </IconButton>
      <Menu
          anchorEl={anchorEl}
          id="menu-appbar"
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ disablePadding: true }}
          marginThreshold={0}
          variant="menu"
      >
          <Box className={classes.settingsBox}>
          {isApteanSSOProvider() ? (
                  <SettingsMenuApteanSSO
                      afterClick={handleClose}
                      viewerUser={{
                          firstName: viewerUser?.firstName ? viewerUser?.firstName : '',
                          lastName: viewerUser?.lastName ? viewerUser?.lastName : '',
                          email: viewerUser?.email ? viewerUser?.email : '',
                          id: viewerUser?.portalUserId ? viewerUser?.portalUserId : '',
                      }}
                  />
          
              ) : (
                  <SettingsMenuAuth0 
                      afterClick={handleClose}
                      viewerUser={{
                          firstName: viewerUser?.firstName ? viewerUser?.firstName : '',
                          lastName: viewerUser?.lastName ? viewerUser?.lastName : '',
                          email: viewerUser?.email ? viewerUser?.email : '',
                          id: viewerUser?.portalUserId ? viewerUser?.portalUserId : '',
                      }}
                  />
              )
          }
          </Box>
      </Menu>
    </>
  );
};
