import { UserForCustomer, UserRole, PortalUserCustomerSubscriptionResource, PortalSubscriptionResource } from '../../api/users.api'
export interface UserInfoRow {
  name: string;
  email: string;
  id: string;
  commerceRole: UserRole | undefined;
  ediRole: UserRole | undefined;
}

export interface SubscriptionInfoRow {
  tenantId: string | undefined;
  tenantName: string | null | undefined;
  id: string;
}


export const makeDataRowsFromUsers = (users: UserForCustomer[]) => {
  let ret = [] as UserInfoRow[];
  if (users) {
    let i = 0;
    for (i; i < users.length; ++i) {
      let commerceRole = users[i].role;
      let ediRole = users[i].subscriptions?.[0]?.role;
      ret.push({
        name: users[i].firstName + ' ' + users[i].lastName,
        email: users[i].email || '',
        commerceRole: commerceRole,
        ediRole: ediRole,
        id: users[i].id || '',
      });
    }
  }

  return ret;
};

export const makeDataRowsFromSubscriptions = (subs: PortalSubscriptionResource[]) => {
  let ret = [] as SubscriptionInfoRow[];
  if (subs) {
    let i = 0;
    for (i; i < subs.length; ++i) {
      let sub = subs[i];
      ret.push({
        tenantId: sub?.tenantId,
        tenantName: sub?.tenantName,
        id: sub?.tenantId as string,
      });
    }
  }
  return ret;
}
