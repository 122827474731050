import React from 'react';
import { useTheme } from '@mui/system';
import { Chart } from 'devextreme-react';
import { EventInfo } from 'devextreme/events';
import { ArgumentAxis, CommonSeriesSettings, Font, Label, Legend, Series, Title, Tooltip } from 'devextreme-react/chart';
import { NormalizedDocumentType } from '../../api/dashboard.api';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { getShortDateStringFromDateTime, getShortDateString } from '../../utils/helpers/dateTimeUtil';

export interface ActivityChartData {
  date: string;
  orders: number;
  shipments: number;
  invoices: number;
}

export interface ActivityChartProps {
  data: ActivityChartData[];
  dateFilterTitle: string;
  onActivityClick: (docType: NormalizedDocumentType, activityDate?: string, ) => void;
}

const ActivityChart: React.FC<ActivityChartProps> = props => {
  const { data, dateFilterTitle, onActivityClick } = props;
  const theme = useTheme();  
  const { t: translate } = useTranslation();
  const colors = [theme.palette.chartOrders.main, theme.palette.chartShipments.main, theme.palette.chartInvoices.main];
  
  const onDrawn = (eventInfo: EventInfo<any>) => {
    // add cursor pointer to chart points
    eventInfo.element.querySelectorAll(".dxc-markers rect").forEach((el: any) => {  
      el.style.cursor = "pointer";  
    });    
  };

  const onPointClick = (pointInfo: any) => {
    const point = pointInfo.target;
    if (point) {
      if (point.isSelected()) {
        // unselect
        point.clearSelection();
      } else {
        // set selected
        point.select();

        // get the selected series
        var selectedSeriesName = point.series?.name;
        
        // get the x-axis (argument) value to open the selected series detail for that value
        var activityDate = point.argument;
        var isoActivityDate = new Date(activityDate).toISOString();
        
        // open order list for specific date
        if (selectedSeriesName === "Orders") {
          onActivityClick("purchaseOrder", isoActivityDate);
        } else if (selectedSeriesName === "Invoices") {
          onActivityClick("invoice", isoActivityDate);
        } else if (selectedSeriesName === "Shipments") {
          onActivityClick("shipment", isoActivityDate);
        }
      }
    }
  }

  const onPointHoverChanged = (pointInfo: any) => {
    // doing hide/show of tooltip rather than just enabling in the Tooltip
    // element to prevent a tooltip from showing when value is 0
    const point = pointInfo.target;
    if (point) {
      if (point.isHovered()) {
        if (point.value > 0) {
          point.showTooltip();
        } else {
          point.hideTooltip();
        }
      } else {
        point.hideTooltip();
      }
    }
  }

  const customizeTooltip = (pointInfo: any) => {
    return {
      text: `${pointInfo.seriesName}:<br/>${pointInfo.argumentText}<br/>${pointInfo.valueText}`,
    };
  }

  const dateFormatter = (object: any) => {
    if (object.value) {
      let date = new Date(object.value);
      let dateTime = DateTime.fromJSDate(date);
      return getShortDateStringFromDateTime(dateTime);
    }
    return object.valueText;
  }

  return (
    <Chart 
      id="activityChart" 
      dataSource={data} 
      palette={colors}
      onDrawn={onDrawn}
      onPointHoverChanged={onPointHoverChanged} 
      onPointClick={onPointClick}
    >
      <Title text={`${translate('dashboard.activityGraphHeader')} ${dateFilterTitle}`} horizontalAlignment="center">
        <Font color={theme.palette.header.main} weight={400} size="1.5rem" />
      </Title>
      <ArgumentAxis>
        <Label customizeText={dateFormatter}>
          <Font color={theme.palette.header.main} size="1rem" weight={500} />
        </Label>
      </ArgumentAxis>
      <CommonSeriesSettings argumentField="date" type="bar"/>
      <Series valueField="orders" name={translate('grids.orders')} />
      <Series valueField="shipments" name={translate('grids.shipments')} />
      <Series valueField="invoices" name={translate('grids.invoices')} />
     
      <Tooltip
        customizeTooltip={customizeTooltip}
      />
      <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right" />
    </Chart>
  );
};

export default ActivityChart;
