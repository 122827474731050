import { useTranslation } from "react-i18next";

export const useDataGridNoDataText = (isGridBusy?: boolean) => {
  const { t: translate } = useTranslation();
  
  // hide No Data by clearing the text since
  // it displays briefly prior to the fetch for data
  if (isGridBusy) {
      return "";
  } else {
      return translate('grids.noData');
  }
};
