import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionResource } from '../../api/customers.api';
import { RootState } from "../../app/store";

export interface AcknowledgementsState {
    acknowledgements: TransactionResource[];
    acknowledgementsPaginationCount: number;    
    todaysAcknowledgements: TransactionResource[];
    filteredAcknowledgements: TransactionResource[];
    status: 'idle' | 'loading' | 'failed';
    acknowledgement?: TransactionResource;
    acknowledgementId?: string;
    error?: Error;
}

const initialState: AcknowledgementsState = {
    acknowledgements: [],
    acknowledgementsPaginationCount: 0,
    todaysAcknowledgements: [],
    filteredAcknowledgements: [],
    status: 'idle',
    acknowledgement: undefined,
    acknowledgementId: undefined,
    error: undefined
}

export const slice = createSlice({
    name: 'acknowledgements',
    initialState,
    reducers: {
        clearAcknowledgement: state => {
            if (state.acknowledgement) {
                // eslint-disable-next-line no-param-reassign
                state.acknowledgement = undefined;
            }
            if (state.acknowledgementId) {
                // eslint-disable-next-line no-param-reassign
                state.acknowledgementId = undefined;
            }
        },
        filterAcknowledgements: (state, action: PayloadAction<{ value: string | undefined, period: 'today' | 'quarterly' }>) => {
            const { value, period } = action.payload;
            if (!value) {
              state.filteredAcknowledgements = [];
              return;
            }
            if (period === 'today') {
              state.filteredAcknowledgements = state.todaysAcknowledgements.filter((acknowledgement: TransactionResource) => acknowledgement.id?.includes(value));
            } else {
              state.filteredAcknowledgements = state.acknowledgements.filter((acknowledgement: TransactionResource) => acknowledgement.id?.includes(value));
            }
          },
          setAcknowledgements: (state, action: PayloadAction<TransactionResource[]>) => {
            state.acknowledgements = action.payload;
          },
          setAcknowledgementsPaginationCount: (state, action: PayloadAction<number>) => {
            state.acknowledgementsPaginationCount = action.payload;
          },
    },
    extraReducers: builder => {},
  });

export const selectAcknowledgements = (state: RootState): TransactionResource[] => state.acknowledgements.acknowledgements;
export const selectAcknowledgementsPaginationCount = (state: RootState): number => state.acknowledgements.acknowledgementsPaginationCount;
export const selectFilteredAcknowledgements = (state: RootState): TransactionResource[] => state.acknowledgements.filteredAcknowledgements;

export const {
    clearAcknowledgement,
    filterAcknowledgements,
    setAcknowledgements,
    setAcknowledgementsPaginationCount
} =  slice.actions;

export default slice.reducer;