import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { createStyles, makeStyles } from '@mui/styles';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../utils/hooks/useDebounce';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Autocomplete, Button, ButtonGroup, ListItem, TextField, Tooltip } from '@mui/material';
import {
  setSelectedOrder,
  changeSearchTerm,
  selectSearchTerm,
  clearSearchResults,
  setDisplaySearchResults,
  toggleSearchResultsDialog,
  selectSelectedPurchaseOrder,
  selectTenantId,
  setSearchResults,
  selectSearchResults,
} from '../../features/app/AppSlice';
import { PurchaseOrderResource, useGetV1PurchaseOrdersQuery } from '../../api/purchaseOrders.api';

const useStyles = makeStyles(() =>
  createStyles({
    autocomplete: {
      width: 250,
      '& .MuiOutlinedInput-root': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    searchButton: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  }),
);

interface GlobalSearchAutoCompleteProps {
  onMobileSearch?: () => void;
}

export const GlobalSearchAutocomplete: FC<GlobalSearchAutoCompleteProps> = props => {
  const { onMobileSearch } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<boolean>(false);
  const tenantId = useAppSelector(selectTenantId);
  const searchTerm = useAppSelector(selectSearchTerm);
  const debouncedSearchTerm = useDebounce<string>(searchTerm);
  const selectedPurchaseOrder = useAppSelector(selectSelectedPurchaseOrder);
  const searchResultOptions = useAppSelector(selectSearchResults);
  const { data: searchResults, isSuccess, isFetching } = useGetV1PurchaseOrdersQuery(
    { tenantId: tenantId as string, orderNumber: debouncedSearchTerm },
    { skip: !tenantId || debouncedSearchTerm.length < 3 || searchTerm.length < 3 || searchTerm === selectedPurchaseOrder?.number || searchTerm === selectedPurchaseOrder?.erpOrderNumber},
  );
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (isSuccess && searchResults) {
      dispatch(setSearchResults(searchResults));
    }
  }, [searchResults, isSuccess, dispatch]);

  const onSearchTermChange = (_: SyntheticEvent, value: string): void => {
    setDisplaySearchResults(false);
    if (error) {
      setError(false);
    }
    dispatch(changeSearchTerm(value));
    if (value.length < 3 && !error) {
      setError(true);
    }
  };

  const toggleDialogState = () => {
    if (searchResultOptions?.length === 1) {
      dispatch(setSelectedOrder(searchResultOptions[0]));
      dispatch(setDisplaySearchResults(true));
      navigate('/transactions');
      return;
    }
    dispatch(toggleSearchResultsDialog());
  };

  const getSelectedInputDisplay = (option: PurchaseOrderResource) => {
    let display = option?.number ?? '';
    if (option.erpOrderNumber && option.erpOrderNumber.toLowerCase().startsWith(searchTerm.toLowerCase())) {
      display = option.erpOrderNumber;
    }
    return display;
  };

  const getSearchResultItemDisplay = (option: PurchaseOrderResource) => {
    let display = option.number;
    if (option.erpOrderNumber) {
      display = `${display} / ${option.erpOrderNumber}`;
    }
    return display;
  };

  return (
    <ButtonGroup sx={{ sm: { display: 'none' } }}>
      <Tooltip
        open={error}
        title="Enter at least 3 characters"
        id="order-number-search-tooltip"
        arrow
        placement={onMobileSearch ? 'top' : 'left'}
        componentsProps={{
          tooltip: { sx: { backgroundColor: 'red', height: 35, display: 'flex', alignItems: 'center' } },
          arrow: { sx: { color: 'red' } },
        }}
      >
        <Autocomplete
          id="order-number"
          size="small"
          clearOnBlur={false}
          className={classes.autocomplete}
          value={selectedPurchaseOrder}
          loading={isFetching}
          filterOptions={x => x}
          inputValue={searchTerm}
          onBlur={() => setError(false)}
          onChange={(_, order) => {
            dispatch(setSelectedOrder(order));
            dispatch(setDisplaySearchResults(true));
            navigate('/transactions');
            if (onMobileSearch) {
              onMobileSearch();
            }
          }}
          componentsProps={{
            clearIndicator: {
              onClick: () => {
                dispatch(setSelectedOrder(null));
                dispatch(setDisplaySearchResults(false));
                dispatch(changeSearchTerm(''));
                dispatch(clearSearchResults());
              },
            },
          }}
          onInputChange={onSearchTermChange}
          options={searchResultOptions}
          noOptionsText={translate('header.searchNoResults')}
          getOptionLabel={option => getSelectedInputDisplay(option)}
          renderOption={(props: any, option) => {
            props.key = props.id;
            let displayValue = getSearchResultItemDisplay(option);
            return (
              <ListItem {...props}>{displayValue}</ListItem>
            );
          }}
          renderInput={params => (
            <TextField {...params} label={translate('header.searchEmptyText')} placeholder={translate('header.searchMinCharacters') as string} error={error} />
          )}
          onKeyDown={event => {
            if (['enter', 'Enter', 'NumpadEnter'].includes(event.code) && searchTerm.length >= 3) {
              toggleDialogState();
            }
          }}
        />
      </Tooltip>

      <Button
        variant="contained"
        onClick={toggleDialogState}
        className={classes.searchButton}
        aria-label="Search by order number"
        disabled={searchTerm.length < 3}
        form="order-number"
      >
        <SearchIcon />
      </Button>
    </ButtonGroup>
  );
};
