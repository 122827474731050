import React, { useEffect } from 'react';
import { useTheme } from '@mui/system';
import { Chart } from 'devextreme-react';
import { EventInfo } from 'devextreme/events';
import { ArgumentAxisClickEvent } from 'devextreme/viz/chart';
import { ArgumentAxis, CommonSeriesSettings, Font, Label, Legend, Series, Title } from 'devextreme-react/chart';
import { useTranslation } from 'react-i18next';

export interface TopTradingPartnersChartData {
  partner: string;
  po: number;
  invoice: number;
  id: string;
}

export interface TopTradingPartnersChartProps {
  data: TopTradingPartnersChartData[];
  onChartClick: () => void;
  onTradingPartnerClick: (id: string) => void;
}

const TopTradingPartnersChart: React.FC<TopTradingPartnersChartProps> = props => {
  const { data, onChartClick, onTradingPartnerClick } = props;
  const theme = useTheme();
  const { t: translate } = useTranslation();
  const colors = [theme.palette.chartInvoices.main, theme.palette.chartOrders.main];

  const onPartnerClick = (event: ArgumentAxisClickEvent) => {
    const partnerName = event.argument;
    const tradingPartnerDocument = data.find(e => e.partner === partnerName);

    if (tradingPartnerDocument) {
      onTradingPartnerClick(tradingPartnerDocument.id);
    }
  };

  const navigateToTopTradingPartners = () => onChartClick();

  const onDrawn = (eventInfo: EventInfo<any>) => {
    const el = document.querySelector('.dxc-title');
    el?.setAttribute('tabindex', '0');
    el?.addEventListener('click', navigateToTopTradingPartners);
    el?.addEventListener('keydown', (e) => {
      ['enter', 'Enter', 'NumpadEnter'].includes((e as KeyboardEvent).code) && onChartClick();
    });
    // add cursor pointer to title
    const style = el?.children[0].getAttribute('style');
    if (!style?.includes('cursor: pointer;')) {
      el?.children[0].setAttribute('style', style + 'cursor: pointer;');
    }
    // add cursor pointer to chart points
    eventInfo.element.querySelectorAll(".dxc-markers rect").forEach((el: any) => {  
      el.style.cursor = "pointer";  
    });  
    
    const chartArgs = document.querySelectorAll('#topTradingPartnersChart .dxc-arg-elements text');
    chartArgs.forEach((arg) => {
      arg.setAttribute('tabindex', '0');
      arg.addEventListener('keydown', (e) => {
        if (['enter', 'Enter', 'NumpadEnter'].includes((e as KeyboardEvent).code)) {
          const tradingPartnerDocument = data.find(e => e.partner === arg.textContent);

          if (tradingPartnerDocument) {
            onTradingPartnerClick(tradingPartnerDocument.id);
          }
        }
      });
    })
  };

  useEffect(() => {
    return () => {
      const el = document.querySelector('.dxc-title');
      el?.removeEventListener('click', navigateToTopTradingPartners);
    };
  }, []);

  return (
    <Chart
      id="topTradingPartnersChart"
      dataSource={data}
      rotated={true}
      palette={colors}
      onArgumentAxisClick={onPartnerClick}
      onPointClick={event => {
        const { target } = event;
        const {
          // TODO: add logic to take users to screens sorted by invoice/po values
          // originalArgument,
          // series: { name },
          data,
        } = target;
        onTradingPartnerClick(data.id);
      }}
      onDrawn={onDrawn}
    >
      <Title text={translate('dashboard.tradingPartnerGraphHeader')} horizontalAlignment="center">
        <Font color={theme.palette.header.main} weight={400} size="1.5rem" />
      </Title>
      <ArgumentAxis>
        <Label>
          <Font color={theme.palette.primary.main} size="1rem" weight={500} />
        </Label>
      </ArgumentAxis>
      <CommonSeriesSettings argumentField="partner" type="bar" />
      <Series valueField="invoice" name={translate("documentTypes.invoice") + " $"} />
      <Series valueField="po" name={translate("documentTypes.purchaseOrder") + " $"} />
      <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right" />
    </Chart>
  );
};

export default TopTradingPartnersChart;
