import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Grid, Link, Theme, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FC, useEffect, useState } from 'react';
import { PortalUserResource } from '../../api/users.api';

import ApteanEDIWordmarkBlack from '../../logos/ApteanEDIWordmarkBlack.svg';
import ApteanEDIWordmarkWhite from '../../logos/ApteanEDIWordmarkWhite.svg';
import ApteanLogoBlack from '../../logos/ApteanLogoBlack.svg';
import ApteanLogoWhite from '../../logos/ApteanLogoWhite.svg';

import { UserSettingsMenu } from '../molecules/UserSettingsMenu';
import { useAppSelector } from '../../app/hooks';
import { useClearActiveCustomerTenant } from '../../utils/hooks/useClearActiveCustomerTenant';
import { selectCustomerId } from '../../features/app/AppSlice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flex: '1 0 auto',
            zIndex: theme.zIndex.drawer + 1, // make toolbar sit above navigation menu
            position: 'relative',
        },
        appBar: {
            boxShadow: '0px 3px 6px #00000012',
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 12,
            [theme.breakpoints.up('xl')]: {
                paddingRight: theme.spacing(12),
            },
            [theme.breakpoints.down('xl')]: {
                paddingRight: theme.spacing(5),
            },
            [theme.breakpoints.down('sm')]: {
                paddingRight: theme.spacing(2),
            },
        },
        titleLink: {
            display: 'inline-flex',
            alignItems: 'center',
        },
        apteanLogo: {
            height: '30px',
            marginRight: theme.spacing(1),
            cursor: 'pointer',
        },
        apteanTitle: {
            color: 'black',
            marginTop: '5px',
            display: 'inline-block',
            height: '25px',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                height: '18px',
            },
        },
        mdIndexGrow: {
            zIndex: theme.zIndex.drawer + 1, // make toolbar sit above navigation menu
            position: 'relative',
        },
        settingsBox: {
            width: 400,
        },
        companyName: {
            opacity: 0.6,
            fontWeight: 500,
            fontSize: '30px',
            marginTop: '1px',
        },
        navigationMenu: {
            marginRight: 6,
        },
        dateFilterButton: {
            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing(0, 2),
                height: 40,
            },
        },
        globalSearchDrawer: {
            zIndex: theme.zIndex.drawer + 2, // make drawer sit above toolbar
        },
        globalSearchDrawerBox: {
            padding: theme.spacing(8, 0, 1),
            display: 'flex',
            justifyContent: 'center',
        },
    }),
);

interface CustomersHeaderProps {
    viewerUser?: PortalUserResource;
}

export const CustomersHeader: FC<CustomersHeaderProps> = props => {
    const { viewerUser } = props;
    const classes = useStyles();
    const navigate = useNavigate();
    const clearActiveCustomerTenant = useClearActiveCustomerTenant();
    const { t: translate } = useTranslation();
    const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const themeMode = useTheme().palette.mode;
    const [apteanLogo, setApteanLogo] = useState<string>(ApteanLogoBlack);
    const [ediLogo, setEdiLogo] = useState<string>(ApteanEDIWordmarkBlack);
    
    const customerId = useAppSelector(selectCustomerId);
  
    useEffect(() => {
        if (themeMode) {
            if (themeMode === "dark") {
                setApteanLogo(ApteanLogoWhite);
                setEdiLogo(ApteanEDIWordmarkWhite);
            } else {
                setApteanLogo(ApteanLogoBlack);
                setEdiLogo(ApteanEDIWordmarkBlack);
            }
        }
    }, [themeMode]);

    const onLogoClick = () => {
        //remove tenant id and customer from local storage and state to show customers/tenant selection page
        clearActiveCustomerTenant(true);
        
        // then redirect to home screen that should by default load customers
        navigate("/");
    };

    const getTitle = () => {
        if (customerId) {
            return translate('breadcrumbs.userManagement');
        } else {
            return translate('customers.title');
        }
    };

    return (
        <div className={smallScreen ? classes.grow : classes.mdIndexGrow} id="primary-app-bar">
            <AppBar position="static" color="inherit" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Grid display={'flex'}>
                        <Link underline="none" title={`${translate('settingsMenu.customers')}`} className={classes.titleLink} onClick={onLogoClick}>
                            <img className={classes.apteanLogo} src={apteanLogo} alt="Aptean Logo" />
                            {!smallScreen && <img className={classes.apteanTitle} src={ediLogo} alt="Aptean EDI Wordmark" />}
                        </Link>
                        <Box marginLeft={2}>
                            <Typography variant="body1" className={classes.companyName}>
                                {getTitle()}
                            </Typography>
                        </Box>
                    </Grid>
                    <UserSettingsMenu viewerUser={viewerUser} />
                </Toolbar>
            </AppBar>
        </div>
    );
};
