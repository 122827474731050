import { useAuth0 } from '@auth0/auth0-react';
import SettingsMenu from './SettingsMenu';


interface SettingsMenuAuth0Props {
  afterClick: () => void;
  viewerUser?: { firstName: string; lastName: string; email: string, id: string } | undefined; // TODO: LATER GET THIS VIA SELECTOR
}

const SettingsMenuAuth0: React.FC<SettingsMenuAuth0Props> = props => {
  const { afterClick, viewerUser } = props;
  const { logout } = useAuth0();
  
  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    
    afterClick();
  };

  return (
    <SettingsMenu 
      afterClick={afterClick}
      handleLogout={handleLogout} 
      viewerUser={viewerUser} 
    />
  );
};

export default SettingsMenuAuth0;
