import { getSessionLocale } from "./dateTimeUtil";
import { defaultCurrencyCode } from "../Constants";


export const getCurrencyFormatter = (options?: Intl.NumberFormatOptions, locale?: string) => {
    let defaultCurrencyFormat = getCurrencyFormat();
    let formatOptions: Intl.NumberFormatOptions = { ...defaultCurrencyFormat };
    if (options) {
        formatOptions = options;
    }
    if (locale) {
        return new Intl.NumberFormat(locale, formatOptions);
    } else {
        return new Intl.NumberFormat(getSessionLocale() || 'en-US', formatOptions);
    }
};

export const getCurrencyFormat = (currencyCode?: string | null | undefined) => {
    let currency = currencyCode ?? defaultCurrencyCode;
    let currencyFormat = { style: 'currency', currency: currency };
    return currencyFormat;
};

export const getDataGridRowCurrencyDisplayValue = (value: number | null | undefined, currencyCode?: string | null | undefined) => {
    // only return formatted value when not 0
    if (value && value !== 0) {
        let currencyFormat = getCurrencyFormat(currencyCode);
        let intlCurrencyFormatter = getCurrencyFormatter(currencyFormat);
        return intlCurrencyFormatter.format(value);
    } else {
      return undefined;
    }
};

export const getSummaryTotalDisplayValue = (summaryTotal: number, summaryCurrencyCode: string) => {
    // only return formatted total when not 0
    if (summaryTotal && summaryTotal !== 0) {
      return getCurrencyFormatter({
          style: 'currency',
          currency: summaryCurrencyCode,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).format(summaryTotal);
    } else {
      return undefined;
    }
};