import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "../../app/store";
import { ExceptionStatus, NormalizedDocumentType } from '../../api/exceptions.api';

export interface ExceptionFilterSet {
  startDateFilter?: string;
  endDateFilter?: string;
  docTypeFilter?: NormalizedDocumentType;
  tradingPartnerIdFilter?: string;
  orderNumberFilter?: string;
  statusFilter?: ExceptionStatus;
}

export interface ExceptionsState {
    filters?: ExceptionFilterSet;
}

const initialState: ExceptionsState = {
    filters: undefined,
}

export const slice = createSlice({
    name: 'exceptions',
    initialState,
    reducers: {
        clearExceptionFilters: state => {
          if (state.filters) {
              // eslint-disable-next-line no-param-reassign
              state.filters = undefined;
          }
        },
        setExceptionFilters: (state, action: PayloadAction<ExceptionFilterSet>) => {
          // eslint-disable-next-line no-param-reassign
          state.filters = action.payload;
        },
    },
    extraReducers: builder => {},
  });

  export const selectExceptionFilters = (state: RootState): ExceptionFilterSet | undefined => state.exceptions.filters;
  
  export const {
      clearExceptionFilters,
      setExceptionFilters
  } =  slice.actions;

export default slice.reducer;