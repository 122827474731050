import { Breadcrumbs, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { breadcrumbNameMap } from '../../utils/breadcrumbNameMap';

import { InactiveBreadcrumb } from '../atoms/InactiveBreadcrumb';
import { LinkRouter } from '../atoms/LinkRouter';
import HomeIcon from '@mui/icons-material/Home';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectTradingPartnerName, setTradingPartnerName } from '../../features/tradingPartner/TradingPartnerSlice';
import { selectTenantId } from '../../features/app/AppSlice';
import { useGetV1PurchaseOrdersByTenantIdAndIdQuery } from '../../api/purchaseOrders.api';
import { useEffect } from 'react';
import { useGetV1CustomersByTenantIdTransactionsQuery, useGetV1CustomersByTenantIdTransactionSummaryQuery } from '../../api/customers.api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbs: {
      [theme.breakpoints.up('xl')]: {
        paddingLeft: theme.spacing(12),
      },
      [theme.breakpoints.down('xl')]: {
        paddingLeft: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
      },
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    label: {
      fontSize: 14,
      textTransform: 'uppercase',
    },
  }),
);

export const BreadCrumbs = () => {
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const tradingPartnerName = useAppSelector(selectTradingPartnerName);
  const tenantId = useAppSelector(selectTenantId);
  const { t: translate } = useTranslation();
  // need to filter out both first empty string from split as well as the date param
  // since it isn't used in breadcrumb building, but it is still needed in navigation,
  // so save it off to add back in the navigation portion of a breadcrumb
  const pathParts = location.pathname.split('/');
  const pathnames = pathParts.filter(x => x).filter(p => !p.startsWith("date-"));
  const datePath = pathParts.filter(p => p.startsWith("date-"));
  

  const { data: purchaseOrder } = useGetV1PurchaseOrdersByTenantIdAndIdQuery(
    {
      tenantId: tenantId as string,
      id: params.purchaseOrderId ? params.purchaseOrderId : ''
    }, { skip: !params.purchaseOrderId || !tenantId }
  );

  const { data: transactions } = useGetV1CustomersByTenantIdTransactionsQuery(
    {
      tenantId: tenantId as string,
      tradingPartnerId: params.tradingPartnerId,
      purchaseOrderId: params.purchaseOrderId
    },
    { skip: !tenantId || !params.tradingPartnerId },
  );

  useEffect(() => {
    if (transactions && transactions.items && transactions.items[0]?.tradingPartnerName) {
      dispatch(setTradingPartnerName(transactions.items[0]?.tradingPartnerName))
    }
  }, [transactions]);

  const renderBreadcrumbs = (_: string, index: number) => {
    const last = index === pathnames.length - 1;
    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
    let breadcrumbKey = to;
    let orderNo = '';
    let navigateToPath = datePath ? `${to}/${datePath}` : to; 
    const orderExp = /order-[0-9, A-Z, a-z, -]+/i;
    const exceptionStatusExp = /status-[a-zA-Z]+/;
    const exceptionTradingPartnerExp = /tradingPartner-[0-9, a-z, A-Z, _, -]+/i;
    let partnerName = '';
    let tradingPartnerOrderNo = '';
    let inOrderDetailsPage = false;
    let exceptionStatus = '';
    let exceptionTradingPartner = '';
    let exceptionOrderNumber = '';
    // Allowing breadcrumb to check the map for the same value without having to add redundant keys

    if (
      breadcrumbKey.includes('purchase-orders') &&
      breadcrumbKey !== '/purchase-orders-quarterly' &&
      breadcrumbKey !== '/purchase-orders-today'
    ) {
      if (breadcrumbKey.includes('/purchase-orders-quarterly')) {
        breadcrumbKey = breadcrumbKey.replace('/purchase-orders-quarterly', '/purchase-orders-');
      } else if (breadcrumbKey.includes('/purchase-orders-today')) {
        breadcrumbKey = breadcrumbKey.replace('/purchase-orders-today', '/purchase-orders-');
      }
      // Getting the order number of a purchase order and including it in the breadcrumb
      if (orderExp.test(breadcrumbKey)) {
        // if (purchaseOrder) {
        //   orderNo = purchaseOrder.number ? purchaseOrder.number : '';
        // } else {
        //   orderNo = breadcrumbKey + '';
        //   orderNo = orderNo.slice(orderNo.search(orderExp) + 6);
        // }
        breadcrumbKey = breadcrumbKey.replace(orderExp, 'order-');

        // if (breadcrumbKey.includes('raw-order-detail')) {
        //   orderNo = '';
        // }
        // // If we are beyond the order status page, such as order detail pages, we don't need the order number
        // if (orderNo.includes('/')) {
        //   orderNo = '';
        // }
      }
    }

    if (breadcrumbKey.includes('trading-partners/')) {
      if (!!params.tradingPartnerId) {
        if (tradingPartnerName) {
          partnerName = tradingPartnerName;
          breadcrumbKey = breadcrumbKey.replace(exceptionTradingPartnerExp, 'tradingPartner-');
          if (!!params.orderNumber && orderExp.test(breadcrumbKey)) {
            // if (purchaseOrder && orderExp.test(breadcrumbKey)) {
            //   tradingPartnerOrderNo = purchaseOrder.number ? purchaseOrder.number : '';
            // } else {
            //   tradingPartnerOrderNo = breadcrumbKey + '';
            //   tradingPartnerOrderNo = tradingPartnerOrderNo.slice(tradingPartnerOrderNo.search(orderExp) + 6);
            // }
            breadcrumbKey = breadcrumbKey.replace(orderExp, 'order-')
            // still set this flag even though not in details, but flag
            // is used to prevent display of the partner name in inactive breadcrumb,
            // which is the behavior we want when in the order transaction history
            inOrderDetailsPage = true;
            
            // if (breadcrumbKey.includes('raw-order-detail')) {
            //   inOrderDetailsPage = true;
            //   tradingPartnerOrderNo = '';
            // }
            // if (tradingPartnerOrderNo.includes('/')) {
            //   tradingPartnerOrderNo = '';
            // }
          }
        }
      }
    }
    // if (breadcrumbKey.includes('transactions')) {
    //   if (!!params.orderNumber) {
        
    //     // Getting the order number of a purchase order and including it in the breadcrumb
    //     if (orderExp.test(breadcrumbKey)) {
    //       breadcrumbKey = breadcrumbKey.replace(orderExp, 'order-');
    //     //   if (purchaseOrder) {
    //     //     orderNo = purchaseOrder.number ? purchaseOrder.number : '';
    //     //   } else {
    //     //     orderNo = breadcrumbKey + '';
    //     //     orderNo = orderNo.slice(orderNo.search(orderExp) + 6);
    //     //   }
    //     //   breadcrumbKey = breadcrumbKey.replace(orderExp, 'order-');

    //     //   if (breadcrumbKey.includes('raw-order-detail')) {
    //     //     inOrderDetailsPage = true;
    //     //     orderNo = '';
    //     //   }
    //     //   // If we are beyond the order status page, such as order detail pages, we don't need the order number
    //     //   if (orderNo.includes('/')) {
    //     //     orderNo = '';
    //     //   }
    //     }
    //   }
    // }
    // if (breadcrumbKey.includes('exceptions/')) {
    //   if (!!params.purchaseOrderId) {
    //     if (purchaseOrder && orderExp.test(breadcrumbKey)) {
    //       exceptionOrderNumber = breadcrumbKey + '';
    //       breadcrumbKey = breadcrumbKey.replace(orderExp, 'order-');
    //       exceptionOrderNumber = purchaseOrder.number ? purchaseOrder.number : '';
    //       if (breadcrumbKey.includes('raw-order-detail')) {
    //         inOrderDetailsPage = true;
    //       }
    //       // If we are beyond the order status page, such as order detail pages, we don't need the order number
    //       if (exceptionOrderNumber.includes('/')) {
    //         exceptionOrderNumber = '';
    //       }
    //     }
    //   }
    // }
    if (breadcrumbKey.includes('transactions') || breadcrumbKey.includes('exceptions') || breadcrumbKey.includes('shipments') || breadcrumbKey.includes('invoices') || breadcrumbKey.includes('acknowledgements')) {
      if (!!params.orderNumber && orderExp.test(breadcrumbKey)) {
        //exceptionOrderNumber = params.orderNumber;
        breadcrumbKey = breadcrumbKey.replace(orderExp, 'order-');
      }
    }

    return last ? (
      <InactiveBreadcrumb key={navigateToPath} variant="label1" className={classes.label}>
        {translate(breadcrumbNameMap[breadcrumbKey])}
        {partnerName !== '' && tradingPartnerOrderNo === '' && !inOrderDetailsPage ? partnerName : tradingPartnerOrderNo}
        {orderNo !== '' ? orderNo : ''}
        {!!exceptionOrderNumber ? exceptionOrderNumber : ''}
      </InactiveBreadcrumb>
    ) : (
        <LinkRouter to={navigateToPath} key={navigateToPath} variant="label1" className={classes.label}>
          {translate(breadcrumbNameMap[breadcrumbKey])}
          {partnerName !== '' && tradingPartnerOrderNo === '' && !inOrderDetailsPage ? partnerName : tradingPartnerOrderNo}
          {orderNo !== '' ? orderNo : ''}
          {!!exceptionOrderNumber ? exceptionOrderNumber : ''}
        </LinkRouter>
      );
  };

  return (
    <Breadcrumbs className={classes.breadcrumbs} separator="/" aria-label="breadcrumb">
      <LinkRouter to="/">
        <HomeIcon color="primary" fontSize="medium" sx={{ marginTop: '4px' }} />
      </LinkRouter>
      {pathnames.length ? (
        <LinkRouter to="/" variant="label1" className={classes.label}>
          {translate("breadcrumbs.dashboard")}
        </LinkRouter>
      ) : (
          <InactiveBreadcrumb variant="label1" className={classes.label}>
            {translate("breadcrumbs.dashboard")}
          </InactiveBreadcrumb>
        )}
      {pathnames.map(renderBreadcrumbs)}
    </Breadcrumbs>
  );
};
