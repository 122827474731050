// TODO: add localization
// import { useTranslation } from 'react-i18next';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { AppBar, Avatar, Box, Button, Drawer, IconButton, Link, Menu, Theme, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PortalUserResource } from '../../api/users.api';

import ApteanEDIWordmarkBlack from '../../logos/ApteanEDIWordmarkBlack.svg';
import ApteanEDIWordmarkWhite from '../../logos/ApteanEDIWordmarkWhite.svg';
import ApteanLogoBlack from '../../logos/ApteanLogoBlack.svg';
import ApteanLogoWhite from '../../logos/ApteanLogoWhite.svg';

import { GlobalSearchAutocomplete } from '../molecules/GlobalSearchAutoComplete';
import { UserSettingsMenu } from '../molecules/UserSettingsMenu';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flex: '1 0 auto',
            zIndex: theme.zIndex.drawer + 1, // make toolbar sit above navigation menu
            position: 'relative',
        },
        appBar: {
            boxShadow: '0px 3px 6px #00000012',
        },
        extraToolbarPadding: {
            paddingLeft: 12,
            [theme.breakpoints.up('xl')]: {
                paddingRight: theme.spacing(12),
            },
            [theme.breakpoints.down('xl')]: {
                paddingRight: theme.spacing(5),
            },
            [theme.breakpoints.down('sm')]: {
                paddingRight: theme.spacing(2),
            },
        },
        titleLink: {
            display: 'inline-flex',
            alignItems: 'center',
        },
        apteanLogo: {
            height: '30px',
            marginRight: theme.spacing(1),
        },
        apteanTitle: {
            color: 'black',
            marginTop: '5px',
            display: 'inline-block',
            height: '25px',
            [theme.breakpoints.down('sm')]: {
                height: '18px',
            },
        },
        mdIndexGrow: {
            zIndex: theme.zIndex.drawer + 1, // make toolbar sit above navigation menu
            position: 'relative',
        },
        settingsBox: {
            width: 400,
        },
        companyName: {
            opacity: 0.6,
            fontWeight: 500,
            fontSize: '30px',
            marginTop: '1px',
        },
        navigationMenu: {
            marginRight: 6,
        },
        dateFilterButton: {
            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing(0, 2),
                height: 40,
            },
        },
        globalSearchDrawer: {
            zIndex: theme.zIndex.drawer + 2, // make drawer sit above toolbar
        },
        globalSearchDrawerBox: {
            padding: theme.spacing(8, 0, 1),
            display: 'flex',
            justifyContent: 'center',
        },
    }),
);

interface HeaderProps {
    viewerUser?: PortalUserResource;
    handleNavMenuClick: () => void;
    dateFilterTitle?: string;
    handleDateFilterButtonClick: () => void;
    globalSearchDrawerOpen: boolean;
    toggleGlobalSearchDrawer: () => void;
    companyName?: string | null;
}

export const Header: FC<HeaderProps> = props => {
    const {
        viewerUser,
        handleNavMenuClick,
        dateFilterTitle,
        handleDateFilterButtonClick,
        globalSearchDrawerOpen,
        toggleGlobalSearchDrawer,
        companyName,
    } = props;
    const classes = useStyles();
    const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const location = useLocation();
    const themeMode = useTheme().palette.mode;
    const [apteanLogo, setApteanLogo] = useState<string>(ApteanLogoBlack);
    const [ediLogo, setEdiLogo] = useState<string>(ApteanEDIWordmarkBlack);
     
    const mobileGlobalSearchDrawer = (
        <Drawer open={globalSearchDrawerOpen} onClose={toggleGlobalSearchDrawer} className={classes.globalSearchDrawer} anchor="top">
            <Box className={classes.globalSearchDrawerBox}>
                <GlobalSearchAutocomplete onMobileSearch={toggleGlobalSearchDrawer} />
            </Box>
        </Drawer>
    );

    useEffect(() => {
        if (themeMode) {
            if (themeMode === "dark") {
                setApteanLogo(ApteanLogoWhite);
                setEdiLogo(ApteanEDIWordmarkWhite);
            } else {
                setApteanLogo(ApteanLogoBlack);
                setEdiLogo(ApteanEDIWordmarkBlack);
            }
        }
    }, [themeMode])
    
    return (
        <div className={smallScreen ? classes.grow : classes.mdIndexGrow} id="primary-app-bar">
            <AppBar position="static" color="inherit" className={classes.appBar}>
                <Toolbar className={classes.extraToolbarPadding}>
                    <IconButton className={classes.navigationMenu} onClick={handleNavMenuClick} size="large" aria-label="navigation menu">
                        <MenuIcon />
                    </IconButton>
                    <Link href="/" underline="none" className={classes.titleLink}>
                        <img className={classes.apteanLogo} src={apteanLogo} alt="Aptean Logo" />
                        {!smallScreen && <img className={classes.apteanTitle} src={ediLogo} alt="Aptean EDI Wordmark" />}
                    </Link>
                    {companyName && (
                        <Box marginLeft={2}>
                            <Typography variant="body1" className={classes.companyName}>
                                {companyName}
                            </Typography>
                        </Box>
                    )}
                    <div className={classes.grow} />
                    {!smallScreen && <GlobalSearchAutocomplete />}
                    {smallScreen && mobileGlobalSearchDrawer}
                    {smallScreen && (
                        <IconButton onClick={toggleGlobalSearchDrawer} color="primary" aria-label="open global search">
                            <SearchIcon />
                        </IconButton>
                    )}
                    {!smallScreen && (
                        <Button
                            variant="outlined"
                            startIcon={<CalendarMonthIcon />}
                            onClick={handleDateFilterButtonClick}
                            className={classes.dateFilterButton}
                            aria-label="date period selection"
                            disabled={
                                location.pathname === '/exceptions' || location.pathname.includes('order-') || location.pathname === 'raw-order-detail'
                            }
                        >
                            {dateFilterTitle}
                        </Button>
                    )}
                    {smallScreen && (
                        <IconButton
                            onClick={handleDateFilterButtonClick}
                            color="primary"
                            className={classes.dateFilterButton}
                            aria-label="date period selection"
                            disabled={
                                location.pathname === '/exceptions' || location.pathname.includes('order-') || location.pathname === 'raw-order-detail'
                            }
                        >
                            <CalendarMonthIcon />
                        </IconButton>
                    )}
                    <UserSettingsMenu viewerUser={viewerUser} />
                </Toolbar>
            </AppBar>
        </div>
    );
};
