import { FC, useEffect, useState } from 'react';
import { Dialog } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { ConfirmationPrompt } from "../molecules/ConfirmationPrompt";

interface ResendDialogProps {
    isOpen: boolean;
    id: string;
    heading: string;
    message: string;
    onConfirm: (id: string) => void;
    onReject: () => void;
    errorMessage?: string | undefined;
    resendCount?: number;
}

const ResendDocumentDialog: FC<ResendDialogProps> = props => {
    const { t: translate } = useTranslation();
    const { isOpen, id, heading = translate('resendDocumentDialog.heading'), message, onConfirm, onReject, errorMessage, resendCount = 0 } = props;
    const [isResendBusy, setIsResendBusy] = useState(false);

    useEffect(() => {
        if (errorMessage) {
            setIsResendBusy(false);
            return;
        }
    }, [errorMessage]);

    useEffect(() => {
        // set submitted to false when dialog is closed
        if (!isOpen) {
            setIsResendBusy(false);
        }
    }, [isOpen]);

    const handleResendConfirm = () => {
        setIsResendBusy(true);
        onConfirm(id);
    }
    const handleResendReject = () => {
        setIsResendBusy(false);
        onReject();
    }

    return (
        <Dialog
            open={isOpen}>
            <ConfirmationPrompt
                heading={heading}
                message={message}
                confirmButtonText={translate('resendDocumentDialog.confirmButtonText')}
                rejectButtonText={translate('resendDocumentDialog.rejectButtonText')}
                confirmButtonColor={resendCount > 0 ? 'warning' : 'primary'}
                rejectButtonColor={'primary'}
                handleConfirm={handleResendConfirm}
                handleReject={handleResendReject}
                isBusy={isResendBusy}
                errorMessage={errorMessage}
            />
        </Dialog>
    );
};

export default ResendDocumentDialog;