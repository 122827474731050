import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { useClearActiveCustomerTenant } from '../../utils/hooks/useClearActiveCustomerTenant';
import { Divider, FormControlLabel, Grid, MenuItem, MenuList, Stack, Switch, Theme, Typography, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../molecules/LanguageSelect';
import { canManageUsers, getLanguageForUser, setDarkModeForUser, setLanguageForUser } from '../../utils/ViewerUtility';
import { setSessionLocale } from '../../utils/helpers/dateTimeUtil';
import { ThemeModeContext } from '../../features/stylingProvider/StylingProvider';
import { selectCustomerId, selectIsUserCustomerAdmin, selectUserHasSingleCustomerSingleTenant } from '../../features/app/AppSlice';

//Not using add user icon for demo
//import PersonAddIcon from '@mui/icons-material/PersonAdd';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'start',
    },
    userInfo: {
      padding: theme.spacing(3),
    },
    menuItem: {
      height: 55,
      textDecoration: 'none',
      color: '#276EF1',
    },
    menuItemIcon: {
      marginRight: theme.spacing(2),
      color: '#276EF1',
    },
    themeLabel: {
      '& span': {
        paddingRight: theme.spacing(2),
      }
    }
  }),
);

interface SettingsMenuProps {
  afterClick: () => void;
  handleLogout: () => void;
  viewerUser?: { firstName: string; lastName: string; email: string, id: string } | undefined; // TODO: LATER GET THIS VIA SELECTOR
  logoutButtonText?: string | undefined | null;
}

const SettingsMenu: React.FC<SettingsMenuProps> = props => {
  const { afterClick, handleLogout, viewerUser, logoutButtonText } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { t: translate, i18n } = useTranslation();
  const clearActiveCustomerTenant = useClearActiveCustomerTenant();

  const [ selectedLang, setSelectedLang] = useState('');

  const colorMode = useContext(ThemeModeContext);
  const currTheme = useTheme().palette.mode;
  
  const customerId = useAppSelector(selectCustomerId);
  const singleCustomerSingleTenant = useAppSelector(selectUserHasSingleCustomerSingleTenant);
  const isViewerAdmin = useAppSelector(selectIsUserCustomerAdmin);
  const canAddUser = canManageUsers(isViewerAdmin, viewerUser);

  const changeLanguage = (lang: string) => {
    if (lang) {
      i18n.changeLanguage(lang);
      setSessionLocale(lang);
      setLanguageForUser(viewerUser?.id, lang);
      afterClick();
    }
  };

  useEffect(() => {
    //get and set starting language
    if (viewerUser?.id) {
      const userLang = getLanguageForUser(viewerUser?.id);
      if (userLang) {
        setSelectedLang(userLang);
        setSessionLocale(userLang);
      } else {
        setSelectedLang('en-US');
        setSessionLocale('en-US');
      }
    }
  }, [viewerUser?.id]);

  const switchThemeHandler = () => {
      const val = currTheme !== "dark";
      setDarkModeForUser(viewerUser?.id, val.toString());
      colorMode.toggleColorMode()
  };

  const getThemeOption = () => {
    const checked = currTheme === "dark";
    return (
      <FormControlLabel 
          className={classes.themeLabel}
          value="start"
          control={
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Brightness7Icon/>
                <Switch checked={checked} onChange={switchThemeHandler}/>
              <Brightness4Icon/>
            </Stack>
          }
          label={translate('settingsMenu.theme')}
          labelPlacement="start"
      />
    )
  };

  const onManageUsersClick = () => {
    navigate('/user-management');
    afterClick();
  };

  const onCustomersClick = () => {
    //remove tenant id and customer from local storage and state to show customers/tenant selection page
    clearActiveCustomerTenant(true);
    
    // then redirect to home screen that should by default load customers
    navigate("/");
    afterClick();
  };
  

  return (
    <Grid container className={classes.root}>
      <Grid className={classes.userInfo} item container xs={12}>
        <Grid item xs={9}>
          <Typography variant="h6">
            {viewerUser?.firstName} {viewerUser?.lastName}
          </Typography>
          <Typography variant="subtitle1">{viewerUser?.email}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <MenuList data-cy="settings-menu" autoFocusItem>
          {!singleCustomerSingleTenant && (
          <MenuItem className={classes.menuItem} onClick={onCustomersClick} data-cy="customers">
              <BusinessIcon className={classes.menuItemIcon} />
              {translate('settingsMenu.customers')}
          </MenuItem>
          )}
          {customerId && canAddUser && (
            <MenuItem className={classes.menuItem} onClick={onManageUsersClick} data-cy="manage-users">
              <PersonIcon className={classes.menuItemIcon} />
              {translate('settingsMenu.manageUsers')}
            </MenuItem>
          )}
          <LanguageSelect 
            languageChanged={changeLanguage}
            menuItemClassName={classes.menuItem}
            language={selectedLang}
          />
          {getThemeOption()}
          <MenuItem className={classes.menuItem} onClick={handleLogout} data-cy="sign-out">
            <ExitToAppIcon className={classes.menuItemIcon} />
            {logoutButtonText ?? translate('settingsMenu.logout')}
          </MenuItem>
        </MenuList>
      </Grid>
    </Grid>
  );
};

export default SettingsMenu;
