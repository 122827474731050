import { emptySplitApi as api } from './empty.api';
export const addTagTypes = ['Dashboard'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getV1DashboardTransactionTotal: build.query<GetV1DashboardTransactionTotalApiResponse, GetV1DashboardTransactionTotalApiArg>({
        query: queryArg => ({
          url: `/v1/dashboard/transaction-total`,
          params: {
            tenantId: queryArg.tenantId,
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            docType: queryArg.docType,
            tradingPartnerId: queryArg.tradingPartnerId,
            purchaseOrderNumber: queryArg.purchaseOrderNumber,
          },
        }),
        providesTags: ['Dashboard'],
      }),
      getV1DashboardGeneralTransactionTotal: build.query<
        GetV1DashboardGeneralTransactionTotalApiResponse,
        GetV1DashboardGeneralTransactionTotalApiArg
      >({
        query: queryArg => ({
          url: `/v1/dashboard/general-transaction-total`,
          params: { startDate: queryArg.startDate, endDate: queryArg.endDate },
        }),
        providesTags: ['Dashboard'],
      }),
      getV1DashboardTopTradingPartners: build.query<
        GetV1DashboardTopTradingPartnersApiResponse,
        GetV1DashboardTopTradingPartnersApiArg
      >({
        query: queryArg => ({
          url: `/v1/dashboard/top-trading-partners`,
          params: { tenantId: queryArg.tenantId, startDate: queryArg.startDate, endDate: queryArg.endDate, count: queryArg.count },
        }),
        providesTags: ['Dashboard'],
      }),
      getV1DashboardPeriodicActivity: build.query<GetV1DashboardPeriodicActivityApiResponse, GetV1DashboardPeriodicActivityApiArg>({
        query: queryArg => ({
          url: `/v1/dashboard/periodic-activity`,
          headers: { 'x-aptean-client-timezone-offset': queryArg['x-aptean-client-timezone-offset'] },
          params: { tenantId: queryArg.tenantId, startDate: queryArg.startDate, endDate: queryArg.endDate },
        }),
        providesTags: ['Dashboard'],
      }),
      getV1DashboardSystemNotification: build.query<
        GetV1DashboardSystemNotificationApiResponse,
        GetV1DashboardSystemNotificationApiArg
      >({
        query: () => ({ url: `/v1/dashboard/system-notification` }),
        providesTags: ['Dashboard'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type GetV1DashboardTransactionTotalApiResponse = /** status 200 Success */ TransactionTotalResource[];
export type GetV1DashboardTransactionTotalApiArg = {
  tenantId: string;
  startDate: string;
  endDate: string;
  docType?: NormalizedDocumentType;
  tradingPartnerId?: string;
  purchaseOrderNumber?: string;
};
export type GetV1DashboardGeneralTransactionTotalApiResponse = /** status 200 Success */ TransactionTotalResource[];
export type GetV1DashboardGeneralTransactionTotalApiArg = {
  startDate: string;
  endDate: string;
};
export type GetV1DashboardTopTradingPartnersApiResponse = /** status 200 Success */ TopTradingPartnerResource[];
export type GetV1DashboardTopTradingPartnersApiArg = {
  tenantId: string;
  startDate: string;
  endDate: string;
  count?: number;
};
export type GetV1DashboardPeriodicActivityApiResponse = /** status 200 Success */ PeriodicActivityResource[];
export type GetV1DashboardPeriodicActivityApiArg = {
  tenantId: string;
  startDate: string;
  endDate: string;
  'x-aptean-client-timezone-offset'?: string;
};
export type GetV1DashboardSystemNotificationApiResponse = /** status 200 Success */ SystemNotificationResource;
export type GetV1DashboardSystemNotificationApiArg = void;
export type NormalizedDocumentType =
  | 'unknown'
  | 'acknowledgement'
  | 'applicationAdvice'
  | 'control'
  | 'creditDebitAdjustment'
  | 'deliveryJustInTime'
  | 'deliverySchedule'
  | 'inventoryAdvice'
  | 'invoice'
  | 'organizationalRelationships'
  | 'payment'
  | 'productTransferResaleReport'
  | 'purchaseOrder'
  | 'purchaseOrderChange'
  | 'remittanceAdvice'
  | 'requestForQuotation'
  | 'requestForRoutingInstructions'
  | 'salesOrderStatus'
  | 'salesOrderStatusRequest'
  | 'shipment'
  | 'shippingSchedule'
  | 'stockReceipt'
  | 'stockTransfer'
  | 'taxcon'
  | 'textMessage'
  | 'warehouseInventoryAdjustmentAdvice'
  | 'warehouseShippingAdvice'
  | 'warehouseShippingOrder';
export type MonetaryValueResource = {
  currency?: string | null;
  amount?: number;
};
export type TransactionTotalResource = {
  documentType?: NormalizedDocumentType;
  amounts?: MonetaryValueResource[] | null;
  count?: number;
};
export type TopTradingPartnerResource = {
  tradingPartnerId?: string;
  tradingPartnerName?: string | null;
  invoiceSum?: number;
  purchaseOrderSum?: number;
};
export type PeriodicActivityResource = {
  startDate?: string;
  endDate?: string;
  transactionTotals?: TransactionTotalResource[] | null;
};
export type SystemNotificationType = 'information' | 'warning' | 'alert';
export type SystemNotificationEnvironment = 'edi' | 'ediPortal';
export type SystemNotificationResource = {
  systemNotificationId?: string;
  notificationType?: SystemNotificationType;
  message?: string | null;
  isActive?: boolean;
  environment?: SystemNotificationEnvironment;
};
export const {
  useGetV1DashboardTransactionTotalQuery,
  useGetV1DashboardGeneralTransactionTotalQuery,
  useGetV1DashboardTopTradingPartnersQuery,
  useGetV1DashboardPeriodicActivityQuery,
  useGetV1DashboardSystemNotificationQuery,
} = injectedRtkApi;
