import axios from "axios";
import { AppDispatch } from "../../app/store";
import { sec } from "../../utils/security";
import { setFetchForExportError, fetchTransactionsForExportSuccess } from "./TransactionsSlice";
import { GetV1CustomersByTenantIdTransactionsApiArg, NormalizedDocumentType } from "../../api/customers.api";

export const getDocumentTypes = () => {
    let types: NormalizedDocumentType[] = [];
    types.push('acknowledgement');
    types.push('applicationAdvice');
    types.push('control');
    types.push('creditDebitAdjustment');
    types.push('deliveryJustInTime');
    types.push('deliverySchedule');
    types.push('inventoryAdvice');
    types.push('invoice');
    types.push('organizationalRelationships');
    types.push('payment');
    types.push('productTransferResaleReport');
    types.push('purchaseOrder');
    types.push('purchaseOrderChange');
    types.push('remittanceAdvice');
    types.push('requestForQuotation');
    types.push('requestForRoutingInstructions');
    types.push('salesOrderStatus');
    types.push('salesOrderStatusRequest');
    types.push('shipment');
    types.push('shippingSchedule');
    types.push('stockReceipt');
    types.push('stockTransfer');
    types.push('taxcon');
    types.push('textMessage');
    types.push('warehouseInventoryAdjustmentAdvice');
    types.push('warehouseShippingAdvice');
    types.push('warehouseShippingOrder');
    
    return types;
  };

export const getTransactionsForExport = (filters: GetV1CustomersByTenantIdTransactionsApiArg) => 
async (dispatch: AppDispatch): Promise<void> =>{
  let url = `${process.env.REACT_APP_EDI_PORTAL_API_ENDPOINT}/v1/customers/${filters.tenantId}/transactions`;
  const token = await sec.getAccessTokenSilently()();
    if (token) {
      axios({
        method: 'get',
        url: url as string,
        headers: { Authorization: `Bearer ${token}` },
        params: {
          tenantId: filters.tenantId,
          startDate: filters.startDate,
          endDate: filters.endDate,
          orderNumber: filters.orderNumber,
          docNumber: filters.docNumber,
          docType: filters.docType,
          tradingPartnerName: filters.tradingPartnerName,
          hasException: filters.hasException,
          erpOrderNumber: filters.erpOrderNumber,
          transactionType: filters.transactionType,
          returnAllRecords: filters.returnAllRecords,
        }
      })
      .then (response => {
        if (response && response.data) {
          dispatch(fetchTransactionsForExportSuccess(response.data));
        }
    })
    .catch(ex => {
      dispatch(setFetchForExportError(ex));
    })
  }
};

