import dxDataGrid from 'devextreme/ui/data_grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

export const getBase64FileDataString = (mimeType?: string) => {
    if (mimeType) {
        return 'data:' + mimeType + ';base64';
    }
};

export const downloadFileFromBase64 = (documentString: string, fileName: string, mimeType: string) => {
    if (documentString && fileName && mimeType) {
        // get the base64 prefix to build the source
        // for an anchor element and simulate clicking it
        // to download the file
        const fileData = getBase64FileDataString(mimeType);
        const linkSource = `${fileData},${documentString}`;
        let downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
};

export const openHtmlFileFromBase64 = (documentString: string) => {
    if (documentString) {
        // using atob and document.write to successfully open the html in a browser tab
        // without getting security errors
        // also tried to use target=_blank on an anchor element, but that was
        // opening as an empty tab and had to refresh the tab to display the data
        // atob converts from base64 and since html formatted string, it can be written directly     
        const w = window.open('about:blank');
        if (w) {
            var base64Data = documentString.replace(/\s/g, "");
            var data = atob(base64Data);
            w.document.write(data);
        }  
    }
};

export const downloadDocument = (documentString: string, fileName: string, mimeType: string, openInNewTab?: boolean) => {
    if (documentString) {
        if (openInNewTab === true && mimeType.indexOf("htm") > 0) {
            openHtmlFileFromBase64(documentString);
        } else {
            downloadFileFromBase64(documentString, fileName, mimeType);
        }
    }
};

export const exportDataGridToCSV = async (worksheetName: string, fileName: string, gridRef: dxDataGrid<any, any> | undefined): Promise<boolean> => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(worksheetName);
    
    try {
        await exportDataGrid({
            component: gridRef,
            worksheet,
            });
            
        let buffer = await workbook.csv.writeBuffer();
            
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
        
        return true;
       
    } catch (error) {
        return false;
    }
    
};