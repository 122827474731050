import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from "../../app/hooks";
import { DateTime } from 'luxon';
import { getSessionLocale } from '../../utils/helpers/dateTimeUtil';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Stack, Grid, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, styled, Checkbox, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterBarTemplate from '../organisms/FilterBarTemplate';
import { NormalizedDocumentType } from '../../api/customers.api';
import { TransactionsFilterSet } from '../../features/transactions/TransactionsSlice';
import { getDocumentTypes } from '../../features/transactions/TransactionsAPI';
import { setDisplaySearchResults, setSelectedOrder, changeSearchTerm, clearSearchResults } from '../../features/app/AppSlice';
import { getDocumentTypeName } from '../../utils/helpers/functions';

const DatePickerWrapper = styled('div')((props) => ({
    width: '100%',
}));

const BlankMenuItem = styled(MenuItem)((props) => ({
  height: '36px'
}));

const FilterBarCheckbox = styled(Checkbox)((props) => ({
    padding: '0',
    paddingLeft: '9px',
    paddingRight: '9px'
}));

interface TransactionsFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    premadeSet?: TransactionsFilterSet; 
    onClear: () => void;
    onFilterChanged: (
        filterDocType?: NormalizedDocumentType | undefined,
        filterPartner?: string | undefined,
        filterOrderNum?: string | undefined,
        filterDocNum?: string | undefined,
        filterTransDateFrom?: DateTime | undefined,
        filterTransDateTo?: DateTime | undefined,
        filterHasExceptions?: boolean | undefined,
        filterErpOrderNum?: string | undefined,
        filterTransactionType?: string | undefined,
        filterIsDocumentResent?: boolean | undefined
    ) => void;
}

const TransactionsFilterBar: React.FC<TransactionsFilterBarProps> = props => {
    const { id, loading = false, visible = false, premadeSet, onFilterChanged } = props;
    const dispatch = useAppDispatch();
    const { t: translate } = useTranslation();
    const sessionLocale = getSessionLocale();
    const [filterDocumentType, setFilterDocumentType] = useState<NormalizedDocumentType | undefined>(undefined);
    const [filterTransactionType, setFilterTransactionType] = useState<string | undefined>(undefined);
    const [filterPartnerName, setFilterPartnerName] = useState<string | undefined>(undefined);
    const [filterOrderNumber, setFilterOrderNumber] = useState<string | undefined>(undefined);
    const [filterDocumentNumber, setFilterDocumentNumber] = useState<string | undefined>(undefined);
    const [filterErpOrderNumber, setFilterErpOrderNumber] = useState<string | undefined>(undefined);
    const [filterDateFrom, setFilterDateFrom] = useState<DateTime | undefined>(undefined);
    const [filterDateTo, setFilterDateTo] = useState<DateTime | undefined>(undefined);
    const [filterHasException, setFilterHasException] = useState<string | undefined>(undefined);
    const [filterIsDocumentResent, setFilterIsDocumentResent] = useState<boolean | undefined>(undefined);

    const fieldsDisabled = visible && loading;

    const isValidSearch = () => {
        return (!isFieldMinLengthInvalid(filterPartnerName) && !isFieldMinLengthInvalid(filterOrderNumber) && !isFieldMinLengthInvalid(filterDocumentNumber) && !isFieldMinLengthInvalid(filterErpOrderNumber));
    }
    const getHasExceptionFilter = () => {
        return filterHasException === undefined ? undefined : filterHasException === "true";
    }
    const getIsDocumentResentFilter = () => {
        // only want to set the filter if it is true, otherwise set to undefined to not filter
        return filterIsDocumentResent === true ? true : undefined;
    }
    
    const onSearchClick = () => {
        if (isValidSearch()) {
            dispatch(clearSearchResults());
            dispatch(changeSearchTerm(''));
            dispatch(setDisplaySearchResults(false));
            dispatch(setSelectedOrder(null));
            onFilterChanged(filterDocumentType, filterPartnerName, filterOrderNumber, filterDocumentNumber, filterDateFrom, filterDateTo, getHasExceptionFilter(), filterErpOrderNumber, filterTransactionType, getIsDocumentResentFilter());
       }
    }

    const onClearAllClick = () => {
        clearFilters();
        props.onClear();
    };    

    useEffect(() => {
        if (premadeSet) {
            clearFilters();
            if (premadeSet.filterPartner) {
                setFilterPartnerName(premadeSet.filterPartner);
            }
            if (premadeSet.filterDateFrom) {
                setFilterDateFrom(premadeSet.filterDateFrom);
            }
            if (premadeSet.filterDateTo) {
                setFilterDateTo(premadeSet.filterDateTo);
            }
            if (premadeSet.filterOrderNum) {
                setFilterOrderNumber(premadeSet.filterOrderNum);
            }
            if (premadeSet.filterDocNum) {
                setFilterDocumentNumber(premadeSet.filterDocNum);
            }
            if (premadeSet.filterErpOrderNum) {
                setFilterErpOrderNumber(premadeSet.filterErpOrderNum);
            }
            if (premadeSet.filterDocType) {
                setFilterDocumentType(premadeSet.filterDocType);
            }
            if (premadeSet.filterHasException !== undefined) {
                setFilterHasException(premadeSet.filterHasException ? 'true' : 'false');
            }
            if (premadeSet.filterTransactionType) {
                setFilterTransactionType(premadeSet.filterTransactionType);
            }
            if (premadeSet.filterIsDocumentResent === true) {
                setFilterIsDocumentResent(true);
            }
            
            // onSearchClick relies on the call the useStates to set in time,
            // set them above for filterbar visuals
            // but make the call directly with the premade filters
            onFilterChanged(
                premadeSet.filterDocType, 
                premadeSet.filterPartner, 
                premadeSet.filterOrderNum,
                premadeSet.filterDocNum,
                premadeSet.filterDateFrom,
                premadeSet.filterDateTo, 
                premadeSet.filterHasException,
                premadeSet.filterErpOrderNum,
                premadeSet.filterTransactionType,
                premadeSet.filterIsDocumentResent
            );
        }
    }, [premadeSet]);

    const clearFilters = () => {
        clearOrderNumFilter();
        clearDocNumFilter();
        clearErpOrderNumberFilter();
        clearPartnerNameFilter();
        clearTransactionTypeFilter();
        setFilterDocumentType(undefined);
        setFilterDateFrom(undefined);
        setFilterDateTo(undefined);
        setFilterHasException(undefined);
        setFilterIsDocumentResent(undefined);
    };

  
    const clearOrderNumFilter = () => {
        setFilterOrderNumber(undefined);
    };

    const clearDocNumFilter = () => {
        setFilterDocumentNumber(undefined);
    };

    const clearErpOrderNumberFilter = () => {
        setFilterErpOrderNumber(undefined);
    };

    const clearPartnerNameFilter = () => {
        setFilterPartnerName(undefined);
    };

    const clearTransactionTypeFilter = () => {
        setFilterTransactionType(undefined);
    };
    
    const onOrderNumFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setFilterOrderNumber(value);
    };

    const onDocNumFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setFilterDocumentNumber(value);
    };

    const onErpOrderNumberFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setFilterErpOrderNumber(value);
    };

    const onPartnerNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setFilterPartnerName(value);
    };

    const onTransactionTypeFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setFilterTransactionType(value);
    };

    const onDateFromFilterChange = (value: DateTime | null) => {
        setFilterDateFrom(value?.startOf('day') ?? undefined);
        setFilterDateTo(value?.endOf('month') ?? undefined);
    };
    
    const onDateToFilterChange = (value: DateTime | null) => {
        setFilterDateTo(value?.endOf('day') ?? undefined);
    };
    
    const onDocTypeFilterChange = (event: SelectChangeEvent<NormalizedDocumentType>) => {
        const {
            target: { value },
        } = event;
        let currentValue = value?.length > 0 ? value : undefined;
        setFilterDocumentType(currentValue as NormalizedDocumentType);
    };

    const onHasExceptionFilterChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        let currentValue = value?.length > 0 ? value : undefined;
        setFilterHasException(currentValue);
    };

    const onDocumentResentFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilterIsDocumentResent(event.target.checked);
    };

    const getDocTypeFilterList = () => {
        let types = getDocumentTypes();
        if (types && types.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                types.map((docType: NormalizedDocumentType) => (
                    <MenuItem
                        key={docType}
                        value={docType}
                    >
                        {getDocumentTypeName(docType)}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };
    
    const getHasExceptionFilterList = () => {
        let items = [];        
        const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
        const yes = <MenuItem key="1" value="true">{translate("filters.yes")}</MenuItem>;
        const no = <MenuItem key="2" value="false">{translate("filters.no")}</MenuItem>;
        items.push(blankItem);
        items.push(yes);
        items.push(no);
        return items;
    };

   const partnerNameFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label={`${translate("filters.clearPartnerNameFilter")}`}
                    onClick={clearPartnerNameFilter}
                    disabled={fieldsDisabled}
                >
                    {filterPartnerName && filterPartnerName.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const orderNumFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label={`${translate("filters.clearOrderNumberFilter")}`}
                    onClick={clearOrderNumFilter}
                    disabled={fieldsDisabled}
                >
                    {filterOrderNumber && filterOrderNumber.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const documentNumFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label={`${translate("filters.clearDocumentNumberFilter")}`}
                    onClick={clearDocNumFilter}
                    disabled={fieldsDisabled}
                >
                    {filterDocumentNumber && filterDocumentNumber.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const erpOrderNumFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label={`${translate("filters.clearErpOrderNumberFilter")}`}
                    onClick={clearErpOrderNumberFilter}
                    disabled={fieldsDisabled}
                >
                    {filterErpOrderNumber && filterErpOrderNumber.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const transactionTypeFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label={`${translate("filters.clearTransactionTypeFilter")}`}
                    onClick={clearTransactionTypeFilter}
                    disabled={fieldsDisabled}
                >
                    {filterTransactionType && filterTransactionType.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const documentTypeMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
            },
        },
        'aria-label': `${translate("filters.document")}`
    };

    const minLengthErrorMessage = "Minimum 3 characters";

    const isFieldMinLengthInvalid = (fieldValue: string | undefined) => {
        if (fieldValue && fieldValue.length < 3) {
            return true;
        }
        return false;
    };

    return (
        <FilterBarTemplate id={id} visible={visible} isValid={isValidSearch()} onClear={onClearAllClick} onSearch={onSearchClick} barHeight="140px">
            <Stack direction={'column'} justifyContent="center" rowGap={2} width="100%">
                <Grid container item direction={'row'} columnGap={2} justifyContent="center" xs={12}>
                    <Grid item xs={1} lg={2}>
                        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={sessionLocale || 'en-us'}>
                            <DatePickerWrapper>
                                <DatePicker
                                    label={translate("filters.dateFrom")}
                                    value={filterDateFrom ?? null}
                                    maxDate={filterDateTo}
                                    disabled={fieldsDisabled}
                                    disableMaskedInput
                                    disableFuture
                                    onChange={value => {
                                        onDateFromFilterChange(value);
                                    }}
                                    renderInput={params => 
                                        <TextField 
                                            {...params} 
                                            fullWidth 
                                            variant='standard'
                                            label={translate("filters.dateFrom")} />
                                    }
                                    data-cy="transaction-filter-date-from"
                                ></DatePicker>
                            </DatePickerWrapper>
                        </LocalizationProvider>
                    </Grid>
                
                    <Grid item xs={1} lg={2}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}  adapterLocale={sessionLocale || 'en-us'}>
                            <DatePickerWrapper>
                                <DatePicker
                                    label={translate("filters.dateTo")}
                                    value={filterDateTo ?? null}
                                    minDate={filterDateFrom}
                                    disabled={fieldsDisabled}
                                    disableMaskedInput
                                    onChange={value => {
                                        onDateToFilterChange(value);
                                    }}
                                    renderInput={params => 
                                        <TextField 
                                            {...params} 
                                            fullWidth 
                                            variant='standard'
                                            label={translate("filters.dateTo")} />
                                    }
                                    data-cy="transaction-filter-date-to"
                                ></DatePicker>
                            </DatePickerWrapper>
                        </LocalizationProvider>
                    </Grid>   
                    <Grid item xs={1} lg={2}>
                        <TextField
                            itemID="transaction-filter-order-num"
                            fullWidth
                            value={filterOrderNumber ?? ''}
                            label={`${translate("filters.orderNumber")}`}
                            disabled={fieldsDisabled}
                            error={isFieldMinLengthInvalid(filterOrderNumber)}
                            helperText={isFieldMinLengthInvalid(filterOrderNumber) ? minLengthErrorMessage : ''}
                            inputProps={{ 'aria-label': `${translate("filters.orderNumber")}`, 'maxLength': 50, }}
                            InputProps={orderNumFilterProps}
                            onChange={onOrderNumFilterChange}
                            autoComplete="off"
                            data-cy="transaction-filter-order-num"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <TextField
                            itemID="transaction-filter-transaction-type"
                            fullWidth
                            value={filterTransactionType ?? ''}
                            label={`${translate("filters.type")}`}
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': `${translate("filters.type")}`, 'maxLength': 50, }}
                            InputProps={transactionTypeFilterProps}
                            onChange={onTransactionTypeFilterChange}
                            autoComplete="off"
                            data-cy="transaction-filter-transaction-type"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <TextField
                            itemID="transaction-filter-erp-order-num"
                            fullWidth
                            value={filterErpOrderNumber ?? ''}
                            label={`${translate("filters.erpOrderNumber")}`}
                            disabled={fieldsDisabled}
                            error={isFieldMinLengthInvalid(filterErpOrderNumber)}
                            helperText={isFieldMinLengthInvalid(filterErpOrderNumber) ? minLengthErrorMessage : ''}
                            inputProps={{ 'aria-label': `${translate("filters.erpOrderNumber")}`, 'maxLength': 50, }}
                            InputProps={erpOrderNumFilterProps}
                            onChange={onErpOrderNumberFilterChange}
                            autoComplete="off"
                            data-cy="transaction-filter-erp-order-num"
                            variant="standard"
                        />
                    </Grid>
                </Grid>
                <Grid container item direction={'row'} columnGap={2} xs={12} justifyContent="center">
                    <Grid item xs={1} lg={2}>
                        <TextField
                            itemID="transaction-filter-partner-name"
                            fullWidth
                            value={filterPartnerName ?? ''}
                            label={`${translate("filters.tradingPartner")}`}
                            disabled={fieldsDisabled}
                            error={isFieldMinLengthInvalid(filterPartnerName)}
                            helperText={isFieldMinLengthInvalid(filterPartnerName) ? minLengthErrorMessage : ''}
                            inputProps={{ 'aria-label': `${translate("filters.tradingPartner")}`, 'minLength': 3, 'maxLength': 50 }}
                            InputProps={partnerNameFilterProps}
                            onChange={onPartnerNameFilterChange}
                            autoComplete="off"
                            data-cy="transaction-filter-partner-name"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <TextField
                            itemID="transaction-filter-doc-num"
                            fullWidth
                            value={filterDocumentNumber ?? ''}
                            label={`${translate("filters.documentNumber")}`}
                            disabled={fieldsDisabled}
                            error={isFieldMinLengthInvalid(filterDocumentNumber)}
                            helperText={isFieldMinLengthInvalid(filterDocumentNumber) ? minLengthErrorMessage : ''}
                            inputProps={{ 'aria-label': `${translate("filters.documentNumber")}`, 'maxLength': 50, }}
                            InputProps={documentNumFilterProps}
                            onChange={onDocNumFilterChange}
                            autoComplete="off"
                            data-cy="transaction-filter-doc-num"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                            <InputLabel id="transaction-filter-doc-type-label">{translate("filters.document")}</InputLabel>
                            <Select
                                labelId="transaction-filter-doc-type-label"
                                aria-labelledby="transaction-filter-doc-type-label"
                                id="transaction-filter-doc-type"
                                value={filterDocumentType ?? ''}
                                MenuProps={documentTypeMenuProps}
                                onChange={onDocTypeFilterChange}
                                data-cy="transaction-filter-doc-type"
                            >
                                {getDocTypeFilterList()}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} lg={2}>
                        <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                            <InputLabel id="transaction-filter-has-exception-label">{translate("filters.hasException")}</InputLabel>
                            <Select
                                labelId="transaction-filter-has-exception-label"
                                aria-labelledby="transaction-filter-has-exception-label"
                                id="transaction-filter-has-exception"
                                value={filterHasException ?? ''}
                                MenuProps={{
                                    'aria-label': `${translate("filters.hasException")}`,
                                }}
                                onChange={onHasExceptionFilterChange}
                                data-cy="transaction-filter-has-exception"
                            >
                                {getHasExceptionFilterList()}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} display={"inline-flex"} alignItems={"flex-end"}>
                        <FormControlLabel 
                            control={<FilterBarCheckbox checked={filterIsDocumentResent ?? false} onChange={onDocumentResentFilterChange} />} 
                            label={translate("filters.documentResent")}
                            aria-label={`${translate("filters.documentResent")}`}
                            disabled={fieldsDisabled}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </FilterBarTemplate>
    );
};

export default TransactionsFilterBar;