import { Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    exceptionsRow: {
      color: theme.palette.error.main,
      fontWeight: 'medium',
    },
  }),
);

export interface MobileDataCardRowProps {
  title: string;
  value: string | number | Date;
  valueFormatter?: Intl.NumberFormat | Intl.DateTimeFormat;
  exceptionRow?: boolean;
  linkTo?: React.ReactNode | undefined;
}

export const MobileDataCardRow: React.FC<MobileDataCardRowProps> = props => {
  const classes = useStyles();
  const { title, value, exceptionRow = false, valueFormatter, linkTo } = props;
  const className = exceptionRow ? classes.exceptionsRow : '';

  const getDisplayValue = () => {
    if (valueFormatter) {
      if (typeof value === 'number') return valueFormatter.format(value);
      if (value instanceof Date) return (valueFormatter as Intl.DateTimeFormat).format(value);
    }

    return value as string;
  };

  return (
    <Grid container justifyContent="space-between">
      <Typography variant="body1" fontWeight="medium" className={className}>
        {title}
      </Typography>
      <Typography variant="body1" color="textSecondary" className={className}>
        {linkTo ? linkTo : getDisplayValue()}
      </Typography>
    </Grid>
  );
};
