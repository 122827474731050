
import { Box, PaginationItem, TablePagination, Theme, useMediaQuery } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      borderBottom: 'none',
      '& .MuiTablePagination-select': {
        fontSize: '1rem',
        marginBottom: '3px'
      },
      '& .MuiTablePagination-selectLabel': {
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
          marginTop: 0,
          marginBottom: 8,
        }
      },
      '& .MuiTablePagination-displayedRows': {
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
          marginTop: 0,
          marginBottom: 8,
        }
      },
      '& .MuiTablePagination-toolbar': {
        flexWrap: 'wrap',
        justifyContent: 'end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center',
        }
      }
    },
    pageButtons: {
      textAlign: 'right',
      '& .Mui-selected': {
        backgroundColor: theme.palette.highEmphasis.main,
        color: theme.palette.highEmphasis.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.highEmphasis.dark,
        },
      },
      '& .MuiPaginationItem-ellipsis': {
        width: '32px',
        display: 'inline-block'
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      }
    },
  }),
);

interface DataGridPaginationProps {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  onPageChange: any;
  pageSizeOptions?: number[];
  onPageSizeChange?: any;
};

const DataGridPagination: FC<DataGridPaginationProps> = props => {
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const { totalItems, currentPage, pageSize, onPageChange, pageSizeOptions, onPageSizeChange } = props;

  let totalPages = totalItems > pageSize ? Math.ceil(totalItems / pageSize) : 1

  interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
  }
  
  function TablePaginationActions(props: TablePaginationActionsProps) {
    const { page, onPageChange } = props;
  
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    };

    const handleNumberButtonClick = (event: React.MouseEvent<HTMLButtonElement>, pageNum: number) => {
      onPageChange(event, pageNum - 1);
    };

    let placemantMappingArray = [];
    let arrayLength = totalPages < 7 ? totalPages : 7;
    for (let i = 1; i <= arrayLength; i++) {
      placemantMappingArray.push(i);
    }

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }} className={classes.pageButtons} style={isMobileScreen && pageSizeOptions && onPageSizeChange ? { flexGrow: 1 } : { marginBottom: isMobileScreen ? '8px' : '0px'}}>
        <PaginationItem 
          type={'previous'} 
          disabled={page === 0} 
          component="button" 
          onClick={handleBackButtonClick}
        />
          {placemantMappingArray.map((curBtn, index, arr) => {
            // Display page numbers or ellipsis between the previous button and next button
            // Based on the MUI default pagination numbers display

            // TODO: See if this logic can be simplified or at least cleaned up a bit

            // Determine whether to display the page number or the ellipsis            
            let type = 'page' as 'end-ellipsis' | 'first' | 'last' | 'next' | 'page' | 'previous' | 'start-ellipsis' | undefined;
            if (totalPages === 8) {
              if (page < 4 && index === 5) {
                type = "end-ellipsis";
              } else if (page >= 4 && index === 1) {
                type = "start-ellipsis";
              }
            } else if (totalPages > 8) {
              if ((page <= 4 || (page > 4 && page < totalPages - 3)) && index === 5) {
                type = "end-ellipsis";
              } else if ((page >= totalPages - 3 || (page >= 4 && page < totalPages - 3)) && index === 1) {
                type = "start-ellipsis";
              } 
            }

            // Determine page number to display
            let pageNum = 0;
            if (index === 0) {
              pageNum = 1;
            } else if (index === arr.length - 1) {
              pageNum = totalPages;
            } else if (totalPages <= 7 || (totalPages > 7 && page < 4)) {
              pageNum = curBtn;
            } else if (totalPages > 7) {
              if (page >= 4 && page < totalPages - 3) {
                if (index === 2) {
                  pageNum = page;
                } else if (index === 3) {
                  pageNum = page + 1;
                } else if (index > 3) {
                  pageNum = page + (index - 2);
                }
              } else if (page >= 4) {
                pageNum = totalPages - (6 - index);
              }
            }

            return (
              <PaginationItem 
                type={type} 
                page={pageNum} 
                selected={pageNum === (page + 1)} 
                component="button" 
                onClick={(event) => {handleNumberButtonClick(event, pageNum)}}
              />
            );
          })}
        <PaginationItem 
          type={'next'} 
          disabled={page >= totalPages - 1} 
          component="button" 
          onClick={handleNextButtonClick}
        />
      </Box>
    );
  }

  return (
    <TablePagination 
      className={classes.pagination}
      count={totalPages} 
      page={currentPage}
      rowsPerPage={pageSize}
      onPageChange={onPageChange}
      rowsPerPageOptions={pageSizeOptions && onPageSizeChange ? pageSizeOptions : [pageSize]}
      labelRowsPerPage={translate('grids.pagination.rowsPerPage')}
      onRowsPerPageChange={onPageSizeChange}
      ActionsComponent={TablePaginationActions}
      labelDisplayedRows={({count, page}) => {
        return `${translate('grids.pagination.page')} ${page + 1} ${translate('grids.pagination.of')} ${count} (${totalItems} ${translate('grids.pagination.items')})`;
      }}
    />
  );
};


export default DataGridPagination;