import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { useAppDispatch } from '../../app/hooks';

import { PortalUserCustomerResource, PortalUserCustomerSubscriptionResource } from '../../api/users.api.js';
import { createStyles, makeStyles } from '@mui/styles';
import { Link, Theme } from '@mui/material';
import { StorageKeyTenantId } from '../../utils/Constants';
import { setTenantId } from '../../features/app/AppSlice';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  }),
);

const getDataSource = (subscriptions: PortalUserCustomerSubscriptionResource[] | null | undefined) => new DataSource({
  store: new ArrayStore({
    data: subscriptions ?? [],
    key: 'portalUserCustomerSubscriptionId',
  }),
});

const CustomerTenantsDetailTemplate: React.FC = (props: any) => {
  const { portalUserCustomerSubscriptions } = props.data.data as PortalUserCustomerResource;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  
  const dataSource = getDataSource(portalUserCustomerSubscriptions);

  const onSelectTenantAccount = (tenantId: string) => {
    localStorage.setItem(StorageKeyTenantId, tenantId);
    dispatch(setTenantId(tenantId));
  };
  
  const tenantNameCellRender = (params: any) => {
    const { data } = params;
    
    return (
      <Link
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onSelectTenantAccount(data.portalSubscription?.tenantId);
          }}
      >
          {data.portalSubscription?.tenantName}
      </Link>
    );
  };

  

  return (
    <>
      <DataGrid
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        showColumnHeaders={false}
        noDataText={translate('customers.noTenants') || 'No Data'} 
        className={classes.gridContainer}
      >
        <Column dataField="portalSubscriptionId" cellRender={tenantNameCellRender}/>
      </DataGrid>
    </>
  );
};

export default CustomerTenantsDetailTemplate;
