import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastConfig } from '../../utils/Constants';
import { EdiActionResult } from '../../api/customers.api';

export interface ClientTransactionsState {
  error?: Error;
  saveStatus?: EdiActionResult;
  toastConfig?: ToastConfig;
}

const initialState: ClientTransactionsState = {
  error: undefined,
  saveStatus: undefined,
  toastConfig: undefined,
};

export const slice = createSlice({
  name: 'clientTransactions',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setError: (state, action: PayloadAction<Error>) => {
      // eslint-disable-next-line no-param-reassign
      state.error = action.payload;
    },
    clearError: state => {
      // eslint-disable-next-line no-param-reassign
      state.error = undefined;
    },
    clearSave: state => {
      // eslint-disable-next-line no-param-reassign
      state.saveStatus = undefined;
      // eslint-disable-next-line no-param-reassign
      state.error = undefined;
    },
    clearState: state => {
      // eslint-disable-next-line no-param-reassign
      state.error = undefined;
      // eslint-disable-next-line no-param-reassign
      state.saveStatus = undefined;
      // eslint-disable-next-line no-param-reassign
      state.toastConfig = undefined;
    },
    setSaveStatus: (state, action: PayloadAction<EdiActionResult | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.saveStatus = action.payload;
    },
    setToastConfig: (state, action: PayloadAction<ToastConfig | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.toastConfig = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {},
});

export const selectError = (state: RootState): Error | undefined => state.clientTransactions.error;
export const selectSaveStatus = (state: RootState): EdiActionResult | undefined => state.clientTransactions.saveStatus;
export const selectToastConfig = (state: RootState): ToastConfig | undefined => state.clientTransactions.toastConfig;

export const {
   clearError,
  clearSave,
  clearState,
  setError,
  setSaveStatus,
  setToastConfig
} = slice.actions;

export default slice.reducer;
