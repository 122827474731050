//import axios from "axios";

export const stringDisplay = (data: string | null | undefined, propertyName: string) => {
    if (data) {
        return data;
    }
    // getting 204 messages as well as this is pointing to commerce endpoint, so commenting out
    // log propertyName to backend
    // axios({
    //     method: 'post',
    //     url: `${process.env.REACT_APP_COMMERCE_PORTAL_API_ENDPOINT}/v1/log`,
    //     data: {
    //         severity: 'Warning',
    //         message: `${propertyName} not found.`
    //     }
    // });
    return '';
}

export const numberDisplay = (data: number | null | undefined, propertyName: string) => {
    if (data === 0) {
        return 0;
    }
    if (data) {
        return data;
    }
    // getting 204 messages as well as this is pointing to commerce endpoint, so commenting out
    // log propertyName to backend
    // axios({
    //     method: 'post',
    //     url: `${process.env.REACT_APP_COMMERCE_PORTAL_API_ENDPOINT}/v1/log`,
    //     data: {
    //         severity: 'Warning',
    //         message: `${propertyName} not found.`
    //     }
    // });
    return 0;
}