
import WarningIcon from '@mui/icons-material/Warning';
import AlertIcon from '@mui/icons-material/NotificationImportant';
import InfoIcon from '@mui/icons-material/Info';
import { createStyles, makeStyles } from '@mui/styles';
import { Grid, Theme, Typography } from '@mui/material';
import { SystemNotificationResource } from '../../api/dashboard.api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bannerContainer: {
      height: '46px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    bannerAlert: {
      color: theme.palette.toastError.main,
      background: theme.palette.toastError.light
    },
    bannerWarning: {
      color: theme.palette.toastWarning.main,
      background: theme.palette.toastWarning.light
    },
    bannerInfo: {
      color: theme.palette.toastInfo.main,
      background: theme.palette.toastInfo.light
    },
    bannerIcon: {
      marginRight: '16px',
    },
  }),
);

interface BannerProps {
  notification: SystemNotificationResource;
}
export const NotificationBanner: React.FC<BannerProps> = (props) => {
  const { notification } = props;
  const { message, notificationType } = notification;
  const classes = useStyles();
  
  const getBannerIcon = () => {
    switch (notificationType) {
      case 'alert':
        return <AlertIcon color="error" className={classes.bannerIcon}></AlertIcon>;
      case 'warning':
        return <WarningIcon color="warning" className={classes.bannerIcon}></WarningIcon>;
      case 'information':
        return <InfoIcon color="info" className={classes.bannerIcon}></InfoIcon>;
      default:
        return <WarningIcon color="warning" className={classes.bannerIcon}></WarningIcon>;
    };
  };

  const getNotificationTypeClass = () => {
    switch (notificationType) {
      case 'alert':
        return classes.bannerAlert;
      case 'warning':
        return classes.bannerWarning;
      case 'information':
        return classes.bannerInfo;
      default:
        return classes.bannerWarning;
    };
  };

  return (
    <Grid container className={`${classes.bannerContainer} ${getNotificationTypeClass()}`} >
      {getBannerIcon()}
      <Typography>{message}</Typography>
    </Grid>
  );
};
