import { DateTime } from 'luxon';

export const getShortDateStringFromDateTime = (date: DateTime, locale?: string) => {  
    if (date.isValid) {
        // If given a locale, use that, otherwise use system default
        let format = DateTime.DATE_MED;
        if (locale) {
            let shortLocaleDate = date.setLocale(locale).toLocaleString(format);
            return shortLocaleDate;
        } else {
            let sessionLocale = getSessionLocale();
            if (sessionLocale) {
                let shortLocaleDate = date.setLocale(sessionLocale).toLocaleString(format);
                return shortLocaleDate;
            }
        }
        
        let shortDate = date.toLocaleString(format);
        return shortDate;
    }
    return undefined;
};

export const getShortDateString = (isoDate: string, locale?: string) => {
    let dateTimeFromISO = DateTime.fromISO(isoDate);
    
    let dateString = getShortDateStringFromDateTime(dateTimeFromISO, locale);
    return dateString;
};

export const getTimeString = (isoDate: string, options?: { includeSeconds?: boolean, use24HourFormat?: boolean, locale?: string }) => {
    const { includeSeconds=false, use24HourFormat=false, locale } = options || {};
    let dateTimeFromISO = DateTime.fromISO(isoDate);

    // either get DateTime from ISO using passed in locale, sessionLocale or system default
    let localeDateTime = dateTimeFromISO;
    if (locale) {
        localeDateTime = dateTimeFromISO.setLocale(locale)
    } else {
        let sessionLocale = getSessionLocale();
        if (sessionLocale) {
            localeDateTime = dateTimeFromISO.setLocale(sessionLocale);
        }
    }
    
    // Based on option flags, either format Time for 24Hour and/or with/without seconds
    if (use24HourFormat) {
        let format24 = includeSeconds ? DateTime.TIME_24_WITH_SECONDS : DateTime.TIME_24_SIMPLE;
        let time24String = localeDateTime.toLocaleString(format24);
        return time24String;
    }
    let format = includeSeconds ? DateTime.TIME_WITH_SECONDS : DateTime.TIME_SIMPLE;
    let timeString = localeDateTime.toLocaleString(format);
    return timeString;
};

export const getFormattedDateTimeString = (isoDate: string, options?: { locale?: string, format?: Intl.DateTimeFormatOptions }) => {
    const { locale, format = DateTime.DATETIME_SHORT } = options || {};
    
    let dateTimeFromISO = DateTime.fromISO(isoDate);

    // either get DateTime from ISO using passed in locale, sessionLocale or system default
    let localeDateTime = dateTimeFromISO;
    if (locale) {
        localeDateTime = dateTimeFromISO.setLocale(locale)
    } else {
        let sessionLocale = getSessionLocale();
        if (sessionLocale) {
            localeDateTime = dateTimeFromISO.setLocale(sessionLocale);
        }
    }
    
    let dateTimeString = localeDateTime.toLocaleString(format);
    return dateTimeString;
};

export const setSessionLocale = (value: string) => {
    sessionStorage.setItem('session_locale', value);
}

export const getSessionLocale = () => {
    return sessionStorage.getItem('session_locale');
}