import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useDataGridNoDataText } from '../../utils/hooks/useDataGridNoDataText';
import { DateTime } from 'luxon';
import {
  clearException,
  clearOrder,
  filterOrders,
  selectPurchaseOrders,
  selectFilteredOrders,
  setOrders,
  setOrderExceptions,
  selectOrderExceptions,
  setOrderException,
  setOrdersPaginationCount,
  selectOrdersPaginationCount,
} from './PurchaseOrdersSlice';
import { Divider, Grid, IconButton, TextField, Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import WarningIcon from '@mui/icons-material/Warning';
import { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import dxDataGrid, { ContentReadyEvent } from 'devextreme/ui/data_grid';
import SummaryTemplate from '../../components/organisms/SummaryTemplate';
import CardSummaryTemplate from '../../components/organisms/CardSummaryTemplate';
import SearchIcon from '@mui/icons-material/Search';
import LaunchIcon from '@mui/icons-material/Launch';
import ResendIcon from '@mui/icons-material/Redo';
import { MobileDataCard } from '../../components/molecules/MobileDataCard';
import { LinkRouter } from '../../components/atoms/LinkRouter';
import { LinkCell } from '../../components/atoms/LinkCell';
import { MobileDataCardRow } from '../../components/atoms/MobileDataCardRow';
import { selectTenantId, selectDateFilterInterval, selectDateFilterTitle, selectIsUserCustomerTenantAdmin } from '../app/AppSlice';
import { ExceptionFilterSet, clearExceptionFilters, setExceptionFilters } from '../exceptions/ExceptionsSlice';
import { TransactionResource, useGetV1CustomersByTenantIdTransactionsQuery } from '../../api/customers.api';
import { useGetV1ExceptionsByTenantIdQuery } from '../../api/exceptions.api';
import { getDateRangeForSpecificDate, getDateFilterTitleForInterval } from '../../utils/helpers/functions';
import { getFormattedDateTimeString } from '../../utils/helpers/dateTimeUtil';
import { getSummaryTotalDisplayValue, getDataGridRowCurrencyDisplayValue } from '../../utils/helpers/currencyFormatUtil';
import { useGetSummaryData } from '../../utils/summaryData';
import { useTranslation } from 'react-i18next';
import ListWithCommonActions from '../../components/organisms/ListWithCommonActions';
import { DownloadSplitButton } from '../../components/atoms/DownloadSplitButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    downloadAll: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    resendButton: {
        color: theme.palette.primary.main,
        height: '16px',
        padding: '0',
        '&:hover': {
            color: theme.palette.primary.dark,
            fontWeight: 'bold'
        }
    },
    // this class will be used once we have a way to tell if a document was already previously resent
    resendAgainButton: {
        color: theme.palette.warning.main,
        height: '16px',
        padding: '0',
        '&:hover': {
            color: theme.palette.warning.dark,
            fontWeight: 'bold'
        }
    },
    exceptionIcon: {
      color: theme.palette.exceptionNotificationBar.main,
      height: '30px',
      fontSize: '30px',
      cursor: 'pointer',
    },
    exceptionIconMobile: {
      color: theme.palette.exceptionNotificationBar.main,
      height: '24px',
      fontSize: '24px',
      cursor: 'pointer',
    }
  }),
);

interface PurchaseOrderProps {
  timePeriod: 'today' | 'quarterly';
}

const PurchaseOrders: FC<PurchaseOrderProps> = props => {
  const { timePeriod } = props;
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const {activityDate: activityDateFilter} = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { i18n, t: translate } = useTranslation();
    
  const isUserTenantAdmin = useAppSelector(selectIsUserCustomerTenantAdmin);
  const purchaseOrders = useAppSelector(selectPurchaseOrders);
  const purchaseOrdersPaginationTotal = useAppSelector(selectOrdersPaginationCount);
  const filteredPurchaseOrders = useAppSelector(selectFilteredOrders);
  const exceptions = useAppSelector(selectOrderExceptions);
  const cachedDateFilterTitle = useAppSelector(selectDateFilterTitle);
  const cachedDateFilterInterval = useAppSelector(selectDateFilterInterval);
  const tenantId = useAppSelector(selectTenantId);

  const [isCustomLoading, setIsCustomLoading] = useState(false);
  const [isFetchingDocument, setIsFetchingDocument] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(isMobileScreen ? 5 : 25);
  const [dateFilterTitle, setDateFilterTitle] = useState(cachedDateFilterTitle);
  const [dateFilterInterval, setDateFilterInterval] = useState(cachedDateFilterInterval);
  const [selectedDataRow, setSelectedDataRow] = useState<TransactionResource | undefined>(undefined);
  const [openResendDialog, setOpenResendDialog] = useState(false);
  const [downloadHtmlClicked, setDownloadHtmlClicked] = useState(false);
  const [downloadRawEdiClicked, setDownloadRawEdiClicked] = useState(false);
  const [header, setHeader] = useState<string>(`${translate('purchaseOrders.title')} (${dateFilterTitle})`);
   
  // Get transactions
  const {refetch, data : transactions, isLoading, isFetching } = useGetV1CustomersByTenantIdTransactionsQuery(
    { 
      tenantId: tenantId as string,
      startDate: dateFilterInterval.start.toISO(),
      endDate: dateFilterInterval.end.toISO(),
      docType: 'purchaseOrder',
      limit: pageSize,
      offset: offset,
    },
    { 
      refetchOnMountOrArgChange: true,
      skip: !tenantId 
    }
  );

  // Get transactions summary
  const summaryData = useGetSummaryData(tenantId as string,dateFilterInterval.start.toISO(), dateFilterInterval.end.toISO(), 'purchaseOrder');
  
  // Get all exceptions so we can display total in card
  const { data: exceptionsData } = useGetV1ExceptionsByTenantIdQuery(
    { 
      tenantId: tenantId as string, 
      startDate: dateFilterInterval.start.toISO(),
      endDate: dateFilterInterval.end.toISO(), 
      returnAllRecords: true
    }, {
      skip: !tenantId
    }
  );

  useEffect(() => {
    // if an activityDate was passed in the url params,
    // use that for the dateFilterInterval instead of the cached interval from the header filter
    if (activityDateFilter) {
      let filterInterval = getDateRangeForSpecificDate(activityDateFilter, cachedDateFilterInterval);
      if (filterInterval) {
        setDateFilterInterval(filterInterval);
        let filterTitle = getDateFilterTitleForInterval(filterInterval, i18n.language);
        if (filterTitle) {
          setDateFilterTitle(filterTitle);
        }
      }
    } else {
      setDateFilterTitle(cachedDateFilterTitle);
    }
  }, [activityDateFilter, cachedDateFilterInterval, cachedDateFilterTitle, i18n.language]);

  useEffect(() => {
    setHeader(`${translate('purchaseOrders.title')} (${dateFilterTitle})`);
  }, [dispatch, dateFilterInterval, i18n.language, dateFilterTitle, translate]);

  useEffect(() => {
    if(!activityDateFilter && cachedDateFilterInterval.toISO() !== dateFilterInterval.toISO()) {
      setDateFilterInterval(cachedDateFilterInterval);
      setDateFilterTitle(cachedDateFilterTitle);
    }
  }, [cachedDateFilterInterval, dateFilterInterval, activityDateFilter, cachedDateFilterTitle])

  useEffect(() => {
    dispatch(clearOrder());
    dispatch(clearException());
    if (!!purchaseOrders && transactions && transactions.items) {
      dispatch(setOrders(transactions.items));
      dispatch(setOrdersPaginationCount(transactions.totalCount || 0));
    }
    // if (!!exceptions && allExceptions) {
    //   dispatch(setOrderExceptions(allExceptions));
    // }
  }, [transactions, exceptionsData]);

  const getRowValue = (rowData: TransactionResource) => {
    // format as currency using specific row currency if have it
    const { value, currency } = rowData;
    let displayValue = getDataGridRowCurrencyDisplayValue(value, currency ?? summaryData.currency);
    return displayValue;
  };
  const getRowDate = (rowData: { date: any }) => getFormattedDateTimeString(rowData.date, { format: DateTime.DATETIME_MED });
  
  const onExceptionIconClick  = (rowData: TransactionResource) => {
    // format as currency using specific row currency if have it
    const { purchaseOrderId, orderNumber, documentType, tradingPartnerId } = rowData;
    
    dispatch(setOrderException(purchaseOrderId));

    // clear and then set filters and navigation indicating want filtered result set on Exceptions
    dispatch(clearExceptionFilters());
    let filters: ExceptionFilterSet = {
        orderNumberFilter: orderNumber ?? undefined,
        docTypeFilter: documentType,
        tradingPartnerIdFilter: tradingPartnerId ?? undefined,
    };
    dispatch(setExceptionFilters(filters));

    navigate(`exceptions-filtered`);
    
  };

  const onSearchValueChange: ChangeEventHandler<HTMLInputElement> = event => {
    setSearchValue(event.target.value);
  };

  const toggleIsFetchingDocument = (isFetching: boolean) => {
      setIsFetchingDocument(isFetching);
  };

  const onDownloadHtmlClick = (selectedRow?: TransactionResource) => {
    // save off the selected row to use for the base html download process  
    // triggered by setting clicked to true
    if (selectedRow) {
        setSelectedDataRow(selectedRow);
        setDownloadHtmlClicked(true);
    }
  };

  const onDownloadRawEdiClick = (selectedRow?: TransactionResource) => {
    // save off the selected row to use for the base raw edi download process  
    // triggered by setting clicked to true
    if (selectedRow) {
        setSelectedDataRow(selectedRow);
        setDownloadRawEdiClicked(true);
    }
  };

  const onResendDocumentClick = (selectedRow?: TransactionResource) => {
    // save off the selected row to use for opening the 
    // dialog for confirming the resend
    if (selectedRow) {
        setSelectedDataRow(selectedRow);
        setOpenResendDialog(true);
    }
  };

  const resendSuccessHandler = () => {
    // need to refetch to bring the resent update into the grid
    refetch();
  };

  const resendDialogCloseHandler = () => {
    setOpenResendDialog(false);
    setSelectedDataRow(undefined);
  };

  const downloadCompletedHandler = () => {
    setDownloadHtmlClicked(false);
    setDownloadRawEdiClicked(false);
    setSelectedDataRow(undefined);
  };

  const dataGridColumns: IColumnProps[] = [
    {
      dataField: 'orderNumber',
      caption: translate('grids.orderNumber'),
      cellRender: (params) => {
        const {value, data} = params;
        return (
          <LinkCell
            params={{
              value: value!,
              data: { id: data.purchaseOrderNumber },
              to: '/purchase-orders',
            }}
            extraPreText="order-"
            onCellClick={() => {
              dispatch(setOrderException(params.data.purchaseOrderId));
            }}
          />
        );
      },
      minWidth: '125px',
    },
    {
      dataField: 'tradingPartnerName',
      caption: translate('grids.tradingPartner'),
      allowFiltering: false,
      cellRender: params => (
        <LinkRouter to={`/trading-partners/tradingPartner-${params.data.tradingPartnerId}`}>{params.value}</LinkRouter>
      ),
      minWidth: '125px',
    },
    {
      dataField: 'date',
      caption: translate('grids.dateReceived'),
      allowFiltering: false,
      calculateCellValue: getRowDate,
      minWidth: '100px',
    },
    {
      dataField: 'value',
      caption: translate('grids.value'),
      allowFiltering: false,
      calculateCellValue: getRowValue,
      minWidth: '100px',
    },
    {
      dataField: 'lines',
      allowFiltering: false,
      caption: translate('grids.numberOfLines'),
      minWidth: '100px',
    },
    {
      dataField: 'quantity',
      caption: translate('grids.totalQuantity'),
      allowFiltering: false,
      minWidth: '100px',
    },
    {
      dataField: 'exceptions',
      headerCellRender: () => {
        return (<WarningIcon aria-label={translate('grids.exceptions')}/>)
      },
      alignment: 'center',
      calculateCellValue: (rowData: { id: string, orderNumber: any, exceptions: any }) => {
        let orderExceptions = Object.keys(rowData.exceptions);
        // If rowData doesn't have exceptions, return undefined
        if (orderExceptions.length === 0) {
          return undefined;
        } else {
          return true;
        }
      },
      allowFiltering: false,
      cellRender: params => {
        let orderExceptions = Object.keys(params.data.exceptions);
        // If rowData doesn't have exceptions, don't display anything
        if (orderExceptions.length === 0) {
          return <></>;
        }
        // have exception, so display icon
        return (
          <WarningIcon
            aria-label={`${translate('grids.exceptionCheckOrder')} ${params.data.orderNumber}`}
            className={classes.exceptionIcon}
            onClick={() => onExceptionIconClick(params.data)}
          />
        ); // Little triangle alert icon
      },
      minWidth: '100px',
    },
    {
      dataField: 'id',
      caption: '',
      width: '100px',
      alignment: 'right',
      allowFiltering: false,
      cellRender: (params: { value: any; data: any }) => {
          return (
              <DownloadSplitButton
                  onDownloadHtmlClick={() => onDownloadHtmlClick(params.data)}
                  onDownloadRawEdiClick={() => onDownloadRawEdiClick(params.data)}
              />
          )
      },
    },
    {
      dataField: 'purchaseOrderId',
      caption: '',
      visible: isUserTenantAdmin,
      width: '70px',
      allowFiltering: false,
      cellRender: (params) => {
          let resendCount = params.data.resendCount;
          let resendDate = getFormattedDateTimeString(params.data.lastResendDate, { format: DateTime.DATETIME_SHORT });

          return (
              <IconButton
                  className={resendCount > 0 ? classes.resendAgainButton : classes.resendButton}
                  title={resendCount > 0 ? `${translate('grids.lastResent')}: ${resendDate}` : `${translate('grids.resend')}`}
                  onClick={() => {
                      onResendDocumentClick(params.data);
                  }}
              ><ResendIcon/></IconButton>
          );
      }
    },
  ];

  /* 
      Override of grid function to ContentReadyEvent
      of the grid to be able to call our own spinner
      to display during load 
  */
  const onContentReady = (e: ContentReadyEvent) => {
    // get the DataGrid instance
    let dataGrid = e.component as dxDataGrid;
    
    // when data is being loaded or fetched, 
    // begin custom loading to display spinner 
    // to indicate grid is loading 
    if ((isLoading || isFetching)) {
        if (!isCustomLoading)  {
            dataGrid.beginCustomLoading('');
            setIsCustomLoading(true); 
        }
    } else if (isCustomLoading) {
        // when the rows are actually loaded and we are still in custom loading,
        // then call to end loading, which hides the spinner 
        dataGrid.endCustomLoading();
        setIsCustomLoading(false); 
        // when paging, if user has scrolled to bottom, move scroll back to top
        var scrollable = dataGrid.getScrollable();  
        //scroll to top  
        scrollable.scrollTo(0);
    }
  };
  
  const gridOptions: IDataGridOptions = {
    dataSource: purchaseOrders,
    height: '55vh',
    keyExpr: 'purchaseOrderId',
    rowAlternationEnabled: true,
    sorting: {
      mode: 'none',
    },
    filterRow: {
      visible: true,
    },
    pager: {
      visible: false,
    },
    paging: {
      enabled: false,
    },
    noDataText: useDataGridNoDataText(isLoading || isFetching),
    onContentReady: onContentReady,
  };

  let paginationInfo = {
    totalItems: purchaseOrdersPaginationTotal,
    currentPage: (offset / pageSize),
    pageSize: pageSize,
    pageSizeOptions: isMobileScreen ? [5, 10, 25, 50, 100, 200, 500] : [25, 50, 100, 200, 500],
    onPageChange: (event: any, page: number) => {
      setOffset(page * pageSize);
    },
    onPageSizeChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setPageSize(parseInt(event.target.value, 10));
      setOffset(0);
    }
  }

  const getGridColumns = () => {
    return dataGridColumns.map((col, index) => <Column key={index} {...col} />);
  };

  const searchByOrderNumber = () => {
    if (!searchValue) {
      dispatch(filterOrders({ period: timePeriod, value: undefined }));
      return;
    }

    dispatch(filterOrders({ period: timePeriod, value: searchValue }));
  };

  const getCards = () => {
    let orders = filteredPurchaseOrders.length ? filteredPurchaseOrders : purchaseOrders;
    return orders.map((order: TransactionResource, i: number) => (
      <MobileDataCard key={i}>
        {dataGridColumns.map((column, index) => {
          if (index === 0) {
            return (
              <LinkRouter
                key={index}
                fontSize="1rem"
                fontWeight="medium"
                onClick={() => {
                  dispatch(setOrderException(order.purchaseOrderId));
                }}
                to={`/purchase-orders/order-${order.orderNumber}`}
              >
                {order.orderNumber}
                <LaunchIcon sx={{ marginLeft: 0.25, fontSize: '0.875rem' }} color="primary" fontSize="small" />
                <Divider sx={{ marginBlock: 0.25 }} />
              </LinkRouter>
            );
          }


          if (column.dataField !== 'exceptions') {
            return (
              <MobileDataCardRow
                key={index}
                title={column.caption}
                value={column.calculateCellValue ? column.calculateCellValue(order) : order[column.dataField as keyof TransactionResource]}
              />
            );
          } else {
            let hasException = exceptions.find(exc => exc.purchaseOrderId === order.id && exc.status === 'error');
            return (
              <MobileDataCardRow
                key={index}
                title={column.caption}
                value={column.calculateCellValue ? column.calculateCellValue(order) : order[column.dataField as keyof TransactionResource]}
                exceptionRow={!!hasException}
                linkTo={
                  !!hasException
                  ? (
                      <WarningIcon
                        className={classes.exceptionIconMobile}
                        onClick={() => onExceptionIconClick(order)}
                      />
                  ) : undefined
                }
              />
            );
          }
        })}
      </MobileDataCard>
    ));
  };

  const getMobileGridSearchBar = () => {
    return (
      <Grid marginLeft={'2px'}>
        <TextField
          value={searchValue}
          onChange={onSearchValueChange}
          fullWidth
          placeholder={translate('header.searchEmptyText') as string}
          sx={{ background: 'white', marginLeft: 0.5 }}
          InputProps={{ endAdornment: <SearchIcon onClick={searchByOrderNumber} /> }}
        />
      </Grid>
    );
  };

  const getSummaryTemplate = () => {
    return (
        <SummaryTemplate
          isBusy={isFetchingDocument}
          pageTitleText={header}
          dataCard={{
            header: dateFilterTitle,
            label1: translate('purchaseOrders.title') as string,
            value1: `${summaryData.count}`,
            label2: translate('cardSummary.totalValue') as string,
            value2: getSummaryTotalDisplayValue(summaryData.total, summaryData.currency),
            style: { boxShadow: '1px 1px 3px #00000014' },
          }}
          secondDataCard={{
            header: translate('cardSummary.exceptions') as string,
            value1: `${exceptionsData && exceptionsData.totalCount ? exceptionsData.totalCount : 0}`,
            onClick:
            exceptionsData && exceptionsData.totalCount && exceptionsData.totalCount > 0
                ? () => {
                  //Navigate to exceptions page
                  navigate('/exceptions');
                }
                : undefined,
            style: { boxShadow: '0px 3px 3px #0000001F' },
          }}
          gridOptions={gridOptions}
          gridColumns={getGridColumns()}
          isMobile={isMobileScreen}
          gridPagination={paginationInfo}
        />
    );
  };

  const getListSummaryTemplate = () => {
    return (
      <>
      {isMobileScreen ? (
        <CardSummaryTemplate
          isBusy={isFetchingDocument || isLoading || isFetching}
          pageTitleText={header}
          gridSearchBar={getMobileGridSearchBar()}
          cards={getCards()}
          gridPagination={paginationInfo} 
        />
      ) :
      (
        getSummaryTemplate()
      )}
      </>
    );
  }
  
  return (
    <ListWithCommonActions
        tenantId={tenantId}
        summaryTemplate={getListSummaryTemplate()}
        selectedRowData={selectedDataRow}
        openResendDialog={openResendDialog}
        downloadHtmlClicked={downloadHtmlClicked}
        downloadRawEdiClicked={downloadRawEdiClicked}
        toggleIsFetchingDocument={toggleIsFetchingDocument}
        onResendSuccess={resendSuccessHandler}
        onResendDialogClose={resendDialogCloseHandler}
        onDownloadComplete={downloadCompletedHandler}
    />
  );

};

export default PurchaseOrders;
