import { FC } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  DialogActions, DialogContent, DialogContentText, DialogTitle,
  Paper,
  Typography,
  Theme
} from "@mui/material";

interface ConfirmationPromptProps {
  heading: string;
  message: string;
  confirmButtonText: string;
  rejectButtonText: string;
  isBusy?: boolean;
  errorMessage?: string;
  confirmButtonColor:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  rejectButtonColor:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  handleConfirm: () => void;
  handleReject: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    promptContainer: {
        '& .MuiDialog-paper': { 
            width: '80%', 
            maxHeight: 435 
        },        
        ...(theme.palette.mode === 'light') ? {} : {border: '1px solid rgba(255,255,255,0.4)'},
        
    },
    promptActions: {
        margin: '0 8px 8px 0'
    },
    promptContent: {
        whiteSpace: 'pre-line' //to allow for line breaks in the string message
    },
    promptErrorMessage: {
        whiteSpace: 'pre-line', //to allow for line breaks in the string message
        fontSize: '14px'
    },

  }),
);

export const ConfirmationPrompt: FC<ConfirmationPromptProps> = props => {
    const classes = useStyles();
  
  let isDisabled = (props.errorMessage && props.errorMessage.length > 0) || props.isBusy === true;
  
  return (
    <Paper className={classes.promptContainer}>
      <DialogTitle>{props.heading}</DialogTitle>
      {props.errorMessage && (
        <Alert severity="error">
          <AlertTitle>Unable to {props.heading}</AlertTitle>
          <Typography className={classes.promptErrorMessage}>{props.errorMessage}</Typography>
        </Alert>
      )}
      <DialogContent>
        <DialogContentText className={classes.promptContent}>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.promptActions}>
        <CircularProgress
          aria-label={"progress spinner"}
          size={20}
          sx={{
            display: props.isBusy ? "block" : "none",
            margin: "0 10px 0 0",
          }}
        />
        <Button
          disabled={props.isBusy}
          autoFocus
          color={props.rejectButtonColor}
          onClick={props.handleReject}
          sx={{
            margin: "0 5px 0 0",
          }}
        >
          {props.rejectButtonText}
        </Button>
        <Button
          variant="contained"
          disabled={isDisabled}
          color={props.confirmButtonColor}
          onClick={props.handleConfirm}
        >
          {props.confirmButtonText}
        </Button>
      </DialogActions>
    </Paper>
  );
};

