export enum DatePeriod {
    Today = "TODAY",
    Week = "WEEK",
    Month = "MONTH",
    Quarter = "QUARTER",
    FirstQuarter = "FIRST_QUARTER", 
    SecondQuarter = "SECOND_QUARTER",
    ThirdQuarter = "THIRD_QUARTER",
    Year = "YEAR",
    LastWeek = "LAST_WEEK",
    LastMonth = "LAST_MONTH",
    Custom = "CUSTOM"
}
