import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "../../app/store";
import { DateTime } from 'luxon';
import { NormalizedDocumentType, TransactionResource, TransactionResourcePaginatedResponse } from '../../api/customers.api';

export interface TransactionsFilterSet {
  filterOrderNum?: string;
  filterDateFrom?: DateTime;
  filterDateTo?: DateTime;
  filterDocType?: NormalizedDocumentType;
  filterPartner?: string;
  filterDocNum?: string;
  filterErpOrderNum?: string;
  filterTransactionType?: string;
  filterHasException?: boolean;
  filterIsDocumentResent?: boolean;
};

export interface TransactionsState {
    filters?: TransactionsFilterSet;
    documentTypes?: NormalizedDocumentType[];
    transactionsForExport?: TransactionResource[];
    fetchTransactionsForExportResponse?: TransactionResourcePaginatedResponse;
    fetchForExportError?: Error;
};

const initialState: TransactionsState = {
    filters: undefined,
    documentTypes: undefined,
    transactionsForExport: undefined,
    fetchTransactionsForExportResponse: undefined,
    fetchForExportError: undefined,
};

export const slice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        clearTransactionFilters: state => {
          if (state.filters) {
              // eslint-disable-next-line no-param-reassign
              state.filters = undefined;
          }
        },
        clearFetchForExport: state => {
          // eslint-disable-next-line no-param-reassign
          state.transactionsForExport = undefined;
          // eslint-disable-next-line no-param-reassign
          state.fetchTransactionsForExportResponse = undefined;
          // eslint-disable-next-line no-param-reassign
          state.fetchForExportError = undefined;
        },
        setFetchForExportError: (state, action: PayloadAction<Error | undefined>) => {
          // eslint-disable-next-line no-param-reassign
          state.fetchForExportError = action.payload;
        },
        setTransactionFilters: (state, action: PayloadAction<TransactionsFilterSet>) => {
          // eslint-disable-next-line no-param-reassign
          state.filters = action.payload;
        },
        setDocumentTypes: (state, action: PayloadAction<NormalizedDocumentType[]>) => {
          // eslint-disable-next-line no-param-reassign
          state.documentTypes = action.payload;
        },
        fetchTransactionsForExportSuccess: (state, action: PayloadAction<TransactionResourcePaginatedResponse | undefined>) => {
          // eslint-disable-next-line no-param-reassign
          state.fetchTransactionsForExportResponse = action.payload;
          // eslint-disable-next-line no-param-reassign
          state.transactionsForExport = action.payload?.items ?? undefined;
        },
        
    },
    extraReducers: builder => {},
  });

  export const selectTransactionFilters = (state: RootState): TransactionsFilterSet | undefined => state.transactions.filters;
  export const selectDocumentTypes = (state: RootState): NormalizedDocumentType[] | undefined => state.transactions.documentTypes;
  export const selectTransactionsForExport = (state: RootState): TransactionResource[] | undefined => state.transactions.transactionsForExport;
  export const selectFetchForExportError = (state: RootState): Error | undefined => state.transactions.fetchForExportError;

  export const {
      clearTransactionFilters,
      clearFetchForExport,
      setTransactionFilters,
      setDocumentTypes,
      fetchTransactionsForExportSuccess,
      setFetchForExportError
  } =  slice.actions;

export default slice.reducer;