import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionResource } from '../../api/customers.api';
import { ExceptionResource } from '../../api/exceptions.api';
import { PurchaseOrderResource } from '../../api/purchaseOrders.api';
import { RootState } from "../../app/store";

export interface PurchaseOrdersState {
    orders: TransactionResource[];
    ordersPaginationCount: number;
    todaysOrders: TransactionResource[];
    filteredOrders: TransactionResource[];
    exceptions: ExceptionResource[];
    status: 'idle' | 'loading' | 'failed';
    order?: PurchaseOrderResource;
    error?: Error;
    exception?: ExceptionResource;
    orderId?: string;
    rawEDI?: string;
    rawEDIerrored: boolean;
    document?: string;
    documentName?: string;
    getHtmlDocumentStatus?: any;
    getRawEdiDocumentStatus?: any;
    documentError?: Error;
    resendFileName?: string;
    resendFileStatus?: any;
    resendError?: Error;
}

const initialState: PurchaseOrdersState = {
    orders: [],
    ordersPaginationCount: 0,
    todaysOrders: [],
    exceptions: [],
    filteredOrders: [],
    status: 'idle',
    order: undefined,
    error: undefined,
    exception: undefined,
    orderId: undefined,
    rawEDI: undefined,
    rawEDIerrored: false,
    getRawEdiDocumentStatus: undefined,
    document: undefined,
    documentName: undefined,
    getHtmlDocumentStatus: undefined,
    documentError: undefined,
    resendFileName: undefined,
    resendFileStatus: undefined,
    resendError: undefined,
};

export const slice = createSlice({
    name: 'purchaseOrders',
    initialState,
    reducers: {
        clearOrder: state => {
            if (state.order) {
                // eslint-disable-next-line no-param-reassign
                state.order = undefined;
            }
            if (state.orderId) {
                // eslint-disable-next-line no-param-reassign
                state.orderId = undefined;
            }
            // eslint-disable-next-line no-param-reassign
            state.rawEDI = undefined;
            // eslint-disable-next-line no-param-reassign
            state.rawEDIerrored = false;
            // eslint-disable-next-line no-param-reassign
            state.getRawEdiDocumentStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.document = undefined;
            // eslint-disable-next-line no-param-reassign
            state.documentName = undefined;
            // eslint-disable-next-line no-param-reassign
            state.getHtmlDocumentStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.documentError = undefined;
            // eslint-disable-next-line no-param-reassign
            state.resendFileName = undefined;
            // eslint-disable-next-line no-param-reassign
            state.resendFileStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.resendError = undefined;

        },
        clearException: state => {
            if (state.exception) {
                // eslint-disable-next-line no-param-reassign
                state.exception = undefined;
            }
        },
        clearDownload: state => {
            // eslint-disable-next-line no-param-reassign
            state.document = undefined;
            // eslint-disable-next-line no-param-reassign
            state.documentName = undefined;
            // eslint-disable-next-line no-param-reassign
            state.getHtmlDocumentStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.getRawEdiDocumentStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.documentError = undefined;
        },
        clearResend: state => {
            // eslint-disable-next-line no-param-reassign
            state.resendFileName = undefined;
            // eslint-disable-next-line no-param-reassign
            state.resendFileStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.resendError = undefined;
        },
        setOrders: (state, action: PayloadAction<TransactionResource[]>) => {
            state.orders = action.payload;
        },
        setOrdersPaginationCount: (state, action: PayloadAction<number>) => {
            state.ordersPaginationCount = action.payload;
        },
        setOrderExceptions: (state, action: PayloadAction<ExceptionResource[]>) => {
            state.exceptions = action.payload;
        },
        setOrderId: (state, action: PayloadAction<string | undefined>) => {
            state.orderId = action.payload;
        },
        setOrder: (state, action: PayloadAction<PurchaseOrderResource>) => {
            state.order = action.payload;
        },
        setOrderException: (state, action: PayloadAction<string | undefined | null>) => {
            if (action.payload) {
                if (state.exceptions.length > 0) {
                    var exception = state.exceptions.find((exc: ExceptionResource) => {
                        return exc.purchaseOrderId === action.payload;
                    });
                    if (exception) {
                        state.exception = exception;
                    }
                }
            }
        },
        filterOrders: (state, action: PayloadAction<{ value: string | undefined, period: 'today' | 'quarterly' }>) => {
            const { value, period } = action.payload;
            if (!value) {
                state.filteredOrders = [];
                return;
            }
            if (period === 'today') {
                state.filteredOrders = state.todaysOrders.filter((order: TransactionResource) => order.orderNumber?.includes(value));
            } else {
                state.filteredOrders = state.orders.filter((order: TransactionResource) => order.orderNumber?.includes(value));
            }
        },
        setRawEdi: (state, action: PayloadAction<string | undefined>) => {
            state.rawEDI = action.payload;
        },
        setRawEdiErrored: (state, action: PayloadAction<boolean>) => {
            state.rawEDIerrored = action.payload;
        },
        setRawEdiDocumentStatus: (state, action: PayloadAction<any | undefined>) => {
            state.getRawEdiDocumentStatus = action.payload;
        },
        setDocument: (state, action: PayloadAction<string | undefined>) => {
            state.document = action.payload;
        },
        setDocumentName: (state, action: PayloadAction<string | undefined>) => {
            state.documentName = action.payload;
        },
        setHtmlDocumentStatus: (state, action: PayloadAction<any | undefined>) => {
            state.getHtmlDocumentStatus = action.payload;
        },
        setDocumentError: (state, action: PayloadAction<Error | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.documentError = action.payload;
        },
        setTransactionResendFileName: (state, action: PayloadAction<string | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.resendFileName = action.payload;
        },
        setTransactionResendFileStatus: (state, action: PayloadAction<any | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.resendFileStatus = action.payload;
        },
        setTransactionResendError: (state, action: PayloadAction<Error | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.resendError = action.payload;
        },
    },
    extraReducers: builder => { },
});

export const selectPurchaseOrders = (state: RootState): TransactionResource[] => state.purchaseOrders.orders;
export const selectOrdersPaginationCount = (state: RootState): number => state.purchaseOrders.ordersPaginationCount;
export const selectTodaysPurchaseOrders = (state: RootState): TransactionResource[] => state.purchaseOrders.todaysOrders;
export const selectFilteredOrders = (state: RootState): TransactionResource[] => state.purchaseOrders.filteredOrders;
export const selectPurchaseOrder = (state: RootState): PurchaseOrderResource | undefined => state.purchaseOrders.order;
export const selectOrderId = (state: RootState): string | undefined => state.purchaseOrders.orderId;
export const selectOrderExceptions = (state: RootState): ExceptionResource[] => state.purchaseOrders.exceptions;
export const selectOrderException = (state: RootState): ExceptionResource | undefined => state.purchaseOrders.exception;
export const selectRawEDI = (state: RootState): string | undefined => state.purchaseOrders.rawEDI;
export const selectRawEDIErrored = (state: RootState): boolean => state.purchaseOrders.rawEDIerrored;
export const selectGetRawEdiDocumentStatus = (state: RootState): any | undefined => state.purchaseOrders.getRawEdiDocumentStatus;
export const selectDocument = (state: RootState): string | undefined => state.purchaseOrders.document;
export const selectDocumentName = (state: RootState): string | undefined => state.purchaseOrders.documentName;
export const selectGetHtmlDocumentStatus = (state: RootState): any | undefined => state.purchaseOrders.getHtmlDocumentStatus;
export const selectDocumentError = (state: RootState): Error | undefined => state.purchaseOrders.documentError;
export const selectResendFileName = (state: RootState): string | undefined => state.purchaseOrders.resendFileName;
export const selectResendFileStatus = (state: RootState): any | undefined => state.purchaseOrders.resendFileStatus;
export const selectResendError = (state: RootState): Error | undefined => state.purchaseOrders.resendError;

export const {
    clearOrder,
    clearException,
    clearDownload,
    clearResend,
    setOrders,
    setOrdersPaginationCount,
    setOrderExceptions,
    setOrderId,
    setOrder,
    setOrderException,
    filterOrders,
    setRawEdi,
    setRawEdiErrored,
    setRawEdiDocumentStatus,
    setDocument,
    setDocumentName,
    setHtmlDocumentStatus,
    setDocumentError,
    setTransactionResendFileName,
    setTransactionResendFileStatus,
    setTransactionResendError
} = slice.actions;

export default slice.reducer;