import { emptySplitApi as api } from './empty.api';
export const addTagTypes = ['CustomerUsers', 'User'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getV1CustomersByCustomerIdUsersAndUserId: build.query<
        GetV1CustomersByCustomerIdUsersAndUserIdApiResponse,
        GetV1CustomersByCustomerIdUsersAndUserIdApiArg
      >({
        query: queryArg => ({ url: `/v1/customers/${queryArg.customerId}/users/${queryArg.userId}` }),
        providesTags: ['CustomerUsers'],
      }),
      deleteV1CustomersByCustomerIdUsersAndUserId: build.mutation<
        DeleteV1CustomersByCustomerIdUsersAndUserIdApiResponse,
        DeleteV1CustomersByCustomerIdUsersAndUserIdApiArg
      >({
        query: queryArg => ({ url: `/v1/customers/${queryArg.customerId}/users/${queryArg.userId}`, method: 'DELETE' }),
        invalidatesTags: ['CustomerUsers'],
      }),
      putV1CustomersByCustomerIdUsersAndUserId: build.mutation<
        PutV1CustomersByCustomerIdUsersAndUserIdApiResponse,
        PutV1CustomersByCustomerIdUsersAndUserIdApiArg
      >({
        query: queryArg => ({
          url: `/v1/customers/${queryArg.customerId}/users/${queryArg.userId}`,
          method: 'PUT',
          body: queryArg.updateUserResource,
        }),
        invalidatesTags: ['CustomerUsers'],
      }),
      getV1CustomersByCustomerIdUsers: build.query<GetV1CustomersByCustomerIdUsersApiResponse, GetV1CustomersByCustomerIdUsersApiArg>({
        query: queryArg => ({ url: `/v1/customers/${queryArg.customerId}/users` }),
        providesTags: ['CustomerUsers'],
      }),
      postV1CustomersByCustomerIdUsers: build.mutation<
        PostV1CustomersByCustomerIdUsersApiResponse,
        PostV1CustomersByCustomerIdUsersApiArg
      >({
        query: queryArg => ({ url: `/v1/customers/${queryArg.customerId}/users`, method: 'POST', body: queryArg.createUserResource }),
        invalidatesTags: ['CustomerUsers'],
      }),
      getV1Users: build.query<GetV1UsersApiResponse, GetV1UsersApiArg>({
        query: () => ({ url: `/v1/users` }),
        providesTags: ['User'],
      }),
      getV1UsersByExternalId: build.query<GetV1UsersByExternalIdApiResponse, GetV1UsersByExternalIdApiArg>({
        query: queryArg => ({ url: `/v1/users/${queryArg.externalId}` }),
        providesTags: ['User'],
      }),
      getV1UsersMigrate: build.query<GetV1UsersMigrateApiResponse, GetV1UsersMigrateApiArg>({
        query: () => ({ url: `/v1/users/migrate` }),
        providesTags: ['User'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type GetV1CustomersByCustomerIdUsersAndUserIdApiResponse = /** status 200 Success */ UserForCustomer;
export type GetV1CustomersByCustomerIdUsersAndUserIdApiArg = {
  customerId: string;
  userId: string;
};
export type DeleteV1CustomersByCustomerIdUsersAndUserIdApiResponse = /** status 204 No Content */ undefined;
export type DeleteV1CustomersByCustomerIdUsersAndUserIdApiArg = {
  customerId: string;
  userId: string;
};
export type PutV1CustomersByCustomerIdUsersAndUserIdApiResponse = /** status 200 Success */ UserForCustomer;
export type PutV1CustomersByCustomerIdUsersAndUserIdApiArg = {
  customerId: string;
  userId: string;
  updateUserResource: UpdateUserResource;
};
export type GetV1CustomersByCustomerIdUsersApiResponse = /** status 200 Success */ UserForCustomer[];
export type GetV1CustomersByCustomerIdUsersApiArg = {
  customerId: string;
};
export type PostV1CustomersByCustomerIdUsersApiResponse = /** status 201 Created */ UserForCustomer;
export type PostV1CustomersByCustomerIdUsersApiArg = {
  customerId: string;
  createUserResource: CreateUserResource;
};
export type GetV1UsersApiResponse = /** status 200 Success */ PortalUserResource;
export type GetV1UsersApiArg = void;
export type GetV1UsersByExternalIdApiResponse = /** status 200 Success */ PortalUserResource;
export type GetV1UsersByExternalIdApiArg = {
  externalId: string;
};
export type GetV1UsersMigrateApiResponse = /** status 200 Success */ undefined;
export type GetV1UsersMigrateApiArg = void;
export type UserRole = 'unknown' | 'admin' | 'editor' | 'reader' | 'primary';
export type PortalSubscriptionResource = {
  portalSubscriptionId?: string;
  portalCustomerId?: string;
  domainService?: string | null;
  tenantId?: string;
  tenantName?: string | null;
  createdTime?: string;
};
export type PortalUserCustomerSubscriptionResource = {
  portalUserCustomerSubscriptionId?: string;
  portalUserCustomerId?: string;
  portalSubscriptionId?: string;
  role?: UserRole;
  portalSubscription?: PortalSubscriptionResource;
};
export type UserForCustomer = {
  id?: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  role?: UserRole;
  subscriptions?: PortalUserCustomerSubscriptionResource[] | null;
};
export type ApiErrorCode = 'unknown' | 'notFound' | 'validation' | 'forbidden';
export type ApiErrorDetail = {
  code?: ApiErrorCode;
  target?: string | null;
  message?: string | null;
};
export type ApiError = {
  code?: ApiErrorCode;
  message?: string | null;
  domain?: string | null;
  details?: ApiErrorDetail[] | null;
};
export type UpdateUserResource = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  customerRole?: UserRole;
  subscriptionRole?: UserRole;
  subscriptions?: PortalSubscriptionResource[] | null;
};
export type CreateUserResource = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  customerRole?: UserRole;
  subscriptionRole?: UserRole;
  subscriptions?: PortalSubscriptionResource[] | null;
};
export type PortalCustomerResource = {
  portalCustomerId?: string;
  name?: string | null;
  coid?: string | null;
  createdTime?: string;
  portalSubscriptions?: PortalSubscriptionResource[] | null;
};
export type PortalUserCustomerResource = {
  portalUserCustomerId?: string;
  portalUserId?: string;
  portalCustomerId?: string;
  role?: UserRole;
  portalCustomer?: PortalCustomerResource;
  portalUserCustomerSubscriptions?: PortalUserCustomerSubscriptionResource[] | null;
};
export type AuthorizationIdentifier = 'autH0' | 'iam';
export type PortalUserIdentifierResource = {
  portalUserIdentifierId?: string;
  portalUserId?: string;
  externalId?: string | null;
  createdBy?: string | null;
  identityProviderCode?: AuthorizationIdentifier;
};
export type PortalUserResource = {
  portalUserId?: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  createdTime?: string;
  portalUserCustomers?: PortalUserCustomerResource[] | null;
  portalUserIdentifiers?: PortalUserIdentifierResource[] | null;
};
export const {
  useGetV1CustomersByCustomerIdUsersAndUserIdQuery,
  useDeleteV1CustomersByCustomerIdUsersAndUserIdMutation,
  usePutV1CustomersByCustomerIdUsersAndUserIdMutation,
  useGetV1CustomersByCustomerIdUsersQuery,
  usePostV1CustomersByCustomerIdUsersMutation,
  useGetV1UsersQuery,
  useGetV1UsersByExternalIdQuery,
  useGetV1UsersMigrateQuery,
} = injectedRtkApi;
