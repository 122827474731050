export const breadcrumbNameMap: { [key: string]: string } = {
  '/trading-partners': 'breadcrumbs.tradingPartners',
  '/trading-partners/order-': 'breadcrumbs.transactionHistory',
  '/trading-partners/tradingPartner-/order-': 'breadcrumbs.transactionHistory',
  '/trading-partners/tradingPartner-/order-/raw-order-detail': 'breadcrumbs.rawEdi',
  '/purchase-orders': 'breadcrumbs.purchaseOrders',
  '/purchase-orders/order-': 'breadcrumbs.transactionHistory',
  '/purchase-orders/order-/raw-order-detail': 'breadcrumbs.rawEdi',
  '/purchase-orders/exceptions-filtered': 'breadcrumbs.exceptions',
  '/exceptions': 'breadcrumbs.exceptions',
  '/exceptions/order-/raw-order-detail': 'breadcrumbs.rawEdi',
  '/exceptions/order-': 'breadcrumbs.transactionHistory',
  '/transactions': 'breadcrumbs.transactionSearch',
  '/transactions/order-': 'breadcrumbs.transactionHistory',
  '/transactions/exceptions-filtered': 'breadcrumbs.exceptions',
  '/invoices': 'breadcrumbs.invoices',
  '/invoices/order-': 'breadcrumbs.transactionHistory',
  '/invoices/exceptions-filtered': 'breadcrumbs.exceptions',
  '/shipments': 'breadcrumbs.shipments',
  '/shipments/order-': 'breadcrumbs.transactionHistory',
  '/shipments/exceptions-filtered': 'breadcrumbs.exceptions',
  '/acknowledgements': 'breadcrumbs.acknowledgements',
  '/acknowledgements/order-': 'breadcrumbs.transactionHistory',
  '/warehouse-shipping-order': 'breadcrumbs.warehouseShippingOrder',
  '/warehouse-shipping-order/order-': 'breadcrumbs.transactionHistory',
  '/warehouse-shipping-order/exceptions-filtered': 'breadcrumbs.exceptions',
  '/warehouse-shipping-advice': 'breadcrumbs.warehouseShippingAdvice',
  '/warehouse-shipping-advice/order-': 'breadcrumbs.transactionHistory',
  '/warehouse-shipping-advice/exceptions-filtered': 'breadcrumbs.exceptions',
  '/warehouse-adjustment-advice': 'breadcrumbs.warehouseAdjustmentAdvice',
  '/warehouse-adjustment-advice/order-': 'breadcrumbs.transactionHistory',
  '/warehouse-adjustment-advice/exceptions-filtered': 'breadcrumbs.exceptions',
  '/stock-transfer': 'breadcrumbs.stockTransfer',
  '/stock-transfer/order-': 'breadcrumbs.transactionHistory',
  '/stock-transfer/exceptions-filtered': 'breadcrumbs.exceptions',
  '/stock-receipt': 'breadcrumbs.stockReceipt',
  '/stock-receipt/order-': 'breadcrumbs.transactionHistory',
  '/stock-receipt/exceptions-filtered': 'breadcrumbs.exceptions',
  '/notification-recipients': 'breadcrumbs.notificationRecipients',
  '/client-transactions': 'breadcrumbs.clientTransactionNotifications',
  '/user-management': 'breadcrumbs.userManagement',
};
