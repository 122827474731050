import { emptySplitApi as api } from './empty.api';
export const addTagTypes = ['PurchaseOrder'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getV1PurchaseOrders: build.query<GetV1PurchaseOrdersApiResponse, GetV1PurchaseOrdersApiArg>({
        query: queryArg => ({
          url: `/v1/purchase-orders`,
          params: { tenantId: queryArg.tenantId, orderNumber: queryArg.orderNumber, limit: queryArg.limit },
        }),
        providesTags: ['PurchaseOrder'],
      }),
      getV1PurchaseOrdersByTenantIdAndId: build.query<
        GetV1PurchaseOrdersByTenantIdAndIdApiResponse,
        GetV1PurchaseOrdersByTenantIdAndIdApiArg
      >({
        query: queryArg => ({ url: `/v1/purchase-orders/${queryArg.tenantId}/${queryArg.id}` }),
        providesTags: ['PurchaseOrder'],
      }),
      getV1PurchaseOrdersByTenantIdFileName: build.query<
        GetV1PurchaseOrdersByTenantIdFileNameApiResponse,
        GetV1PurchaseOrdersByTenantIdFileNameApiArg
      >({
        query: queryArg => ({
          url: `/v1/purchase-orders/${queryArg.tenantId}/fileName`,
          params: { purchaseorderId: queryArg.purchaseorderId, type: queryArg['type'], resourceId: queryArg.resourceId },
        }),
        providesTags: ['PurchaseOrder'],
      }),
      getV1PurchaseOrdersByTenantIdResendDocument: build.query<
        GetV1PurchaseOrdersByTenantIdResendDocumentApiResponse,
        GetV1PurchaseOrdersByTenantIdResendDocumentApiArg
      >({
        query: queryArg => ({
          url: `/v1/purchase-orders/${queryArg.tenantId}/resend-document`,
          params: { transactionId: queryArg.transactionId, persistenceId: queryArg.persistenceId },
        }),
        providesTags: ['PurchaseOrder'],
      }),
      getV1PurchaseOrdersByTenantIdRawEdiDocument: build.query<
        GetV1PurchaseOrdersByTenantIdRawEdiDocumentApiResponse,
        GetV1PurchaseOrdersByTenantIdRawEdiDocumentApiArg
      >({
        query: queryArg => ({
          url: `/v1/purchase-orders/${queryArg.tenantId}/raw-edi-document`,
          params: { transactionId: queryArg.transactionId, persistenceId: queryArg.persistenceId },
        }),
        providesTags: ['PurchaseOrder'],
      }),
      getV1PurchaseOrdersByTenantIdHtmlDocument: build.query<
        GetV1PurchaseOrdersByTenantIdHtmlDocumentApiResponse,
        GetV1PurchaseOrdersByTenantIdHtmlDocumentApiArg
      >({
        query: queryArg => ({
          url: `/v1/purchase-orders/${queryArg.tenantId}/html-document`,
          params: { transactionId: queryArg.transactionId, persistenceId: queryArg.persistenceId },
        }),
        providesTags: ['PurchaseOrder'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type GetV1PurchaseOrdersApiResponse = /** status 200 Success */ PurchaseOrderResource[];
export type GetV1PurchaseOrdersApiArg = {
  tenantId: string;
  orderNumber: string;
  limit?: number;
};
export type GetV1PurchaseOrdersByTenantIdAndIdApiResponse = /** status 200 Success */ PurchaseOrderResource;
export type GetV1PurchaseOrdersByTenantIdAndIdApiArg = {
  tenantId: string;
  id: string;
};
export type GetV1PurchaseOrdersByTenantIdFileNameApiResponse = /** status 200 Success */ string;
export type GetV1PurchaseOrdersByTenantIdFileNameApiArg = {
  tenantId: string;
  purchaseorderId: string;
  type: NormalizedDocumentType;
  resourceId: string;
};
export type GetV1PurchaseOrdersByTenantIdResendDocumentApiResponse = /** status 200 Success */ ApiResponseResource;
export type GetV1PurchaseOrdersByTenantIdResendDocumentApiArg = {
  tenantId: string;
  transactionId: string;
  persistenceId?: string;
};
export type GetV1PurchaseOrdersByTenantIdRawEdiDocumentApiResponse = /** status 200 Success */ ApiResponseResource;
export type GetV1PurchaseOrdersByTenantIdRawEdiDocumentApiArg = {
  tenantId: string;
  transactionId: string;
  persistenceId?: string;
};
export type GetV1PurchaseOrdersByTenantIdHtmlDocumentApiResponse = /** status 200 Success */ ApiResponseResource;
export type GetV1PurchaseOrdersByTenantIdHtmlDocumentApiArg = {
  tenantId: string;
  transactionId: string;
  persistenceId?: string;
};
export type OrderProcessStatus = 'notAvailable' | 'received' | 'processed' | 'acknowledged' | 'shipped' | 'invoiced';
export type NormalizedDocumentType =
  | 'unknown'
  | 'acknowledgement'
  | 'applicationAdvice'
  | 'control'
  | 'creditDebitAdjustment'
  | 'deliveryJustInTime'
  | 'deliverySchedule'
  | 'inventoryAdvice'
  | 'invoice'
  | 'organizationalRelationships'
  | 'payment'
  | 'productTransferResaleReport'
  | 'purchaseOrder'
  | 'purchaseOrderChange'
  | 'remittanceAdvice'
  | 'requestForQuotation'
  | 'requestForRoutingInstructions'
  | 'salesOrderStatus'
  | 'salesOrderStatusRequest'
  | 'shipment'
  | 'shippingSchedule'
  | 'stockReceipt'
  | 'stockTransfer'
  | 'taxcon'
  | 'textMessage'
  | 'warehouseInventoryAdjustmentAdvice'
  | 'warehouseShippingAdvice'
  | 'warehouseShippingOrder';
export type OrderAcknowledgementResource = {
  id?: string;
  createdBy?: string | null;
  createdTimestamp?: string;
  modifiedBy?: string | null;
  modifiedTimestamp?: string;
  purchaseOrderId?: string | null;
  date?: string;
  number?: string | null;
  fileName?: string | null;
  rawFileName?: string | null;
  documentType?: NormalizedDocumentType;
  transactionType?: string | null;
};
export type ShipmentResource = {
  id?: string;
  createdBy?: string | null;
  createdTimestamp?: string;
  modifiedBy?: string | null;
  modifiedTimestamp?: string;
  purchaseOrderId?: string | null;
  date?: string;
  processedDate?: string | null;
  number?: string | null;
  lines?: number;
  quantity?: number;
  fileName?: string | null;
  rawFileName?: string | null;
  lastResendDate?: string | null;
  resendCount?: number | null;
  persistenceId?: string;
  documentType?: NormalizedDocumentType;
  transactionType?: string | null;
};
export type InvoiceResource = {
  id?: string;
  createdBy?: string | null;
  createdTimestamp?: string;
  modifiedBy?: string | null;
  modifiedTimestamp?: string;
  date?: string;
  processedDate?: string | null;
  amount?: number;
  currency?: string | null;
  number?: string | null;
  lines?: number;
  quantity?: number;
  fileName?: string | null;
  rawFileName?: string | null;
  lastResendDate?: string | null;
  resendCount?: number | null;
  persistenceId?: string | null;
  documentType?: NormalizedDocumentType;
  transactionType?: string | null;
};
export type PurchaseOrderResource = {
  id?: string;
  createdBy?: string | null;
  createdTimestamp?: string;
  modifiedBy?: string | null;
  modifiedTimestamp?: string;
  customerId?: string;
  tradingPartnerId?: string;
  tradingPartnerName?: string | null;
  date?: string;
  processedDate?: string | null;
  amount?: number;
  currency?: string | null;
  number?: string | null;
  lines?: number;
  quantity?: number;
  fileName?: string | null;
  rawFileName?: string | null;
  status?: OrderProcessStatus;
  lastResendDate?: string | null;
  resendCount?: number | null;
  persistenceId?: string;
  erpOrderNumber?: string | null;
  documentType?: NormalizedDocumentType;
  transactionType?: string | null;
  orderAcknowledgements?: OrderAcknowledgementResource[] | null;
  shipments?: ShipmentResource[] | null;
  invoices?: InvoiceResource[] | null;
};
export type Error = {
  code?: string | null;
  message?: string | null;
};
export type ApiResponseResource = {
  data?: any | null;
  errors?: Error[] | null;
};
export const {
  useGetV1PurchaseOrdersQuery,
  useGetV1PurchaseOrdersByTenantIdAndIdQuery,
  useGetV1PurchaseOrdersByTenantIdFileNameQuery,
  useGetV1PurchaseOrdersByTenantIdResendDocumentQuery,
  useGetV1PurchaseOrdersByTenantIdRawEdiDocumentQuery,
  useGetV1PurchaseOrdersByTenantIdHtmlDocumentQuery,
} = injectedRtkApi;
