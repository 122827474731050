import { CircularProgress, Container, Grid } from "@mui/material";
import { PageTitle } from '../../components/atoms/PageTitle';
import DataGridPagination from "../molecules/DataGridPagination";

interface CardSummaryTemplateProps {
    isBusy?: boolean;
    //PageHeaderProps
    pageTitleText: string;
    //SubHeaderProps
    pageSubTitleText?: string;
    //Cards
    cards: React.ReactNode | React.ReactNode[];
    gridSearchBar?: React.ReactNode;
    gridPagination?: {
        totalItems: number,
        currentPage: number;
        pageSize: number;
        onPageChange: any;
        pageSizeOptions?: number[];
        onPageSizeChange?: any;
    };
}

const CardSummaryTemplate: React.FC<CardSummaryTemplateProps> = props => {
    const { pageTitleText, pageSubTitleText, cards, gridSearchBar, gridPagination, isBusy=false } = props;
    return (
        <Container component="section" disableGutters>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PageTitle title={pageTitleText} subTitle={pageSubTitleText} />
                </Grid>
                <Grid container direction="column" spacing={1} mt={1} ml={1} width={'unset'} flexGrow={1}>
                    {gridSearchBar}
                    {cards}
                </Grid>
                {gridPagination && (
                    <Grid item xs={12}>
                        <DataGridPagination {...gridPagination} />    
                    </Grid> 
                )}
            </Grid>
            {(isBusy) &&
                <CircularProgress
                    aria-label={'progress spinner'}
                    size={48}
                    sx={{
                        position: 'absolute',
                        top: '70%',
                        left: '40%',
                    }}
                />
            }   
        </Container>
    );

};

export default CardSummaryTemplate;