import { Badge } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '46px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.exceptionNotificationBar.main,
    },
    badge: {
      right: '18px',
    },
  }),
);

interface ExceptionsBadgeProps {
  openExceptionsCount: number;
}

export const ExceptionsBadge: React.FC<ExceptionsBadgeProps> = props => {
  const classes = useStyles();
  const { openExceptionsCount } = props;
  const { t: translate } = useTranslation();

  return (
    <div className={classes.root}>
      <Badge color="error" badgeContent={openExceptionsCount} className={classes.badge}></Badge>
      {openExceptionsCount === 1 ? <Typography>{translate("dashboard.exception")}</Typography> : <Typography>{translate("dashboard.exceptions")}</Typography>}
    </div>
  );
};
