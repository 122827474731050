import { LinkRouter } from './LinkRouter';

interface LinkCellProps {
  params: {
    value: string;
    to: string;
    data: {
      id: number | string;
      date?: string;
    };
  },
  style?: object;
  extraPreText?: string;
  onCellClick?: () => void;
}

export const LinkCell: React.FunctionComponent<LinkCellProps> = props => {
  const { params, style, extraPreText, onCellClick } = props;
  const {
    value,
    to,
    data: { id, date },
  } = params;

  const getPath = () => {
    // accommodating for possible date param
    let idParam = `${extraPreText ? extraPreText : ""}${id ? id : value}`;
    const idPath = idParam ? `/${idParam}` : "";
    const datePath = date ? `/date-${date}` : "";
    return `${idPath}${datePath}`;
  }
  return <LinkRouter to={`${to}${getPath()}`} style={style} onClick={onCellClick}>{value}</LinkRouter>;
};
