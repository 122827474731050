import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionResource } from '../../api/customers.api';
import { RootState } from "../../app/store";

export interface ShipmentsState {
    shipments: TransactionResource[];
    shipmentsPaginataionCount: number;    
    todaysShipments: TransactionResource[];
    filteredShipments: TransactionResource[];
    status: 'idle' | 'loading' | 'failed';
    shipment?: TransactionResource;
    shipmentId?: string;
    error?: Error;
}

const initialState: ShipmentsState = {
    shipments: [],
    shipmentsPaginataionCount: 0,
    todaysShipments: [],
    filteredShipments: [],
    status: 'idle',
    shipment: undefined,
    shipmentId: undefined,
    error: undefined
}

export const slice = createSlice({
    name: 'shipments',
    initialState,
    reducers: {
        clearShipment: state => {
            if (state.shipment) {
                // eslint-disable-next-line no-param-reassign
                state.shipment = undefined;
            }
            if (state.shipmentId) {
                // eslint-disable-next-line no-param-reassign
                state.shipmentId = undefined;
            }
        },
        filterShipments: (state, action: PayloadAction<{ value: string | undefined, period: 'today' | 'quarterly' }>) => {
            const { value, period } = action.payload;
            if (!value) {
              state.filteredShipments = [];
              return;
            }
            if (period === 'today') {
              state.filteredShipments = state.todaysShipments.filter((shipment: TransactionResource) => shipment.id?.includes(value));
            } else {
              state.filteredShipments = state.shipments.filter((shipment: TransactionResource) => shipment.id?.includes(value));
            }
          },
          setShipments: (state, action: PayloadAction<TransactionResource[]>) => {
            state.shipments = action.payload;
          },
          setShipmentsPaginationCount: (state, action: PayloadAction<number>) => {
            state.shipmentsPaginataionCount = action.payload;
          },
    },
    extraReducers: builder => {},
  });

export const selectShipments = (state: RootState): TransactionResource[] => state.shipments.shipments;
export const selectShipmentsPaginationCount = (state: RootState): number => state.shipments.shipmentsPaginataionCount;
export const selectFilteredShipments = (state: RootState): TransactionResource[] => state.shipments.filteredShipments;

export const {
    clearShipment,
    filterShipments,
    setShipments,
    setShipmentsPaginationCount
} =  slice.actions;

export default slice.reducer;