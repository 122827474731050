import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as deDE from './localization/de-de.json';
import * as enGB from './localization/en-gb.json';
import * as enUS from './localization/en-us.json';
import * as esES from './localization/es-es.json';
import * as frFR from './localization/fr-fr.json';
import * as nlNL from './localization/nl-nl.json';

i18n.use(initReactI18next).init({
    debug: true,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      'de-DE': {
        translation: deDE,
      },
      'en-US': {
        translation: enUS,
      },
      'en-GB': {
        translation: enGB,
      },
      'en-AU': {
        translation: enGB,
      },
      'en-CA': {
        translation: enGB,
      },
      'es-ES': {
        translation: esES,
      },
      'fr-FR': {
        translation: frFR,
      },
      'nl-NL': {
        translation: nlNL
      }
    },
  });
  export default i18n;