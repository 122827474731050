import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { DataGrid } from 'devextreme-react';
import { IDataGridOptions } from 'devextreme-react/data-grid';
import { forwardRef } from 'react';
import DataGridPagination from '../molecules/DataGridPagination';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      '& .Mui-selected': {
        backgroundColor: theme.palette.highEmphasis.main,
        color: theme.palette.highEmphasis.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.highEmphasis.dark,
        },
      }
    },
    dataGrid: {
      '& .dx-datagrid-focus-overlay': {
        '&:after': {
          backgroundColor: theme.palette.highEmphasis.main,
        },
      },
      '& .dx-datagrid .dx-datagrid-headers .dx-header-row td': {
        paddingBottom: '8px !important',
      },
      '& .dx-datagrid .dx-datagrid-headers.dx-state-focused .dx-header-row td': {
        '&:focus': {
          outline: `${theme.palette.highEmphasis.main} solid 2px`,
        },
      },
      '& .dx-datagrid .dx-link': {
        color: theme.palette.highEmphasis.main,
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
      '& .dx-icon-trash': {
        color: `${theme.palette.error.main} !important`,
        '&:hover': {
          color: `${theme.palette.error.dark} !important`
        }
      },
      '& .dx-datagrid .dx-datagrid-rowsview.dx-state-focused .dx-data-row > td.dx-command-edit > a.dx-link': {
        '&:focus': {
          outline: `${theme.palette.highEmphasis.main} solid 2px`,
        },
      },
      '& .dx-checkbox-checked .dx-checkbox-icon': {
        backgroundColor: theme.palette.highEmphasis.main,
      },
      '& .dx-checkbox-indeterminate .dx-checkbox-icon': {
        backgroundColor: theme.palette.highEmphasis.main,
      },
      '& .dx-loadindicator-segment-inner': {
        borderColor: `${theme.palette.highEmphasis.main} ${theme.palette.highEmphasis.main} transparent`
      },
      '& .dx-loadindicator-segment0 .dx-loadindicator-segment-inner': {
        borderLeftColor: 'transparent'
      },
      '& .dx-loadindicator-segment2 .dx-loadindicator-segment-inner': {
        borderRightColor: 'transparent'
      },
      '& .dx-datagrid .dx-datagrid-nodata': {
        whiteSpace: 'pre-line'
      }
    },
    dataGridLessRowPadding: {
      '& .dx-datagrid .dx-data-row > td, .dx-datagrid .dx-data-row > tr > td': {  
        paddingTop: '4px !important',
        paddingBottom: '4px !important'  
      },  
    },
  }),
);

interface TableTemplateProps {
  isVisible?: boolean;
  gridOptions: IDataGridOptions;
  useLessRowPadding?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  gridPagination?: {
    totalItems: number,
    currentPage: number;
    pageSize: number;
    onPageChange: any;
    pageSizeOptions?: number[];
    onPageSizeChange?: any;
  };
}

export type Ref = DataGrid;

export const TableTemplate = forwardRef<Ref, TableTemplateProps>((props, ref) => {
  const { gridOptions, isVisible=true, useLessRowPadding=false, children, gridPagination } = props;
  const classes = useStyles();
  const containerClassName = useLessRowPadding ? `${classes.dataGrid} ${classes.dataGridLessRowPadding}` : `${classes.dataGrid}`;
  
  return (
    <span>
      <DataGrid visible={isVisible} ref={ref} keyExpr="id" {...gridOptions} className={containerClassName}>
          {children}
      </DataGrid>
      {gridPagination && isVisible && (
        <span style={{float: 'right'}}>
          <DataGridPagination {...gridPagination}/>
        </span>
      )}
    </span>
  );
});
