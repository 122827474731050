// Enums
export enum ExceptionOrigin {
  Processing = 'processing',
  Acknowledgement = 'acknowledgement',
  Shipping = 'shipping',
  Invoicing = 'invoicing',
}

export enum ExceptionStatus {
  Unknown = 'unknown',
  Error = 'error',
  ResolvedError = 'resolved_Error',
}

export enum OrderStatusEnum {
  Processed = 'processed',
  Acknowledged = 'acknowledged',
  Shipped = 'shipped',
  Invoiced = 'invoiced',
}

// Basic types
export interface PurchaseOrder {
  id: string;
  order_number: string;
  value: number;
  date: string;
  no_lines: number;
  total_qty: number;
  order_status: OrderStatusEnum;
  trading_partner_id: string;
  trading_partner?: string;
}

export interface Shipment {
  id: string;
  shipment_number: string;
  invoice_date: string;
  order_number: string;
  value: number;
  trading_partner_id: string;
}

export interface Invoice {
  id: string;
  invoice_number: string;
  invoice_date: string;
  order_number: string;
  trading_partner_id: string;
  value: number;
  no_lines: number;
  total_qty: number;
}

export interface Exception {
  id: string;
  trading_partner_id: string;
  order_id: string;
  exception: ExceptionOrigin;
  description: string;
  status: ExceptionStatus;
  created_timestamp: string;
}

export interface TradePartner {
  name: string;
  id: string;
  purchase_orders: PurchaseOrder[];
  invoices: Invoice[];
  exceptions: Exception[];
  shipments: Shipment[];
}

export interface Customer {
  id: string;
  name: string;
  trade_partners: TradePartner[];
  orders: Order[];
}

export interface Order {
  order_number: string;
  value: number;
  no_lines: number;
  total_qty: number;
  order_status: OrderStatusEnum;
  trading_partner: string;
  trading_partner_id: string;
  processed_date: string;
  acknowledged_date: string;
  shipped_date: string;
  invoiced_date: string;
}

export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  FAILED = 'failed',
}
