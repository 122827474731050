import { PaletteColorOptions, PaletteMode } from '@mui/material';
declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    success?: PaletteColorOptions;
    pageSubHeader?: PaletteColorOptions;
    header?: PaletteColorOptions;
    highEmphasis?: PaletteColorOptions;
    chartOrders?: PaletteColorOptions;
    chartInvoices?: PaletteColorOptions;
    chartShipments?: PaletteColorOptions;
    exceptionNotificationBar?: PaletteColorOptions;
    exceptionsChartOpen?: PaletteColorOptions;
    exceptionsChartPending?: PaletteColorOptions;
    exceptionsChartClosed?: PaletteColorOptions;
    progressBarBaseStep?: PaletteColorOptions;
    progressBarExceptionStep?: PaletteColorOptions;
    progressBarCompletedStep?: PaletteColorOptions;
    progressBarCompletedStepShadow?: PaletteColorOptions;
    toast?: PaletteColorOptions;
    toastError?: PaletteColorOptions;
    toastInfo?: PaletteColorOptions;
    toastSuccess?: PaletteColorOptions;
    toastWarning?: PaletteColorOptions;
  }
  interface Palette {
    success: PaletteColor;
    pageSubHeader: PaletteColor;
    header: PaletteColor;
    highEmphasis: PaletteColor;
    chartOrders: PaletteColor;
    chartInvoices: PaletteColor;
    chartShipments: PaletteColor;
    exceptionNotificationBar: PaletteColor;
    exceptionsChartOpen: PaletteColor;
    exceptionsChartPending: PaletteColor;
    exceptionsChartClosed: PaletteColor;
    progressBarBaseStep: PaletteColor;
    progressBarExceptionStep: PaletteColor;
    progressBarCompletedStep: PaletteColor;
    progressBarCompletedStepShadow: PaletteColor;
    toast: PaletteColor;
    toastError: PaletteColor;
    toastInfo: PaletteColor;
    toastSuccess: PaletteColor;
    toastWarning: PaletteColor;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    label1: React.CSSProperties;
    label2: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    label1?: React.CSSProperties;
    label2?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label1: true;
    label2: true;
  }
}

const rawTheme = {
  typography: {
    label1: {
      font: 'normal normal 500 12px/14px Roboto',
      letterSpacing: 1.2,
      fontWeight: 500,
      fontSize: 12,
    },
  },
};

//const theme = createTheme({
  export const getBaseTheme = (mode: PaletteMode) => ({
  ...rawTheme,
  palette: {
    mode,
    primary: {
      main: '#266EF2'
    },
    secondary: {
        main: '#54B3BE',
        contrastText: '#FFFFFF'
    },
    success: {
        main: '#34C759'
    },
    error: {
        main: '#FF3B30'
    },
    warning: {
        main: '#FF952A'
    },
    pageSubHeader: {
      main: '#04227B',
    },
    header: {
      ...(mode === 'light')
      ? {
        main: '#00000098',
      } : {
        main: '#ffffffbf'
      }
    },
    filterBar: {
      ...(mode === 'light')
      ? {
        main: '#666666',
      } : {
        main: '#FFFFFF'
      }
    },
    filterBarBackground: {
      ...(mode === 'light')
      ? {
        main: '#EBEBEB',
      } : {
        main: '#363640'
      }
    },
    highEmphasis: {
      main: '#276EF1',
      contrastText: 'white',
      dark: '#2056BA',
    },
    chartOrders: {
      main: '#F88335',
    },
    chartInvoices: {
      main: '#1F246A',
    },
    chartShipments: {
      main: '#A54891',
    },
    exceptionNotificationBar: {
      main: '#FF3B30',
      light: '#FF3B3014',
    },
    exceptionsChartOpen: {
      main: '#E03997',
    },
    exceptionsChartPending: {
      main: '#FF952A',
    },
    exceptionsChartClosed: {
      main: '#1675A2',
    },
    progressBarBaseStep: {
        main: '#C2C2C2',
    },
    progressBarExceptionStep: {
        ...(mode === 'light')
      ? {
        main: '#FAEAEA',
      } : {
        main: '#F0C1C1',
      }
    },
    progressBarCompletedStep: {
      ...(mode === 'light')
      ? {
        main: '#EAF9E0',
      } : {
        main: '#BAE2A3',
      }
    },
    progressBarCompletedStepShadow: {
      ...(mode === 'light')
      ? {
        main: '0px 3px 6px #00000041',
      } : {
        main: '0px 3px 6px #000000cc',
      }
    },
    toast: {
      main: '#EAF9E0',
      contrastText: '#1F7A3C',
    },
    // Following toast palettes taken from Pay Style Guide
    toastError: {
      ...(mode === 'light')
      ? {
        main:  '#c70a00',
        light: '#FFEFEE',
      } : {
        light: '#c70a00',
        main:  '#FFEFEE',
      }
    },
    toastInfo: {
      ...(mode === 'light')
      ? {
        main:  '#2056BA',
        light: '#EEF4FE',
      } : {
        light: '#2056BA',
        main:  '#EEF4FE',
      }
    },
    toastSuccess: {
      ...(mode === 'light')
      ? {
        main:  '#1F7A3C',
        light: '#EAF9E0',
      } : {
        light: '#1F7A3C',
        main:  '#EAF9E0',
      }
    },
    toastWarning: {
      ...(mode === 'light')
      ? {
        main:  '#C63E15',
        light: '#FFF7EE',
      } : {
        light: '#C63E15',
        main:  '#FFF7EE',
      }
    }
  },
});

//export default theme;
