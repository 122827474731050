import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionResource } from '../../api/customers.api';
import { ExceptionResource } from '../../api/exceptions.api';
import { RootState } from "../../app/store";

export interface InvoicesState {
    invoices: TransactionResource[];
    invoicesPaginationCount: number;
    todaysInvoices: TransactionResource[];
    filteredInvoices: TransactionResource[];
    exceptions: ExceptionResource[];
    status: 'idle' | 'loading' | 'failed';
    invoice?: TransactionResource;
    error?: Error;
    exception?: ExceptionResource;
    invoiceId?: string;
}

const initialState: InvoicesState = {
    invoices: [],
    invoicesPaginationCount: 0,
    todaysInvoices: [],
    filteredInvoices: [],
    exceptions: [],
    status: 'idle',
    invoice: undefined,
    error: undefined,
    exception: undefined,
    invoiceId: undefined,
};

export const slice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        clearInvoice: state => {
            if (state.invoice) {
                // eslint-disable-next-line no-param-reassign
                state.invoice = undefined;
            }
            if (state.invoiceId) {
                // eslint-disable-next-line no-param-reassign
                state.invoiceId = undefined;
            }
        },
        clearException: state => {
            if (state.exception) {
              // eslint-disable-next-line no-param-reassign
              state.exception = undefined;
            }
          },
          setInvoices: (state, action: PayloadAction<TransactionResource[]>) => {
            state.invoices = action.payload ?? [];
          },
          setInvoicesPaginationCount: (state, action: PayloadAction<number>) => {
            state.invoicesPaginationCount = action.payload;
          },
          setInvoicesExceptions: (state, action: PayloadAction<ExceptionResource[]>) => {
            state.exceptions = action.payload;
          },
          setInvoiceId: (state, action: PayloadAction<string | undefined>) => {
            state.invoiceId = action.payload;
          },
          setInvoice: (state, action: PayloadAction<TransactionResource>) => {
            state.invoice = action.payload;
          },
          setInvoiceException: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload) {
                if (state.exceptions.length > 0) {
                  var exception = state.exceptions.find((exc: ExceptionResource) => {
                    return exc.documentNumber === action.payload;
                  });
                  if (exception) {
                    state.exception = exception;
                  }
                }
              }
        },
        filterInvoices: (state, action: PayloadAction<{ value: string | undefined, period: 'today' | 'quarterly' }>) => {
            const { value, period } = action.payload;
            if (!value) {
              state.filteredInvoices = [];
              return;
            }
            if (period === 'today') {
              state.filteredInvoices = state.todaysInvoices.filter((invoice: TransactionResource) => invoice.id?.includes(value));
            } else {
              state.filteredInvoices = state.invoices.filter((invoice: TransactionResource) => invoice.id?.includes(value));
            }
          },
    },
  extraReducers: builder => {},
});

export const selectInvoices = (state: RootState): TransactionResource[] => state.invoices.invoices;
export const selectInvoicesPaginationCount = (state: RootState): number => state.invoices.invoicesPaginationCount;
export const selectTodaysInvoices = (state: RootState): TransactionResource[] => state.invoices.todaysInvoices;
export const selectFilteredInvoices = (state: RootState): TransactionResource[] => state.invoices.filteredInvoices;
export const selectInvoice = (state: RootState): TransactionResource | undefined => state.invoices.invoice;
export const selectInvoicesId = (state: RootState): string | undefined => state.invoices.invoiceId;
export const selectInvoiceExceptions = (state: RootState): ExceptionResource[] => state.invoices.exceptions;
export const selectInvoiceException = (state: RootState): ExceptionResource | undefined => state.invoices.exception;

export const {
    clearInvoice,
    clearException,
    setInvoices,
    setInvoicesPaginationCount,
    setInvoicesExceptions,
    setInvoiceId,
    setInvoice,
    setInvoiceException,
    filterInvoices,
} = slice.actions;

export default slice.reducer;