import { Alert, Snackbar, styled, useTheme } from '@mui/material';
import { ToastSeverity } from "../../utils/Constants";

const Toast = styled(Alert)((props) => ({
    width: '100%',
    height: 'max-content',
    fontWeight: 'bold',
    ...(props.severity === ToastSeverity.Error && {
        color: useTheme().palette.toastError.main,
        background: useTheme().palette.toastError.light,
        '& .MuiAlert-icon': {
            color: useTheme().palette.toastError.main,
        }
    }),
    ...(props.severity === ToastSeverity.Info && {
        color: useTheme().palette.toastInfo.main,
        background: useTheme().palette.toastInfo.light,
        '& .MuiAlert-icon': {
            color: useTheme().palette.toastInfo.main,
        }
    }),
    ...(props.severity === ToastSeverity.Success && {
        color: useTheme().palette.toastSuccess.main,
        background: useTheme().palette.toastSuccess.light,
        '& .MuiAlert-icon': {
            color: useTheme().palette.toastSuccess.main,
        }
    }),
    ...(props.severity === ToastSeverity.Warning && {
        color: useTheme().palette.toastWarning.main,
        background: useTheme().palette.toastWarning.light,
        '& .MuiAlert-icon': {
            color: useTheme().palette.toastWarning.main,
        }
    }),
}));

const FloatingToast = styled(Toast)((props) => ({
    width: '100%',
  }));
  
  const SnackFullWidth = styled(Snackbar)((props) => ({
    width: 'calc(100% - 48px)',
    boxShadow: '0px 3px 6px #00000029',
  }));

type SnackbarPromptOptions = {
    anchorOrigin: {
      horizontal: "center" | "left" | "right";
      vertical: "bottom" | "top";
    };
    //milliseconds
    autoHideDuration: number;
    //milliseconds
    transitionDuration?: number;
    message: string;
    severity: ToastSeverity;
  }
  
  interface SnackbarPromptProps {
    open: boolean;
    snackbarPromptOptions: SnackbarPromptOptions;
    handleClose: () => void;
  }
  const SnackbarPrompt: React.FC<SnackbarPromptProps> = (props: SnackbarPromptProps) => {
    return (
      <SnackFullWidth
        open={props.open}
        anchorOrigin={props.snackbarPromptOptions.anchorOrigin}
        autoHideDuration={props.snackbarPromptOptions.autoHideDuration}
        transitionDuration={props.snackbarPromptOptions.transitionDuration}
        onClose={props.handleClose}
        message={props.snackbarPromptOptions.message}
      >
        <FloatingToast severity={props.snackbarPromptOptions.severity}>{props.snackbarPromptOptions.message}</FloatingToast>
      </SnackFullWidth>
    );
  };
  
  export type { SnackbarPromptOptions };
  export default SnackbarPrompt;