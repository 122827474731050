import { useAppDispatch } from "../../app/hooks";
import { setCustomer, setTenantId } from "../../features/app/AppSlice";
import { StorageKeyTenantId } from "../Constants";

export const useClearActiveCustomerTenant = (includeCustomerClear?: boolean) => {
  const dispatch = useAppDispatch();

  // need to return as a function to allow for calling within 
  // a component event like onClick
  return (clearCustomer?: boolean) => {
    //remove tenant id and customer from local storage and state 
    //to allow for correct screen to display such as customers/tenant selection page
    localStorage.removeItem(StorageKeyTenantId);
    dispatch(setTenantId(undefined));
    if (clearCustomer) {
      dispatch(setCustomer(null));
    }
  };
  
};
