import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { TableTemplate } from './TableTemplate';
import { makeDataRowsFromSubscriptions, SubscriptionInfoRow } from '../../features/userManagement/UserManagementApi';
import { UserForCustomer, PortalUserCustomerSubscriptionResource, UserRole, PortalUserResource, PortalSubscriptionResource } from '../../api/users.api';

const BlankMenuItem = styled(MenuItem)((props) => ({
  height: '36px'
}));

const roleOptions = [
  {
    value: 'reader',
    label: 'Reader',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
];

interface AddUserDialogProps {
  isOpen: boolean;
  onSave: (firstName: string, lastName: string, email: string, commerceRole?: UserRole, ediRole?: UserRole, subscriptions?: PortalUserCustomerSubscriptionResource[]) => void;
  onClose: () => void;
  viewerUser?: PortalUserResource;
  userForEdit?: UserForCustomer;
  apiStatus: QueryStatus;
  errorMessage?: string;
  emails: string[];
  availableSubscriptions: PortalSubscriptionResource[];
  isIamUser?: boolean;
}

const AddUserDialog: React.FC<AddUserDialogProps> = props => {
  const { isOpen, onSave, onClose, viewerUser, userForEdit, apiStatus, errorMessage, emails, availableSubscriptions, isIamUser = false } = props;
  const { t: translate } = useTranslation();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [commerceRole, setCommerceRole] = useState<UserRole | undefined>('reader');
  const [ediRole, setEdiRole] = useState<UserRole | undefined>('reader');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');  
  const [dataRows, setDataRows] = useState<SubscriptionInfoRow[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const getDomainServiceRole = (user: UserForCustomer | undefined) => {
    if (!user || !user.subscriptions) return undefined;
    return user.subscriptions[0].role;
  };

  const setForUser = useCallback(() => {
    if (userForEdit) {
      setFirstName(userForEdit.firstName || '');
      setLastName(userForEdit.lastName || '');
      setEmail(userForEdit.email || '');
      setCommerceRole((userForEdit.role) || 'unknown');
      
      if (userForEdit.subscriptions?.length) {
        setEdiRole(getDomainServiceRole(userForEdit) || 'unknown');
        setSelectedRowIds(findUserSubIds(userForEdit.subscriptions));
      }
    }
  },[userForEdit, availableSubscriptions]);

  const setToDefaults = useCallback((closing?: boolean) => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setCommerceRole('reader');
    setEdiRole('reader');
    if (closing) {
      setSelectedRowIds([]);
      setIsFormDirty(false);
    } else {
      if (availableSubscriptions && availableSubscriptions.length === 1){
        setSelectedRowIds(findUserSubIds(availableSubscriptions));
      } else {
        setSelectedRowIds([]);
      }
    }
  },[availableSubscriptions]);

  useEffect(() => {
    if (isOpen) {
      if (!userForEdit) {
        setToDefaults();
      } else {
        setForUser();
      }
    }
  }, [userForEdit, apiStatus, isOpen, setForUser, setToDefaults]);

  useEffect(() => {
    if (availableSubscriptions && availableSubscriptions.length){
      setDataRows(makeDataRowsFromSubscriptions(availableSubscriptions));
    }
  }, [availableSubscriptions])

  const findUserSubIds = (subs?: PortalUserCustomerSubscriptionResource[]) => {
    if (subs && subs.length) {
      let ret = [] as string[];
      subs.forEach(sub => {
        if (sub.portalSubscription) {
          ret.push(sub.portalSubscription.tenantId as string);
        }
      });
      return ret;
    }
    else {
      return [];
    }
  }
  const cancelAdd = () => {    
    setToDefaults(true);
    onClose();
  };

  const validateForm = () => {
    if (!firstName.trim()) {
      return false;
    }
    if (!lastName.trim()) {
      return false;
    }
    if (!email || emailInvalid) {
      return false;
    }
    return isFormDirty;
  };

  const getSelectedSubscriptions = () => {
    let ret = [] as PortalSubscriptionResource[];
    selectedRowIds.forEach(selection => {
      let found = availableSubscriptions.find(sub => sub?.tenantId === selection);
      if (found) {
        ret.push(found);
      }
    });
    return ret; 
  };

  const submitForm = () => {
    if (validateForm()) {
      let subs = getSelectedSubscriptions();
      
      // let the user type what they want, but we need to store the email lowercase, change that before sending
      onSave(firstName, lastName, email.toLowerCase(), commerceRole || 'unknown', ediRole || 'unknown', subs);
    }
  };

  const isEmailValid = (emailString: string) => {
    if (!emailString) {
        return true;
    }
    const matches = emailString ? emailString.match(/^([\w.%+\-']+)@([\w-]+\.)+([\w]{2,})$/i) || [] : [];
    return (matches.length > 0);
  };

  const validateAndSetEmail = (toValidate: string) => {
    if (toValidate || toValidate === '') {
      const isValid = isEmailValid(toValidate);
      setEmailInvalid(!isValid || emails.includes(toValidate));
      if (!isValid) {
        setEmailErrorText(translate('userManagement.emailInvalid') as string);
      } else if (emails.includes(toValidate)) {
        setEmailErrorText(translate('userManagement.emailDuplicate') as string);
      } else {
        setEmailErrorText('');
      }
      setEmail(toValidate);
    }
  };

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFormDirty(true);
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFormDirty(true);
    setLastName(event.target.value);
  };
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFormDirty(true);
    validateAndSetEmail(event.target.value);
  };

  const handleCommerceRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFormDirty(true);
    // Set as the Role value structure.
    const roleChangeValue = event.target.value;
    setCommerceRole(roleChangeValue as UserRole);
  };

  const handleEdiRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFormDirty(true);
    // Set as the Role value structure.
    const roleChangeValue = event.target.value;
    setEdiRole(roleChangeValue as UserRole);
  };

  const onGridSelectionChanged = (e: any) => {
    setIsFormDirty(true);
    if (e && e.hasOwnProperty('selectedRowKeys')) {
      setSelectedRowIds(e.selectedRowKeys);
    }
  };

  const getRoleOptions = () => {
    let items = [];
       
    const blankItem = <BlankMenuItem key="0" value="unknown"></BlankMenuItem>;
    const mappedItems = roleOptions.map(roleValue => (
      <MenuItem key={roleValue.value} value={roleValue.value}>
        {roleValue.label}
      </MenuItem>
    ));
    
    items.push(blankItem);
    items.push(...mappedItems);

    return items;
  };
    
  const gridCols = [
    {
      dataField: 'tenantName',
      caption: translate('userManagement.tenant'),
      sortOrder: 'asc',
    },
    {
      dataField: 'tenantId',
      caption: translate('userManagement.tenantId'),
    },
  ] as IColumnProps[];

  const gridOptions: IDataGridOptions = {
    dataSource: dataRows,
    selectedRowKeys: selectedRowIds,
    height: '20vh',
    rowAlternationEnabled: true,
    scrolling: {
      mode: 'virtual',
      preloadEnabled: true,
    },
    sorting: {
      mode: 'multiple',
    },
    selection: {
      mode: 'multiple',
      showCheckBoxesMode: 'always',
      allowSelectAll: false,
    },
    onSelectionChanged: onGridSelectionChanged,
  };

  const getGridColumns = () => {
    return gridCols.map((col, index) => <Column key={index} {...col} />);
  };

  const getSubscriptionContent = () => {
    if (availableSubscriptions && availableSubscriptions.length) {
      return <TableTemplate gridOptions={gridOptions}>{getGridColumns()}</TableTemplate>;
    }
    return (
      <Grid item>
        <Typography>{translate("userManagement.noSubscriptions")}</Typography>
      </Grid>
    );
  };

  // If the user is editing their own account, don't allow them to change their own customer role
  const isCommerceRoleDisabled = (commerceRole === 'primary' || apiStatus === QueryStatus.pending || userForEdit?.id === viewerUser?.portalUserId);

  const firstNameProps = {
    'aria-label': 'first name',
    maxLength: 100,
  };
  const lastNameProps = {
    'aria-label': 'last name',
    maxLength: 100,
  };
  const emailProps = {
    'aria-label': 'email',
    maxLength: 100,
  };

  return (
    <Dialog aria-label="add user dialog" maxWidth="md" open={isOpen} scroll="paper" fullWidth>
      <DialogTitle className="dialogTitle" id="add-user-dialog-title">
        <Typography variant="body1">{userForEdit ? translate("userManagement.editUser") : translate("userManagement.addUser")}</Typography>
      </DialogTitle>
      {errorMessage && (
        <Alert severity="error">
          <Typography>{errorMessage}</Typography>
        </Alert>
      )}
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              itemID="dialog-user-first-name"
              fullWidth
              disabled={apiStatus === QueryStatus.pending || isIamUser}
              autoFocus
              value={firstName}
              label={translate('userManagement.firstName')}
              inputProps={firstNameProps}
              onChange={handleFirstNameChange}
              autoComplete="off"
              required
              data-cy="dialog-user-first-name"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              itemID="dialog-user-second-name"
              fullWidth
              disabled={apiStatus === QueryStatus.pending || isIamUser}
              value={lastName}
              label={translate('userManagement.lastName')}
              inputProps={lastNameProps}
              onChange={handleLastNameChange}
              autoComplete="off"
              required
              data-cy="dialog-user-last-name"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              itemID="dialog-user-email"
              fullWidth
              disabled={apiStatus === QueryStatus.pending || isIamUser}
              error={emailInvalid}
              helperText={emailErrorText}
              value={email}
              label={translate('userManagement.email')}
              inputProps={emailProps}
              onChange={handleEmailChange}
              autoComplete="off"
              required
              data-cy="dialog-user-email"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              itemID="dialog-user-commerce-role"
              fullWidth
              onChange={handleCommerceRoleChange}
              defaultValue={'reader'}
              disabled={isCommerceRoleDisabled}
              inputProps={{ 'aria-label': 'commerce role' }}
              label={translate('userManagement.portRole')}
              value={commerceRole}
              data-cy="dialog-user-role-commerce"
              variant="standard"
              select
            >
              {getRoleOptions()}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              itemID="dialog-user-edi-role"
              fullWidth
              onChange={handleEdiRoleChange}
              defaultValue={'reader'}
              disabled={ediRole === 'primary' || apiStatus === QueryStatus.pending}
              inputProps={{ 'aria-label': 'edi role' }}
              label={translate('userManagement.ediRole')}
              value={ediRole}
              data-cy="dialog-user-role-edi"
              variant="standard"
              select
            >
              {getRoleOptions()}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{translate('userManagement.availableSubscriptions')}</Typography>
            <Grid container justifyContent="center">
              {getSubscriptionContent()}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {apiStatus === QueryStatus.pending && <CircularProgress size={20} />}
        <Button onClick={cancelAdd} disabled={apiStatus === QueryStatus.pending} data-cy="dialog-add-edit-entity-cancel">
          {translate('userManagement.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={submitForm}
          disabled={apiStatus === QueryStatus.pending || !validateForm()}
          data-cy="dialog-add-edit-entity-save"
        >
          {translate('userManagement.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;
