import { CurrencySummaryResource } from '../../api/customers.api';
import { numberDisplay } from '../../utils/dataDisplayPipe';
import { defaultCurrencyCode } from '../../utils/Constants';

export const getNumPo = (orders: CurrencySummaryResource[] | null | undefined) => {
  if (!orders || !orders[0]) return '0';
  return numberDisplay(orders[0].quantity, 'purchase orders quantity').toString();
};

export const getTotalPo = (orders: CurrencySummaryResource[] | null | undefined) => {
  if (!orders || !orders[0]) return 0;
  return numberDisplay(orders[0].amount, 'purchase orders total amount');
};

export const getPoCurrency = (orders: CurrencySummaryResource[] | null | undefined) => {
  if (!orders || !orders[0]) return defaultCurrencyCode;
  return orders[0].currency;
};