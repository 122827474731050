import { Typography, useTheme } from '@mui/material';
//import theme from '../../Theme';
import React from 'react';

export interface PageTitleProps {
  title: string;
  subTitle?: string;
  gutterBottom?: boolean;
}

export const PageTitle: React.FC<PageTitleProps> = props => {
  const { title, subTitle, gutterBottom = false } = props;  
  const theme = useTheme();  

  return (
    <>
      <Typography component="h1" variant="h6" fontSize={28} color={theme.palette.header.main} gutterBottom={gutterBottom}>{title}</Typography>
      {subTitle && (
        <Typography component="h2" variant="h4" color={theme.palette.pageSubHeader.main} gutterBottom={gutterBottom}>
          {subTitle}
        </Typography>)
      }
    </>
  );
};
