import axios from 'axios';
import { EdiActionResult, GetV1CustomersSaveNotificationRecipientsApiArg, NotificationRecipientsResource } from '../../api/customers.api';
import { GetV1CustomersDeleteNotificationRecipientsApiArg } from '../../api/customers.api';
import { AppDispatch } from '../../app/store';
import { sec } from '../../utils/security';
import { setDeleteError, setDeleteStatus, setError, setSaveStatus } from './NotificationRecipientsSlice';

export const deleteRecipient = (options: GetV1CustomersDeleteNotificationRecipientsApiArg) =>
  async (dispatch: AppDispatch): Promise<void> => {
    let url = `${process.env.REACT_APP_EDI_PORTAL_API_ENDPOINT}/v1/customers/delete-notification-recipients`;
    const token = await sec.getAccessTokenSilently()();
      if (token) {
        axios({
          method: 'get',
          url: url as string,
          headers: { Authorization: `Bearer ${token}` },
          params: {
            notificationRecipientsId: options.notificationRecipientsId,
          }
        })
        .then (response => {
          if (response && response.data) {
            dispatch(setDeleteStatus(response.data as EdiActionResult));
          }
      })
      .catch(ex => {
        dispatch(setDeleteError(ex));
      })
    }
  };

  export const saveRecipient = (options: GetV1CustomersSaveNotificationRecipientsApiArg) =>
  async (dispatch: AppDispatch): Promise<void> => {
    let url = `${process.env.REACT_APP_EDI_PORTAL_API_ENDPOINT}/v1/customers/save-notification-recipients`;
    const token = await sec.getAccessTokenSilently()();
      if (token) {
        axios({
          method: 'get',
          url: url as string,
          headers: { Authorization: `Bearer ${token}` },
          params: {
            tenantId: options.tenantId,
            clientId: options.clientId,
            notificationRecipientsId: options.notificationRecipientsId,
            description: options.description,
            recipients: options.recipients,
            receivesErrors: options.receivesErrors,
            receivesNotifications: options.receivesNotifications,
            notificationType: options.notificationType,
          }
        })
        .then (response => {
          if (response && response.data) {
            dispatch(setSaveStatus(response.data as EdiActionResult));
          }
      })
      .catch(ex => {
        dispatch(setError(ex));
      })
    }
  };
