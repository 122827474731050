import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { Grid, Link, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { PortalUserCustomerResource, PortalUserResource } from '../../api/users.api';
import { Column, IDataGridOptions, MasterDetail } from 'devextreme-react/data-grid';
import CustomerTenantsDetailTemplate from '../../components/organisms/CustomerTenantsDetailTemplate';
import { TableTemplate } from '../../components/organisms/TableTemplate';
import { setCustomer } from '../app/AppSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tenantSelectionPageWrap: {
      padding: theme.spacing(0, 0),
      alignContent: 'start',
    },
    masterDetailStyles: {
      marginLeft: theme.spacing(6),
    }
  }),
);

interface CustomersProps {
  viewerUser?: PortalUserResource;
  customers: PortalUserCustomerResource[];
}
const Customers: React.FC<CustomersProps> = props => {
  const { viewerUser, customers } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  
  const gridOptions: IDataGridOptions = {
    dataSource: customers,
    columnAutoWidth: false,
    rowAlternationEnabled: false,
    wordWrapEnabled: true,
    height: '75vh',
    keyExpr: "portalUserCustomerId",
    sorting: {
      mode: 'none',
    },
    noDataText: `${translate('customers.noCustomers')}`,
  };

  const onCustomerClick = (customer: PortalUserCustomerResource) => {
    dispatch(setCustomer(customer));
    navigate('/user-management');
  };

  const customerNameCellRender = (params: any) => {
    const { data } = params;
    
    if (data.role === 'admin') {
      return (
        <Link 
          title={`${translate('settingsMenu.manageUsers')}`}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onCustomerClick(data);
          }}>
          <Typography variant='h6'>{data.portalCustomer?.name}</Typography>
        </Link>
      );
    } else {
      return data.portalCustomer?.name;
    }
    
  };

  return (
    <Grid
      data-cy="customer-card-select"
      aria-label="customer"
      container
      className={classes.tenantSelectionPageWrap}
    >
      <TableTemplate gridOptions={gridOptions} >
        <Column dataField="portalCustomerId" caption={`${translate('grids.customer')}`} cellRender={customerNameCellRender}/>
        <MasterDetail enabled={true} component={CustomerTenantsDetailTemplate} autoExpandAll={true}/>
      </TableTemplate>
    </Grid>
  );
};

export default Customers;
