import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastConfig } from '../../utils/Constants';
import { EdiActionResult, NotificationRecipientsResource } from '../../api/customers.api';

export interface NotificationRecipientsState {
  deleteDialogOpen: boolean;
  addEditDialogOpen: boolean;
  error?: Error;
  deleteError?: Error,
  deleteStatus?: EdiActionResult;
  saveStatus?: EdiActionResult;
  notificationRecipients: NotificationRecipientsResource[];
  selectedRecipient: NotificationRecipientsResource | null;
  tenantId: string;
  toastConfig?: ToastConfig;
}

const initialState: NotificationRecipientsState = {
  deleteDialogOpen: false,
  addEditDialogOpen: false,
  error: undefined,
  deleteError: undefined,
  deleteStatus: undefined,
  saveStatus: undefined,
  notificationRecipients: [],
  selectedRecipient: null,
  tenantId: '',
  toastConfig: undefined,
};

export const slice = createSlice({
  name: 'notificationRecipients',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setError: (state, action: PayloadAction<Error>) => {
      // eslint-disable-next-line no-param-reassign
      state.error = action.payload;
    },
    clearError: state => {
      // eslint-disable-next-line no-param-reassign
      state.error = undefined;
    },
    clearDelete: state => {
      // eslint-disable-next-line no-param-reassign
      state.deleteStatus = undefined;
      // eslint-disable-next-line no-param-reassign
      state.deleteError = undefined;
    },
    clearSave: state => {
      // eslint-disable-next-line no-param-reassign
      state.saveStatus = undefined;
      // eslint-disable-next-line no-param-reassign
      state.error = undefined;
    },
    clearState: state => {
      // eslint-disable-next-line no-param-reassign
      state.deleteDialogOpen = false;
      // eslint-disable-next-line no-param-reassign
      state.addEditDialogOpen = false;
      // eslint-disable-next-line no-param-reassign
      state.error = undefined;
      // eslint-disable-next-line no-param-reassign
      state.notificationRecipients = [];
      // eslint-disable-next-line no-param-reassign
      state.deleteStatus = undefined;
      // eslint-disable-next-line no-param-reassign
      state.deleteError = undefined;
      // eslint-disable-next-line no-param-reassign
      state.selectedRecipient = null;
      // eslint-disable-next-line no-param-reassign
      state.toastConfig = undefined;
    },
    toggleDeleteDialog: state => {
      // eslint-disable-next-line no-param-reassign
      state.deleteDialogOpen = !state.deleteDialogOpen;
    },
    toggleAddEditDialog: state => {
      // eslint-disable-next-line no-param-reassign
      state.addEditDialogOpen = !state.addEditDialogOpen;
    },
    setSelectedRecipient: (state, action: PayloadAction<NotificationRecipientsResource | null>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedRecipient = action.payload;
    },
    fetchNotificationRecipients: (state, action: PayloadAction<NotificationRecipientsResource[]>) => {
      // eslint-disable-next-line no-param-reassign
      state.notificationRecipients = action.payload;
    },
    setTenantId: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.tenantId = action.payload;
    },
    setDeleteError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.deleteError = action.payload;
    },
    setDeleteStatus: (state, action: PayloadAction<EdiActionResult | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.deleteStatus = action.payload;
    },
    setSaveStatus: (state, action: PayloadAction<EdiActionResult | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.saveStatus = action.payload;
    },
    setToastConfig: (state, action: PayloadAction<ToastConfig | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.toastConfig = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {},
});

export const selectError = (state: RootState): Error | undefined => state.notificationRecipients.error;
export const selectDeleteError = (state: RootState): Error | undefined => state.notificationRecipients.deleteError;
export const selectDeleteStatus = (state: RootState): EdiActionResult | undefined => state.notificationRecipients.deleteStatus;
export const selectSaveStatus = (state: RootState): EdiActionResult | undefined => state.notificationRecipients.saveStatus;
export const selectDeleteDialogOpen = (state: RootState) => state.notificationRecipients.deleteDialogOpen;
export const selectAddEditDialogOpen = (state: RootState) => state.notificationRecipients.addEditDialogOpen;
export const selectNotificationRecipients = (state: RootState): NotificationRecipientsResource[] => state.notificationRecipients.notificationRecipients;
export const selectSelectedRecipient = (state: RootState): NotificationRecipientsResource | null => state.notificationRecipients.selectedRecipient;
export const selectTenantId = (state: RootState): string => state.notificationRecipients.tenantId;
export const selectToastConfig = (state: RootState): ToastConfig | undefined => state.notificationRecipients.toastConfig;

export const {
  clearDelete,
  clearError,
  clearSave,
  clearState,
  setTenantId,
  setError,
  setDeleteError,
  setDeleteStatus,
  setSaveStatus,
  setSelectedRecipient,
  fetchNotificationRecipients,
  toggleDeleteDialog,
  toggleAddEditDialog,
  setToastConfig
} = slice.actions;

export default slice.reducer;
