import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import PublicIcon from '@mui/icons-material/Public';
import TransactionSearchIcon from '@mui/icons-material/Plagiarism';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import TransactionsIcon from '@mui/icons-material/ListAlt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { selectIsUserCustomerTenantAdmin } from '../../features/app/AppSlice';
import { routes } from '../../routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: 'grey',
      minWidth: 36,
    },
    drawerPaper: {
      position: 'absolute',
      minWidth: 256, 
    }
  }),
);

interface NavigationMenuProps {
  open: boolean;
  appHeaderHeight: string;
  handleClose: () => void;
  handleCommercePortalClick: () => void;
}

export const NavigationMenu: FC<NavigationMenuProps> = props => {
  const { open, appHeaderHeight, handleClose, handleCommercePortalClick } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const [navAdminOpen, setNavAdminOpen] = useState(false);
  const [navTransactionsOpen, setNavTransactionsOpen] = useState(false); 
  const [nav3PLTransactionsOpen, setNav3PLTransactionsOpen] = useState(false); 
  const { t: translate } = useTranslation();

  const isViewerAdmin = useAppSelector(selectIsUserCustomerTenantAdmin);
  
  useEffect(() => {
    if (!open) {
      setNavAdminOpen(false);
    }
  }, [open]);

  const getIcon = (name: string) => {
    switch (name) {
      case 'dashboard':
        return <HomeIcon />;
      case 'transactionSearch':
        return <TransactionSearchIcon />;
      case 'tradingPartners':
        return <BusinessIcon />;
      case 'exceptions':
        return <InfoIcon />;
      case 'commercePortal':
        return <PublicIcon />;
    }
  };

  const handleNavMenuItemClick = (path: string) => {
    navigate(path);
    handleClose();
  };

  const handleAdminClick = (event: MouseEvent<HTMLElement>) => {
    setNavAdminOpen(!navAdminOpen);
    setNavTransactionsOpen(false);
    setNav3PLTransactionsOpen(false);
  };

  const handleTransactionsClick = (event: MouseEvent<HTMLElement>) => {
    setNavTransactionsOpen(!navTransactionsOpen);
    setNav3PLTransactionsOpen(false);
    setNavAdminOpen(false);
  };

  const handle3PLTransactionsClick = (event: MouseEvent<HTMLElement>) => {
    setNav3PLTransactionsOpen(!nav3PLTransactionsOpen);
    setNavTransactionsOpen(false);
    setNavAdminOpen(false);
  };

  const mainRoutes = routes.filter((x) => { return x.parentName === undefined });
  
  const getSubMenu = (menuOpen: boolean, parentName: string, isAdminOnly?: boolean) => {
    const subRoutes = routes.filter((x) => { return x.parentName === parentName });
    
    if ((isAdminOnly && isViewerAdmin) || !isAdminOnly) {
      let menuItems = subRoutes.map(item => {
        return (
          <ListItem key={item.name} sx={{ pl: 2 }} disablePadding>
            <ListItemButton
              onClick={() => {
                handleNavMenuItemClick(item.path);
              }}
              divider
            >
              <ListItemText primary={translate(item.i18n)} />
            </ListItemButton>
          </ListItem>
        );
      });

      return (
        <Collapse in={menuOpen} timeout="auto" unmountOnExit>
            <List component="div" role="navigation" disablePadding>
              {menuItems}
            </List>
        </Collapse>
      );
    } else {
      return null;
    }
  };

  return (
    <Drawer 
      open={open} 
      classes={{
        paper: classes.drawerPaper,
      }} 
      variant="temporary" 
      onClose={handleClose} 
      anchor="left"
      sx={{
        marginTop: appHeaderHeight ? appHeaderHeight : "64px",
      }}
    >
      <List component="div" role="navigation">
        {mainRoutes.map(item => {
          return (
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                onClick={() => {
                  handleNavMenuItemClick(item.path);
                }}
                divider
              >
                <ListItemIcon className={classes.icon}>{getIcon(item.name)}</ListItemIcon>
                <ListItemText primary={translate(item.i18n)} />
              </ListItemButton>
            </ListItem>
          );
        })}
        <ListItem key={'TransCore'} disablePadding>
          <ListItemButton onClick={handleTransactionsClick} divider>
            <ListItemIcon className={classes.icon}>
              <TransactionsIcon />
            </ListItemIcon>
            <ListItemText primary={translate('navigation.transactions')} />
            {navTransactionsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        {getSubMenu(navTransactionsOpen, "CoreTransactions")}
        <ListItem key={'Trans3PL'} disablePadding>
          <ListItemButton onClick={handle3PLTransactionsClick} divider>
            <ListItemIcon className={classes.icon}>
              <LocalShippingIcon />
            </ListItemIcon>
            <ListItemText primary={translate('navigation.transactions3PL')} />
            {nav3PLTransactionsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        {getSubMenu(nav3PLTransactionsOpen, "3PLTransactions")}
        { isViewerAdmin &&
        <ListItem key={'Admin'} disablePadding>
          <ListItemButton onClick={handleAdminClick} divider>
            <ListItemIcon className={classes.icon}>
              <AdminIcon />
            </ListItemIcon>
            <ListItemText primary={translate('navigation.admin')} />
            {navAdminOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        }
        {getSubMenu(navAdminOpen, "Admin", true)}
        {/* Appending commerce portal list item to the end as it is not an edi portal route 
        <ListItem key={'commercePortal'} disablePadding>
          <ListItemButton onClick={handleCommercePortalClick}>
            <ListItemIcon className={classes.icon}>{getIcon('commercePortal')}</ListItemIcon>
            <ListItemText primary={translate('navigation.commercePortal')} />
          </ListItemButton>
        </ListItem>
        */}
      </List>
    </Drawer>
  );
};
