import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useDataGridNoDataText } from '../../utils/hooks/useDataGridNoDataText';
import { DateTime } from 'luxon';
import ExportIcon from '@mui/icons-material/IosShare';
import LaunchIcon from '@mui/icons-material/Launch';
import WarningIcon from '@mui/icons-material/Warning';
import ResendIcon from '@mui/icons-material/Redo';
import { createStyles, makeStyles } from '@mui/styles';
import { Button, IconButton, CircularProgress, Divider, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import DataGrid, { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import dxDataGrid, { ContentReadyEvent } from 'devextreme/ui/data_grid';

import { TransactionResource, NormalizedDocumentType, useGetV1CustomersByTenantIdTransactionsQuery, GetV1CustomersByTenantIdTransactionsApiArg } from '../../api/customers.api';
import {
    setSelectedOrder,
    changeSearchTerm,
    clearSearchResults,
    setDisplaySearchResults,
    selectDateFilterInterval,
    selectDisplaySearchResults,
    selectTenantId,
    selectSelectedPurchaseOrder,
    selectDateFilterTitle,
    setToastConfig,
    selectIsUserCustomerTenantAdmin
} from '../app/AppSlice';
import { ExceptionFilterSet, clearExceptionFilters, setExceptionFilters } from '../exceptions/ExceptionsSlice';
import { setOrderException } from '../purchaseOrders/PurchaseOrdersSlice';
import { TransactionsFilterSet, clearFetchForExport, selectFetchForExportError, selectTransactionsForExport } from './TransactionsSlice';
import { getTransactionsForExport } from './TransactionsAPI';

import { PageTitle } from '../../components/atoms/PageTitle';
import { LinkRouter } from '../../components/atoms/LinkRouter';
import FiltersButton from '../../components/atoms/FiltersButton';
import { DownloadSplitButton } from '../../components/atoms/DownloadSplitButton';
import { TableTemplate } from '../../components/organisms/TableTemplate';
import { MobileDataCard } from '../../components/molecules/MobileDataCard';
import { MobileDataCardRow } from '../../components/atoms/MobileDataCardRow';
import CardSummaryTemplate from '../../components/organisms/CardSummaryTemplate';
import ListWithCommonActions from '../../components/organisms/ListWithCommonActions';
import TransactionsFilterBar from '../../components/molecules/TransactionsFilterBar';

import { getDataGridRowCurrencyDisplayValue } from '../../utils/helpers/currencyFormatUtil';
import { getFormattedDateTimeString } from '../../utils/helpers/dateTimeUtil';
import { getDocumentTypeName } from '../../utils/helpers/functions';
import { exportDataGridToCSV } from '../../utils/helpers/fileUtil';
import { ToastSeverity } from '../../utils/Constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        exceptionIcon: {
            color: theme.palette.exceptionNotificationBar.main,
            height: '20px',
            fontSize: '20px',
            cursor: 'pointer',
        },
        exceptionIconMobile: {
            color: theme.palette.exceptionNotificationBar.main,
            height: '24px',
            fontSize: '24px',
            cursor: 'pointer',
        },
        resendButton: {
            color: theme.palette.primary.main,
            padding: '0',
            '&:hover': {
                color: theme.palette.primary.dark,
                fontWeight: 'bold'
            }
        },
        // this class will be used once we have a way to tell if a document was already previously resent
        resendAgainButton: {
            color: theme.palette.warning.main,
            padding: '0',
            '&:hover': {
                color: theme.palette.warning.dark,
                fontWeight: 'bold'
            }
        }
    }),
);

export const Transactions = () => {
    const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t: translate } = useTranslation();
    const {orderNumber: orderNumberFilter} = useParams();
    const gridRef = useRef<DataGrid | null>(null);
      
    const isUserTenantAdmin = useAppSelector(selectIsUserCustomerTenantAdmin);
    const tenantId = useAppSelector(selectTenantId);
    const displayPeriodTitle = useAppSelector(selectDateFilterTitle);
    const selectedOrder = useAppSelector(selectSelectedPurchaseOrder);
    const dateFilterInterval = useAppSelector(selectDateFilterInterval);
    const displaySearchResults = useAppSelector(selectDisplaySearchResults);
    const exportError = useAppSelector(selectFetchForExportError);
    const transactionsForExport = useAppSelector(selectTransactionsForExport);

    const [isCustomLoading, setIsCustomLoading] = useState(false);
    const [isCustomLoadEndDelayed, setIsCustomLoadEndDelayed] = useState(false);
    const [isFetchingDocument, setIsFetchingDocument] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [displayedOrder, setDisplayedOrder] = useState('');
    const [transactionsOffset, setTransactionsOffset] = useState(0);
    const [pageSize, setPageSize] = useState(isMobileScreen ? 10 : 50);
    const [transactionsInUse, setTransactionsInUse] = useState<{ items: TransactionResource[], total: number }>({ items: [], total: 0 });
    const [selectedDataRow, setSelectedDataRow] = useState<TransactionResource | undefined>(undefined);
    const [openResendDialog, setOpenResendDialog] = useState(false);
    const [exportClicked, setExportClicked] = useState(false);
    const [downloadHtmlClicked, setDownloadHtmlClicked] = useState(false);
    const [downloadRawEdiClicked, setDownloadRawEdiClicked] = useState(false);

    const [premadeFilter, setPremadeFilter] = useState<TransactionsFilterSet | undefined>(undefined);
    const [filterPartnerName, setFilterPartnerName] = useState<string | undefined>(undefined);
    const [filterOrderNumber, setFilterOrderNumber] = useState<string | undefined>(undefined);
    const [filterDocumentNumber, setFilterDocumentNumber] = useState<string | undefined>(undefined);
    const [filterErpOrderNumber, setFilterErpOrderNumber] = useState<string | undefined>(undefined);
    const [filterDateFrom, setFilterDateFrom] = useState<DateTime | undefined>(undefined);
    const [filterDateTo, setFilterDateTo] = useState<DateTime | undefined>(undefined);
    const [filterDocumentType, setFilterDocumentType] = useState<NormalizedDocumentType | undefined>(undefined);
    const [filterHasException, setFilterHasException] = useState<boolean | undefined>(undefined);
    const [filterTransactionType, setFilterTransactionType] = useState<string | undefined>(undefined);
    const [filterIsDocumentResent, setFilterIsDocumentResent] = useState<boolean | undefined>(undefined);
    
    const [filtersOpen, setFiltersOpen] = useState(true);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterBarSearchTriggered, setFilterBarSearchTriggered] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [pageChangeTriggered, setPageChangeTriggered] = useState(false);

    // including refetchOnMountOrArgChange to prevent caching of the list since
    // changes could be made during a resend that would need refetching of data
    // to show the updated data
    const { refetch, data: transactionsData, isFetching, isLoading } = useGetV1CustomersByTenantIdTransactionsQuery(
        {
            tenantId: tenantId as string,
            startDate: filterDateFrom ? filterDateFrom.toISO() : undefined,
            endDate: filterDateTo ? filterDateTo.toISO() : undefined,
            orderNumber: filterOrderNumber,
            docNumber: filterDocumentNumber,
            docType: filterDocumentType,
            tradingPartnerName: filterPartnerName,
            hasException: filterHasException,
            erpOrderNumber: filterErpOrderNumber,
            transactionType: filterTransactionType,
            isDocumentResent: filterIsDocumentResent,
            limit: pageSize,
            offset: transactionsOffset,
        },
        { 
            refetchOnMountOrArgChange: true,
            skip: !tenantId || (dateFilterInterval && (!premadeFilter || (premadeFilter.filterDateFrom && !filterDateFrom && !filterBarSearchTriggered)) && !(!!selectedOrder || !!orderNumberFilter)) 
        },
    );

    const setTitleOrderDisplay = useCallback(() => {
        let display = selectedOrder ? selectedOrder.number! : orderNumberFilter!;
        let erpOrderNumber = selectedOrder?.erpOrderNumber;
        if (erpOrderNumber) {
            display = `${display} / ${erpOrderNumber}`;
        } else if (!!orderNumberFilter && transactionsData?.items?.length) {
            //get erpOrderNumber from a record that has it
            let orderWithErpOrderNumber = transactionsData.items.find(t => {
                return t.erpOrderNumber !== undefined && t.erpOrderNumber !== null && t.erpOrderNumber.length > 0;
            });
            if (orderWithErpOrderNumber) {
                //erpOrderNumber = orderWithErpOrderNumber.erpOrderNumber ?? undefined;
                display = `${display} / ${orderWithErpOrderNumber.erpOrderNumber}`;
            }
        }
        if (isMobileScreen) {
            setDisplayedOrder(display);
        }
        
        let currentOrderNumber = selectedOrder?.number ?? orderNumberFilter;
        if (!premadeFilter || (premadeFilter && (premadeFilter.filterDateFrom || premadeFilter.filterOrderNum !== currentOrderNumber || premadeFilter.filterErpOrderNum !== erpOrderNumber))) { 
            setPremadeFilter({
                filterOrderNum: currentOrderNumber,
                filterErpOrderNum: erpOrderNumber
            } as TransactionsFilterSet);
        }
        
    }, [selectedOrder, orderNumberFilter, transactionsData, isMobileScreen, premadeFilter]);
    
    useEffect(() => {
        if (!!selectedOrder || !!orderNumberFilter) {
            setTransactionsOffset(0);
            //setSearchOffset(0);

            setTitleOrderDisplay();
            
            //setFilterOrderNumber(selectedOrder?.number ?? orderNumberFilter);
        } else if (dateFilterInterval && !premadeFilter) {
            setPremadeFilter({
                filterDateFrom: dateFilterInterval.start,
                filterDateTo: dateFilterInterval.end,
            } as TransactionsFilterSet);
        }
    }, [selectedOrder, orderNumberFilter, dateFilterInterval, premadeFilter, setTitleOrderDisplay]);

    useEffect(() => {
        setFilterBarSearchTriggered(false);
        setTransactionsInUse({ items: transactionsData?.items || [], total: transactionsData?.totalCount || 0 });
    }, [transactionsData]);

    useEffect(() => {
        if(exportError) {
          dispatch(setToastConfig({
            message: exportError.message,
            severity: ToastSeverity.Error
          }));
          setExportClicked(false);
          setIsExporting(false);
        }
      }, [exportError])

    useEffect(() => {
        if (transactionsInUse) {
            let numFilters = 0;

            if (filterDocumentType && filterDocumentType.length > 0) {
                numFilters += 1;
            }
            if (filterPartnerName && filterPartnerName.length > 0) {
                numFilters += 1;
            }
            if (filterOrderNumber && filterOrderNumber.length > 0) {
                numFilters += 1;
            }
            if (filterDocumentNumber && filterDocumentNumber.length > 0) {
                numFilters += 1;
            }
            if (filterErpOrderNumber && filterErpOrderNumber.length > 0) {
                numFilters += 1;
            }
            if (filterTransactionType && filterTransactionType.length > 0) {
                numFilters += 1;
            }
            if (filterDateFrom) {
                numFilters += 1;
            }
            if (filterDateTo) {
                numFilters += 1;
            }
            if (filterHasException !== undefined) {
                numFilters += 1;
            }
            if (filterIsDocumentResent !== undefined) {
                numFilters += 1;
            }
            
            setFilterCount(numFilters);
        }
    }, [transactionsInUse, filterDocumentType, filterPartnerName, filterOrderNumber, filterDocumentNumber, filterErpOrderNumber, filterDateFrom, filterDateTo, filterHasException, filterTransactionType, filterIsDocumentResent]);

    const getRowDocument = (rowData: TransactionResource) => getDocumentTypeName(rowData.documentType);
    const getRowTotalQty = (rowData: TransactionResource) => rowData.quantity;
    const getRowOrderNumber = (rowData: TransactionResource) => rowData.orderNumber;
    const getRowTradingPartner = (rowData: TransactionResource) => rowData.tradingPartnerName;
    const getRowNumberOfLines = (rowData: TransactionResource) => rowData.lines;
    const getRowHasExceptions = (rowData: TransactionResource) => Object.keys(rowData.exceptions || {}).length > 0;
    const getRowDate = (rowData: TransactionResource) => {
        // format as date 
        if (rowData?.date) {
            return getFormattedDateTimeString(rowData.date, { format: DateTime.DATETIME_MED });
        }
        return undefined;
    };
    const getRowValue = (rowData: TransactionResource) => {
        // format as currency using specific row currency if have it
        const { value, currency } = rowData;
        let displayValue = getDataGridRowCurrencyDisplayValue(value, currency);
        return displayValue;
    };
    
    const onExceptionIconClick  = (rowData: TransactionResource) => {
        // format as currency using specific row currency if have it
        const { purchaseOrderId, orderNumber, documentType, tradingPartnerId } = rowData;
        
        dispatch(setOrderException(purchaseOrderId));

        // clear and then set filters and navigation indicating want filtered result set on Exceptions
        dispatch(clearExceptionFilters());
        let filters: ExceptionFilterSet = {
            orderNumberFilter: orderNumber ?? undefined,
            docTypeFilter: documentType,
            tradingPartnerIdFilter: tradingPartnerId ?? undefined,
        };
        dispatch(setExceptionFilters(filters));

        navigate(`/transactions/exceptions-filtered`);
    };

    const setDefaultFilters = () => {
        setFilterDocumentNumber(undefined);
        setFilterDocumentType(undefined);
        setFilterErpOrderNumber(undefined);
        setFilterHasException(undefined);
        setFilterOrderNumber(undefined);
        setFilterPartnerName(undefined);
        setFilterTransactionType(undefined);
        setFilterIsDocumentResent(undefined);
    };

    const onResetClick = (): void => {
        dispatch(setDisplaySearchResults(false));
        dispatch(clearSearchResults());
        dispatch(setSelectedOrder(null));
        dispatch(changeSearchTerm(''));
        setTransactionsOffset(0);
        setPremadeFilter(undefined);
    };

    const toggleIsFetchingDocument = (isFetching: boolean) => {
        setIsFetchingDocument(isFetching);
    };

    const onFiltersButtonClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onFilterBarClearAllClick = () => {
        // clear and set filters as cleared
        setDefaultFilters();
        setFiltersCleared(true);
        setFilterCount(0);
        
        // also clear the current data to allow for new search
        onResetClick();
    };

    const onExportClick = () => {
        // clear previous results
        dispatch(clearFetchForExport());

        // trigger the fetch of all data so that it can be exported
        setExportClicked(true);
        setIsExporting(true);
        
        dispatch(getTransactionsForExport({
            tenantId,
            startDate: filterDateFrom ? filterDateFrom.toISO() : undefined,
            endDate: filterDateTo ? filterDateTo.toISO() : undefined,
            orderNumber: filterOrderNumber,
            docNumber: filterDocumentNumber,
            docType: filterDocumentType,
            tradingPartnerName: filterPartnerName,
            hasException: filterHasException,
            erpOrderNumber: filterErpOrderNumber,
            transactionType: filterTransactionType,
            returnAllRecords: true
        } as GetV1CustomersByTenantIdTransactionsApiArg))
    };

    const onDownloadHtmlClick = (selectedRow?: TransactionResource) => {
        // save off the selected row to use for the base html download process  
        // triggered by setting clicked to true
        if (selectedRow) {
            setSelectedDataRow(selectedRow);
            setDownloadHtmlClicked(true);
        }
    };

    const onDownloadRawEdiClick = (selectedRow?: TransactionResource) => {
        // save off the selected row to use for the base raw edi download process  
        // triggered by setting clicked to true
        if (selectedRow) {
            setSelectedDataRow(selectedRow);
            setDownloadRawEdiClicked(true);
        }
    };

    const onResendDocumentClick = (selectedRow: any) => {
        // save off the selected row to use for opening the 
        // dialog for confirming the resend
        if (selectedRow) {
            setSelectedDataRow(selectedRow as TransactionResource);
            setOpenResendDialog(true);
        }
    };

    const resendDialogCloseHandler = () => {
        setOpenResendDialog(false);
        setSelectedDataRow(undefined);
    };

    const resendSuccessHandler = () => {
        // need to refetch to bring the resent update into the grid
        refetch();
    };

    const downloadCompletedHandler = () => {
        setDownloadHtmlClicked(false);
        setDownloadRawEdiClicked(false);
        setSelectedDataRow(undefined);
    };

    const gridCols = [
        {
            dataField: 'orderNumber',
            caption: translate('grids.orderNumber'),
            calculateCellValue: getRowOrderNumber,
            cellRender: (params: { value: any; data: TransactionResource }) => {
                const { value, data } = params;
                if (value && !selectedOrder && !orderNumberFilter) {
                    return (
                        <LinkRouter
                            to={`/transactions/order-${value}`}
                            onClick={() => {
                                dispatch(setOrderException(data.purchaseOrderId));
                            }}
                        >
                            {value}
                        </LinkRouter>
                    );
                } else {
                    return (value);
                }
            },
            minWidth: '125px',
        },
        {
            dataField: 'tradingPartnerName',
            caption: translate('grids.tradingPartner'),
            calculateCellValue: getRowTradingPartner,
            cellRender: (params: { value: any; data: TransactionResource }) => {
                return <LinkRouter to={`/trading-partners/tradingPartner-${params.data.tradingPartnerId}`}>{params.value}</LinkRouter>;
            },
            minWidth: '120px',
        },
        {
            dataField: 'documentType',
            caption: translate('grids.document'),
            calculateCellValue: getRowDocument,
            minWidth: '120px',
        },
        {
            dataField: 'documentNumber',
            caption: translate('grids.documentNumber'),
            minWidth: '130px',
        },
        {
            dataField: 'transactionType',
            caption: translate('grids.type'),
            minWidth: '65px',
        },
        {
            dataField: 'value',
            caption: translate('grids.value'),
            calculateCellValue: getRowValue,
            minWidth: '100px',
        },
        {
            dataField: 'date',
            caption: translate('grids.date'),
            calculateCellValue: getRowDate,
            dataType: 'string',
            alignment: 'left',
            minWidth: '130px',
        },
        {
            dataField: 'lines',
            caption: translate('grids.numberOfLines'),
            calculateCellValue: getRowNumberOfLines,
            minWidth: '80px',
        },
        {
            dataField: 'quantity',
            caption: translate('grids.totalQuantity'),
            calculateCellValue: getRowTotalQty,
            minWidth: '90px',
        },
        {
            dataField: 'exceptions',
            headerCellRender: () => {
                return (<WarningIcon aria-label={translate('grids.exceptions')}/>)
            },
            calculateCellValue: getRowHasExceptions,
            minWidth: '55px',
            cellRender: (params: { value: any; data: TransactionResource }) => {
                if (params.value) {
                    return (
                        <WarningIcon
                            aria-label={`${translate('grids.exceptionCheckOrder')} ${params.data.orderNumber}`}
                            className={classes.exceptionIcon}
                            onClick={() => onExceptionIconClick(params.data)}
                        />
                    ); // Little triangle alert icon
                }
                return <></>;
            },
        },
        {
            dataField: 'id',
            caption: '',
            visible: true,
            minWidth: '60px',
            alignment: 'right',
            allowFiltering: false,
            allowExporting: false,
            cellRender: (params: { value: any; data: any }) => {
                return (
                    <DownloadSplitButton
                        onDownloadHtmlClick={() => onDownloadHtmlClick(params.data)}
                        onDownloadRawEdiClick={() => onDownloadRawEdiClick(params.data)}
                    />
                )
            },
        },
        {
            dataField: 'purchaseOrderId',
            caption: '',
            visible: isUserTenantAdmin,
            minWidth: '60px',
            allowFiltering: false,
            allowExporting: false,
            cellRender: (params) => {
                let resendCount = params.data.resendCount;
                let resendDate = getFormattedDateTimeString(params.data.lastResendDate, { format: DateTime.DATETIME_SHORT });
                return (
                    <IconButton
                        className={resendCount > 0 ? classes.resendAgainButton : classes.resendButton}
                        title={resendCount > 0 ? `${translate('grids.lastResent')}: ${resendDate}` : `${translate('grids.resend')}`}
                        onClick={() => {
                            onResendDocumentClick(params.data);
                        }}
                    ><ResendIcon/></IconButton>
                );
            }
        },
    ] as IColumnProps[];

    const isDataProcessing = isLoading || isFetching;
    const isBusy = isFetchingDocument || isDataProcessing || isExporting;

    /* 
        Override of grid function to ContentReadyEvent
        of the grid to be able to call our own spinner
        to display during load 
    */
    const onContentReady = (e: ContentReadyEvent) => {
        // get the DataGrid instance
        let dataGrid = e.component as dxDataGrid;
        
        // when data is being loaded or fetched, 
        // begin custom loading to display spinner 
        // to indicate grid is loading 
        if ((isDataProcessing)) {
            if (!isCustomLoading)  {
                dataGrid.beginCustomLoading('');
                setIsCustomLoading(true); 
                setIsCustomLoadEndDelayed(false);
            }
        } else if (isCustomLoading && !isCustomLoadEndDelayed) {
            // when the rows are actually loaded and we are still in custom loading,
            // then call to end loading,
            // which hides the spinner (after a little delay because
            // the grid load process needs to finish after getting data)
            
            // need this flag to set to false to prevent coming in here multiple
            // times since onContentReady is triggered multiple times by the 
            // grid and the setTimeout keeps the isCustomLoading to true during
            // those multiple hits
            setIsCustomLoadEndDelayed(true);
            setTimeout(() => { 
                dataGrid.endCustomLoading();
                setIsCustomLoading(false); 
                // when paging, if user has scrolled to bottom, move scroll back to top
                var scrollable = dataGrid.getScrollable();  
                //scroll to top  
                scrollable.scrollTo(0);
            }, 2000);
        }
    };

    const onExportContentReady = async (e: ContentReadyEvent) => {
        // get the DataGrid instance
        let dataGrid = e.component as dxDataGrid;
        
        // export can't be called until the data is
        // loaded into the grid and this method is 
        // entered more than once, so set exportClicked
        // back to false so that export only happens once
        if (transactionsForExport && exportClicked) {
            setExportClicked(false);
            // await the export and set isExporting to false afterwards
            // so that the spinner is still displayed while exporting
            let exported = await exportDataGridToCSV('Transactions Report', 'transactions-report.csv', dataGrid);
            if (exported) {
                setIsExporting(false);
            }
        }
    };
    
    const getGridHeight = () => {
        let contentHeight = document.getElementById('transactions-content-area')?.clientHeight || 0;
        let titleHeight = document.getElementById('transactions-title-container')?.clientHeight || 0;
        const paginationComponentHeight = 56; // roughly the pixel height of the paging footer that can't tap into from here

        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('transactions-filter-bar')?.clientHeight || 0;
        }
        let remainingHeightArea = contentHeight - titleHeight - filterBarHeight - paginationComponentHeight;
        if (remainingHeightArea > 0) {
            return `${remainingHeightArea}px`;
        }
        return '100%';
    };

    const gridOptions: IDataGridOptions = {
        dataSource: transactionsInUse.items,
        columnAutoWidth: true,
        height: getGridHeight(),
        rowAlternationEnabled: true,
        sorting: {
            mode: 'none',
        },
        pager: {
            visible: false,
        },
        paging: {
            enabled: false,
        },
        noDataText: useDataGridNoDataText(isDataProcessing),
        onContentReady: onContentReady,
    };

    // need a second set of options specific for the 
    // hidden grid to be used without paging solely for export
    const gridOptionsForExport: IDataGridOptions = {
        dataSource: transactionsForExport,
        keyExpr: 'id',
        pager: {
            visible: false,
        },
        paging: {
            enabled: false,
        },
        onContentReady: onExportContentReady
    };

    let paginationInfo = {
        totalItems: transactionsInUse.total,
        currentPage: (transactionsOffset / pageSize),
        pageSize: pageSize,
        onPageChange: (event: any, page: number) => {
            setTransactionsOffset(page * pageSize);
            setPageChangeTriggered(true);
        },
        pageSizeOptions: isMobileScreen ? [10, 25, 50, 100, 200, 500] : [25, 50, 100, 200, 500],
        onPageSizeChange: (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            setPageSize(parseInt(event.target.value, 10));
            setTransactionsOffset(0);
        }
    };

    const getCardHeaderLink = (dataRow: any, column: IColumnProps) => {
        let value = column.calculateCellValue(dataRow);
        // only link if not already in a result-set filtered by order number
        if(value && !selectedOrder && !orderNumberFilter) {
            return (
                <LinkRouter
                    key={0}
                    fontWeight="medium"
                    to={`/transactions/order-${dataRow.orderNumber}`}
                    onClick={() => {
                        dispatch(setOrderException(dataRow.purchaseOrderId));
                    }}
                >
                    {value}
                    <LaunchIcon sx={{ marginLeft: 0.25, fontSize: '0.875rem' }} color="primary" fontSize="small" />
                    <Divider sx={{ marginBlock: 0.25 }} />
                </LinkRouter>
        );
        } else {
            return (
                <>
                    <Typography variant='body2'>{value}</Typography>
                    <Divider sx={{ marginBlock: 0.25 }} />
                </>
            );
        }
    };

    const getCards = () => {
        return (transactionsInUse?.items)?.map((row, i) => (
            <MobileDataCard key={i} hasExceptions={row.documentType === 'purchaseOrder' && (row.exceptions !== null && row.exceptions && Object.keys(row.exceptions).length > 0)}>
                {gridCols.map((column, colIndex) =>
                    colIndex === 0 ? (
                        getCardHeaderLink(row, column)
                    ) : (
                        <MobileDataCardRow
                            key={colIndex}
                            title={column.caption}
                            value={column.calculateCellValue ? column.calculateCellValue(row) : ''}
                            linkTo={
                                column.dataField === "exceptions" && row.documentType === 'purchaseOrder' && (row.exceptions !== null && row.exceptions && Object.keys(row.exceptions).length > 0)
                                    ? (
                                        <WarningIcon
                                            className={classes.exceptionIconMobile}
                                            onClick={() => onExceptionIconClick(row)}
                                        />
                                    ) : undefined
                            }
                        />
                    ),
                )}
            </MobileDataCard>
        ));
    };

    const getGridColumns = () => {
        return gridCols.map((col, index) => <Column key={index} {...col} />);
    };

    const getDisplayText = (): string => {
        // not displaying filters on mobile right now, so still display the values with the title
        if (isMobileScreen){
            if (displaySearchResults || !!orderNumberFilter) {
                return `${translate('transactions.titleOrder')} ${displayedOrder}`;
            }
            return `${translate('transactions.title')} (${displayPeriodTitle})`;
        }

        return `${translate('transactions.title')}`;
    };

    const refreshFilters = (
        filterDocType?: NormalizedDocumentType | undefined,
        filterPartner?: string | undefined,
        filterOrderNum?: string | undefined,
        filterDocNum?: string | undefined,
        filterTransDateFrom?: DateTime | undefined,
        filterTransDateTo?: DateTime | undefined,
        filterHasExceptions?: boolean | undefined,
        filterErpOrderNum?: string | undefined,
        filterTransType?: string | undefined,
        filterDocResent?: boolean | undefined,
    ) => {
        setFilterDocumentType(filterDocType);
        setFilterOrderNumber(filterOrderNum);
        setFilterDocumentNumber(filterDocNum);
        setFilterErpOrderNumber(filterErpOrderNum);
        setFilterPartnerName(filterPartner);
        setFilterDateFrom(filterTransDateFrom);
        setFilterDateTo(filterTransDateTo);
        setFilterHasException(filterHasExceptions);
        setFilterTransactionType(filterTransType);
        setFilterIsDocumentResent(filterDocResent);

        setFilterBarSearchTriggered(true);
    };

    const getFilterBar = () => {
        return (
            <TransactionsFilterBar
                id="transactions-filter-bar"
                visible={filtersOpen && !isMobileScreen}
                loading={isBusy}
                premadeSet={premadeFilter}
                onFilterChanged={refreshFilters}
                onClear={onFilterBarClearAllClick}
            />
        );
    }
    
    const getSummaryTemplate = () => {
        if (isMobileScreen) {
            return (
                <Grid>
                    {getFilterBar()}
                    <CardSummaryTemplate
                        isBusy={isBusy}
                        cards={getCards()}
                        pageTitleText={getDisplayText()}
                        gridPagination={paginationInfo} />
                </Grid>
            );
        }
        return (
            <Grid id='transactions-content-area' height={'100%'}>
                <Grid id='transactions-title-container' container mb={1} justifyContent="space-between" alignItems="center">
                    <PageTitle title={getDisplayText()} />
                    <Grid item container columnGap={2} width={'unset'}>
                        <FiltersButton
                            onClick={onFiltersButtonClick}
                            filterCount={filterCount}
                            filtersCleared={filtersCleared}
                            disabled={isBusy}
                            aria-label={`${translate("filters.filtersButton")}`}
                            data-cy="filters"
                        />
                        <Button startIcon={<ExportIcon />} variant="contained" onClick={onExportClick} disabled={isBusy} aria-label={`${translate("grids.export")}`}>
                            {translate('grids.export')}
                        </Button>
                    </Grid>
                </Grid>
                {getFilterBar()}
                <TableTemplate gridOptions={gridOptions} gridPagination={paginationInfo} useLessRowPadding>{getGridColumns()}</TableTemplate>
                <TableTemplate isVisible={false} ref={gridRef} gridOptions={gridOptionsForExport}>{getGridColumns()}</TableTemplate>
                {(isFetchingDocument || isExporting) &&
                    <CircularProgress
                        aria-label={`${translate("grids.progressSpinner")}`}
                        size={48}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                        }}
                    />
                }
            </Grid>
        );
    }

    return (
        <ListWithCommonActions
            tenantId={tenantId}
            summaryTemplate={getSummaryTemplate()}
            selectedRowData={selectedDataRow}
            openResendDialog={openResendDialog}
            downloadHtmlClicked={downloadHtmlClicked}
            downloadRawEdiClicked={downloadRawEdiClicked}
            toggleIsFetchingDocument={toggleIsFetchingDocument}
            onResendSuccess={resendSuccessHandler}
            onResendDialogClose={resendDialogCloseHandler}
            onDownloadComplete={downloadCompletedHandler}
        />
    );
};
