import { CircularProgress, Grid, styled } from '@mui/material';
import { IDataGridOptions } from 'devextreme-react/data-grid';
import { PageTitle } from '../../components/atoms/PageTitle';
import { TableTemplate } from '../../components/organisms/TableTemplate';
import DataCard, { DataCardProps } from '../molecules/DataCard';

const SummaryGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
}));

interface SummaryTemplateProps {
  isBusy?: boolean;
  //PageHeaderProps
  pageTitleText: string;
  //SubHeaderProps
  pageSubTitleText?: string;
  //DataCardProps
  dataCard?: DataCardProps;
  secondDataCard?: DataCardProps;
  //GridTemplateProps
  gridOptions: IDataGridOptions;
  gridColumns: React.ReactNode | React.ReactNode[];
  //SearchBarProps
  isMobile?: boolean;
  gridPagination?: {
    totalItems: number,
    currentPage: number;
    pageSize: number;
    onPageChange: any;
    pageSizeOptions?: number[];
    onPageSizeChange?: any;
  };
}

const SummaryTemplate: React.FC<SummaryTemplateProps> = props => {
  const {
    pageTitleText,
    pageSubTitleText,
    dataCard,
    secondDataCard,
    gridOptions,
    gridColumns,
    isMobile,
    gridPagination,
    isBusy=false
  } = props;

  const getCardSection = () => {
    if (secondDataCard && dataCard) {
      return (
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <DataCard {...dataCard} />
          </Grid>
          <Grid item>
            <DataCard {...secondDataCard} />
          </Grid>
        </Grid>
      );
    }
    else if (dataCard) {
      return <DataCard {...dataCard} />;
    }
    return undefined;
  };

  return (
    <SummaryGrid container spacing={2}>
      <Grid item xs={12}>
        <PageTitle title={pageTitleText} subTitle={pageSubTitleText} />
      </Grid>
      <Grid item xs={12}>
        {getCardSection()}
      </Grid>
      <Grid item xs={12}>
        {!isMobile && <TableTemplate gridOptions={gridOptions} gridPagination={gridPagination}>{gridColumns}</TableTemplate>}
      </Grid>
      {(isBusy) &&
        <CircularProgress
            aria-label={'progress spinner'}
            size={48}
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
            }}
        />
      }   
    </SummaryGrid>
  );
};

export default SummaryTemplate;