import { getBaseTheme } from '../../Theme';
import themes from "devextreme/ui/themes";
import { refreshTheme } from "devextreme/viz/themes";

import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createContext, useEffect, useMemo, useState } from 'react';

interface StylingProviderProps {
    children?: React.ReactNode;
}

export const ThemeModeContext = createContext({ toggleColorMode: () => {} });

const StylingProvider: React.FC<StylingProviderProps> = (props) => {
    // current state of the darkmode (defaults to light)
    const [mode, setMode] = useState<'light'| 'dark'>('light');
    // our implementation of the context that will toggle the mode state
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );
    // our theme, usuing the theme rules, but also passing in mode for darkmode
    const brightTheme = useMemo(() => createTheme(getBaseTheme(mode)),[mode]);

    useEffect(() => {
        // if mode changes, set the devExtreme theme accordingly
        themes.current(mode === "dark" ? "material.blue.dark" : "material.blue.light");
        // this is needed for SVG devExtreme objects like the graphs
        refreshTheme();
    },[mode])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeModeContext.Provider value={colorMode}>
                <ThemeProvider theme={brightTheme}>
                    {props.children}
                </ThemeProvider>
            </ThemeModeContext.Provider>
        </StyledEngineProvider>
    );
};

export default StylingProvider;