import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useDataGridNoDataText } from '../../utils/hooks/useDataGridNoDataText';
import { Button, Divider, Grid, Stack, Theme, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import dxDataGrid, { ContentReadyEvent } from 'devextreme/ui/data_grid';
import { DateTime } from 'luxon';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  TransactionSummaryResource,
  TransactionResource,
  useGetV1CustomersByTenantIdTransactionsQuery,
  useGetV1CustomersByTenantIdTransactionSummaryQuery
} from '../../api/customers.api';
import { GetV1PurchaseOrdersByTenantIdResendDocumentApiArg } from '../../api/purchaseOrders.api';
import { getSummaryTotalDisplayValue, getDataGridRowCurrencyDisplayValue } from '../../utils/helpers/currencyFormatUtil';
import { getFormattedDateTimeString } from '../../utils/helpers/dateTimeUtil';
import { getDocumentTypeName } from '../../utils/helpers/functions';
import { LinkRouter } from '../../components/atoms/LinkRouter';
import { MobileDataCardRow } from '../../components/atoms/MobileDataCardRow';
import { MobileDataCard } from '../../components/molecules/MobileDataCard';
import CardSummaryTemplate from '../../components/organisms/CardSummaryTemplate';
import SummaryTemplate from '../../components/organisms/SummaryTemplate';
import ResendDocumentDialog from '../../components/organisms/ResendDocumentDialog';
import { selectTenantId, selectDateFilterInterval, selectDateFilterTitle, selectCustomerName, setToastConfig, selectIsUserCustomerTenantAdmin } from '../app/AppSlice';
import { clearResend, setOrderException, selectResendFileStatus, selectResendError } from '../purchaseOrders/PurchaseOrdersSlice';
import { resendDocument } from '../purchaseOrders/PurchaseOrdersAPI';
import { getNumPo, getTotalPo, getPoCurrency } from './TradingPartnerAPI';
import { selectTradingPartnerId, setTradingPartnerId, setTradingPartnerName, selectTradingPartnerName, selectTradingPartnerTransactions, selectTradingPartnerTransactionCount, setTransactions, setTransactionPaginationCount } from './TradingPartnerSlice';
import { useTranslation } from 'react-i18next';
import { defaultCurrencyCode, ToastSeverity } from '../../utils/Constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resendButton: {
      color: theme.palette.primary.main,
      height:'16px', 
      padding:'0',
      '&:hover': {
        color: theme.palette.primary.dark,
        fontWeight: 'bold'
      }
    },
    // this class will be used once we have a way to tell if a document was already previously resent
    resendAgainButton: {
      color: theme.palette.warning.main,
      height:'16px', 
      padding:'0',
      '&:hover': {
        color: theme.palette.warning.dark,
        fontWeight: 'bold'
      }
    }
  }),
);

interface TradingPartnerProps { }

const TradingPartner: React.FC<TradingPartnerProps> = props => {
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const { i18n, t: translate } = useTranslation();
  const classes = useStyles();
  const params = useParams();
  const partnerId = params?.tradingPartnerId;

  const dateFilterTitle = useAppSelector(selectDateFilterTitle);
  const dateFilterInterval = useAppSelector(selectDateFilterInterval);
  const tenantId = useAppSelector(selectTenantId);
  const customerName = useAppSelector(selectCustomerName);
  const isUserTenantAdmin = useAppSelector(selectIsUserCustomerTenantAdmin);
  const tradingPartnerId = useAppSelector(selectTradingPartnerId);
  const tradingPartnerName = useAppSelector(selectTradingPartnerName);
  const tradingPartnerTransactions = useAppSelector(selectTradingPartnerTransactions);
  const tradingPartnerPaginationTotal = useAppSelector(selectTradingPartnerTransactionCount);
  const resendDocStatus = useAppSelector(selectResendFileStatus);
  const resendDocError = useAppSelector(selectResendError);

  const [isCustomLoading, setIsCustomLoading] = useState(false);
  const [header, setHeader] = useState<string>(`${tradingPartnerName} ${translate('tradingPartners.title')} ${dateFilterTitle}`);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [numPo, setNumPo] = useState<string>('0');
  const [totalPo, setTotalPo] = useState<number>(0);
  const [poCurrency, setPoCurrency] = useState<string>(defaultCurrencyCode);
  const [partner, setPartner] = useState<TransactionSummaryResource | undefined>();
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(isMobileScreen ? 5 : 25);
  const [transactionsTotalCount, setTransactionsTotalCount] = useState(0);
  const [selectedDataRow, setSelectedDataRow] = useState<TransactionResource | undefined>(undefined); 
  const [openResendDialog, setOpenResendDialog] = useState(false);  
  
  const { data: transactions, isLoading, isFetching } = useGetV1CustomersByTenantIdTransactionsQuery(
    {
      tenantId: tenantId as string,
      startDate: dateFilterInterval.start.toISO(),
      endDate: dateFilterInterval.end.toISO(),
      tradingPartnerId: tradingPartnerId,
      limit: pageSize,
      offset: offset,
    },
    { skip: !tenantId || !tradingPartnerId },
  );
  const { data: tradingPartners } = useGetV1CustomersByTenantIdTransactionSummaryQuery(
    {
      tenantId: tenantId as string,
      startDate: dateFilterInterval.start.toISO(),
      endDate: dateFilterInterval.end.toISO(),
    },
    {
      skip: !tenantId,
    },
  );

  useEffect(() => {
    if (partnerId) {
      dispatch(setTradingPartnerId(partnerId));
      if (tradingPartners) {
        const partner = tradingPartners.find(partner => {
          return partner.tradingPartnerId === partnerId;
        });
        setPartner(partner);
      }
    }
  }, [dispatch, partnerId, tradingPartners]);

  useEffect(() => {
    if (partner) {
      setNumPo(getNumPo(partner.orderSummary));
      setTotalPo(getTotalPo(partner.orderSummary));
      setPoCurrency(getPoCurrency(partner.orderSummary) as string);
    }
    else {
      setNumPo('0');
      setTotalPo(0);
      setPoCurrency(defaultCurrencyCode);
    }
  }, [partner]);

  useEffect(() => {
    setHeader(tradingPartnerName + ` ${translate('tradingPartners.title')} (${dateFilterTitle})`);
  }, [dispatch, tradingPartnerName, dateFilterInterval, i18n.language]);

  useEffect(() => {
    if (!!tradingPartnerTransactions && transactions && transactions.items) {
      dispatch(setTransactions(transactions.items));
      dispatch(setTransactionPaginationCount(transactions.totalCount || 0));
    }
  }, [transactions]);

  useEffect(() => {
    if (partner && partner.tradingPartnerName) {
      dispatch(setTradingPartnerName(partner.tradingPartnerName));
    }
  }, [partner, dispatch]);

  const cardContent = {
    header: dateFilterTitle,
    label1: translate('purchaseOrders.title') as string,
    value1: numPo,
    label2: translate('cardSummary.totalValue') as string,
    value2: getSummaryTotalDisplayValue(totalPo, poCurrency),
  };
   
  const getRowOrderNumber = (rowData: { orderNumber: any }) => rowData.orderNumber;
  const getRowDocumentNumber = (rowData: { documentNumber: any }) => rowData.documentNumber;
  const getRowNumberOfLines = (rowData: { lines: any }) => rowData.lines;
  const getRowTotalQty = (rowData: { quantity: any }) => rowData.quantity;
  const getRowDocument = (rowData: { documentType: any }) => getDocumentTypeName(rowData.documentType);
  const getRowDate = (rowData: { date: any }) => getFormattedDateTimeString(rowData.date, { format: DateTime.DATE_SHORT });
  const getRowValue = (rowData: TransactionResource) => {
    // format as currency using specific row currency if have it
    const { value, currency } = rowData;
    let displayValue = getDataGridRowCurrencyDisplayValue(value, currency);
    return displayValue;
  };
    
  const onResendDocumentClick = (selectedRow: any) => {
    // save off the selected row to use for opening the 
    // dialog for confirming the resend
    if (selectedRow) {
      setSelectedDataRow(selectedRow as TransactionResource);
      setOpenResendDialog(true);
    }
  };

  const resendDialogCloseHandler = () => {
    setOpenResendDialog(false);
    setSelectedDataRow(undefined);
    //setResendErrorMessage('');
  };

  const onResendDialogConfirm = () => {
    // resend the selected document
    if (selectedDataRow) {
      
      dispatch(resendDocument({
        tenantId: tenantId,
        transactionId: selectedDataRow.id,
        persistenceId: selectedDataRow.persistenceId
      } as GetV1PurchaseOrdersByTenantIdResendDocumentApiArg))
    }
  };

  const resetResendDocSequence = () => {
    dispatch(clearResend());
    resendDialogCloseHandler();
  }

  useEffect(() => {
    if (resendDocStatus) {
      if (resendDocStatus.wasSuccessful === true) {
        // confirmation that sent was successful 
          // canned response due to response only containing errors,
          // but we can translate client-side strings
        dispatch(setToastConfig({
          message: translate("resendDocumentDialog.resendSuccess"),
          severity: ToastSeverity.Success
        }));
      } else {
        dispatch(setToastConfig({
          message: resendDocStatus.message,
          severity: ToastSeverity.Error
        }));
      } 
      resetResendDocSequence();
    } else if(resendDocError) {
      dispatch(setToastConfig({
        message: resendDocError.message,
        severity: ToastSeverity.Error
      }));
      resetResendDocSequence();
    }
  },[resendDocStatus, resendDocError])

  const gridCols = [
    {
      dataField: 'orderNumber',
      calculateCellValue: getRowOrderNumber,
      caption: translate('grids.orderNumber'),
      cellRender: (params) => {
        const {value, data} = params;
        if (data.orderNumber?.length) {
          return (
            <LinkRouter
              to={`/trading-partners/tradingPartner-${partnerId}/order-${data.orderNumber}`}
              onClick={() => {
                dispatch(setOrderException(data.purchaseOrderId));
              }}
            >
              {value}
            </LinkRouter>
          );
        } else {
          return (value);
        }
      },
      minWidth: '125px',
    },
    {
      dataField: 'documentType',
      calculateCellValue: getRowDocument,
      caption: translate('grids.document'),
      minWidth: '200px',
      allowFiltering: false,
    },
    {
      dataField: 'documentNumber',
      calculateCellValue: getRowDocumentNumber,
      caption: translate('grids.documentNumber'),
      minWidth: '125px',
      allowFiltering: false,
    },
    {
      dataField: 'value',
      calculateCellValue: getRowValue,
      caption: translate('grids.value'),
      minWidth: '100px',
      allowFiltering: false,
    },
    {
      dataField: 'date',
      calculateCellValue: getRowDate,
      caption: translate('grids.date'),
      dataType: 'date',
      alignment: 'left',
      minWidth: '100px',
      sortOrder: 'asc',
      allowFiltering: false,
    },
    {
      dataField: 'lines',
      calculateCellValue: getRowNumberOfLines,
      caption: translate('grids.numberOfLines'),
      minWidth: '100px',
      allowFiltering: false,
    },
    {
      dataField: 'quantity',
      calculateCellValue: getRowTotalQty,
      caption: translate('grids.totalQuantity'),
      minWidth: '100px',
      allowFiltering: false,
    },
    
    {
      dataField: 'purchaseOrderId',
      caption: '',
      visible: isUserTenantAdmin,
      minWidth: '80px',
      allowFiltering: false,
      cellRender: (params) => {
        let docType = params.data.documentType;
        if (docType === "invoice" || docType === "shipment") {
          let resendCount = params.data.resendCount;
          let resendDate = getFormattedDateTimeString(params.data.lastResendDate, { format: DateTime.DATETIME_SHORT });
          return (
            <Button 
              variant="text"
              className={resendCount > 0 ? classes.resendAgainButton : classes.resendButton}
              title={resendCount > 0 ? `${translate('grids.lastResent')}: ${resendDate}` : ''}
              onClick={() => {
                onResendDocumentClick(params.data);
              }}
            >{translate('grids.resend')}</Button>
          );
        }
        else {
          return (
            ''
          );
        }
      },
    },
  ] as IColumnProps[];

  /* 
    Override of grid function to ContentReadyEvent
    of the grid to be able to call our own spinner
    to display during load 
  */
  const onContentReady = (e: ContentReadyEvent) => {
    // get the DataGrid instance
    let dataGrid = e.component as dxDataGrid;
    
    // when data is being loaded or fetched, 
    // begin custom loading to display spinner 
    // to indicate grid is loading 
    if ((isLoading || isFetching)) {
        if (!isCustomLoading)  {
            dataGrid.beginCustomLoading('');
            setIsCustomLoading(true); 
        }
    } else if (isCustomLoading) {
        // when the rows are actually loaded and we are still in custom loading,
        // then call to end loading, which hides the spinner 
        dataGrid.endCustomLoading();
        setIsCustomLoading(false); 
        // when paging, if user has scrolled to bottom, move scroll back to top
        var scrollable = dataGrid.getScrollable();  
        //scroll to top  
        scrollable.scrollTo(0);
    }
  };

  const gridOptions: IDataGridOptions = {
    dataSource: tradingPartnerTransactions,
    columnAutoWidth: true,
    height: '55vh',
    rowAlternationEnabled: true,
    filterRow: {
      visible: true,
    },
    sorting: {
      mode: 'none',
    },
    pager: {
      visible: false,
    },
    paging: {
      enabled: false,
    },
    noDataText: useDataGridNoDataText(isLoading || isFetching),
    onContentReady: onContentReady
  };

  let paginationInfo = {
    totalItems: tradingPartnerPaginationTotal,
    currentPage: (offset / pageSize),
    pageSize: pageSize,
    pageSizeOptions: isMobileScreen ? [5, 10, 25, 50, 100, 200, 500] : [25, 50, 100, 200, 500],
    onPageChange: (event: any, page: number) => {
      setOffset(page * pageSize);
    },
    onPageSizeChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setPageSize(parseInt(event.target.value, 10));
      setOffset(0);
    }
  }

  const getOrderNumberLink = (dataRow: any, column: IColumnProps) => {
    let value = column.calculateCellValue(dataRow);
    
    return (
      <LinkRouter
        key={0}
        fontWeight="medium"
        to={`/trading-partners/tradingPartner-${partnerId}/order-${dataRow.orderNumber}`}
        onClick={() => {
          dispatch(setOrderException(dataRow.purchaseOrderId));
        }}
      >
        {value}
        <LaunchIcon sx={{ marginLeft: 0.25, fontSize: '0.875rem' }} color="primary" fontSize="small" />
      </LinkRouter>
    );
  };

  const getMobileCardHeader = (dataRow: any, column: IColumnProps, resendButtonColumnIndex: number) => {
    const isResendVisible = isUserTenantAdmin && (dataRow.documentType === "invoice" || dataRow.documentType === "shipment");
    let resendCount = dataRow.resendCount as number;
    let resendDate = getFormattedDateTimeString(dataRow.lastResendDate as string, { format: DateTime.DATETIME_SHORT });
        
    // joining first and last column indexes to be the header of the card
    const combinedKey = `0-${resendButtonColumnIndex}`;
    return (
      <Stack key={combinedKey}>
        <Grid container item xs={12} flexDirection={"row"} alignContent={"space-between"} alignItems={"flex-end"}>
          <Grid item xs={10}>
            {getOrderNumberLink(dataRow, column)}
          </Grid>
          <Grid item xs={2}>
            { isResendVisible &&
                <Button 
                  key={resendButtonColumnIndex}
                  variant="text"
                  className={resendCount > 0 ? classes.resendAgainButton : classes.resendButton}
                  title={resendCount > 0 ? `${translate('grids.lastResent')}: ${resendDate}` : ''}
                  onClick={() => {
                    onResendDocumentClick(dataRow);
                  }}
                >{translate('grids.resend')}</Button>
            }
          </Grid>
        </Grid>
        <Divider sx={{ marginBlock: 0.25 }} />
      </Stack>
      
    )
  };

  const getCards = () => {
    // purchaseOrderId is used as the dataField for the resend button column, so use that to find the index
    // of the resend column since it is going on same line as the first column, order number
    const resendButtonColumnIndex = gridCols.findIndex(c => c.dataField === "purchaseOrderId"); 
    return tradingPartnerTransactions.map((row, i) => (
      <MobileDataCard key={i}>
        {gridCols.map((column, colIndex) => {
          return (
            colIndex === 0 ? (
              getMobileCardHeader(row, column, resendButtonColumnIndex)
            ) : (
              colIndex !== resendButtonColumnIndex ? (
                <MobileDataCardRow
                  key={colIndex}
                  title={column.caption}
                  value={column.calculateCellValue ? column.calculateCellValue(row) : ''}
                />
              ): (<div key={colIndex} style={{display: 'none'}}/>)
            )
          )
        })}
      </MobileDataCard>
    ));
  };

  const getGridColumns = () => {
    return gridCols.map((col, index) => <Column key={index} {...col} />);
  };

  const getSummaryTemplate = () => {
    if (isMobileScreen) {
      return <CardSummaryTemplate 
              pageTitleText={header} 
              cards={getCards()} 
              gridPagination={paginationInfo} 
              isBusy={isLoading || isFetching}
            />;
    } else {
      return <SummaryTemplate 
              pageTitleText={header} 
              dataCard={cardContent} 
              gridOptions={gridOptions} 
              gridColumns={getGridColumns()} 
              gridPagination={paginationInfo} 
            />;
    }
  };

  const getResendDialogMessage = () => {
    let message = translate('grids.resendConfirmation');
    let resendCount = selectedDataRow?.resendCount ?? 0;
    if (resendCount > 0) {
      let resendDate = getFormattedDateTimeString(selectedDataRow?.lastResendDate as string, { format: DateTime.DATETIME_SHORT });
      message =  `${message}\n${translate('grids.lastResent')}: ${resendDate}`;
    }
    return message;
  }

  return (
    <>
        {getSummaryTemplate()}
        <ResendDocumentDialog
          isOpen={openResendDialog}
          id={selectedDataRow?.purchaseOrderId!}
          heading={`${translate('grids.resend')} ${getDocumentTypeName(selectedDataRow?.documentType)}`}
          message={getResendDialogMessage()}
          resendCount={selectedDataRow?.resendCount as number}
          onConfirm={onResendDialogConfirm}
          onReject={resendDialogCloseHandler}
          errorMessage={resendDocError?.message}
      />
    </>
  );
};

export default TradingPartner;
