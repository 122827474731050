import { TransactionTotalResource, useGetV1DashboardTransactionTotalQuery, NormalizedDocumentType } from "../api/dashboard.api";
import { defaultCurrencyCode } from "./Constants";

export const useGetSummaryData = (tenantId: string, startDate: string, endDate: string, docType: string, purchaseOrderNumber?: string) => {
    const { data: transactionsData } = useGetV1DashboardTransactionTotalQuery(
        {
        tenantId: tenantId,
        startDate: startDate,
        endDate: endDate,
        docType: docType as NormalizedDocumentType,
        purchaseOrderNumber: purchaseOrderNumber
        },
        {
        skip: !tenantId,
        },
    );

    let summaryData = {count: 0, total: 0, currency: defaultCurrencyCode};
    if (transactionsData) {
        let orderSum = transactionsData.find((item: TransactionTotalResource) => {
        return item.documentType === docType;
        });
        summaryData.count = (orderSum?.count || 0);
        summaryData.total = (!orderSum || !orderSum.amounts || !orderSum.amounts[0] || !orderSum.amounts[0].amount) ? 0 : orderSum.amounts[0].amount;
        summaryData.currency = (!orderSum || !orderSum.amounts || !orderSum.amounts[0] || !orderSum.amounts[0].currency) ? defaultCurrencyCode : orderSum.amounts[0].currency;
    }
    return summaryData;
}