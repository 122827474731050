import SettingsMenu from './SettingsMenu';
import { clearApteanSSOStorage } from '../../utils/security';
import { useTranslation } from 'react-i18next';

interface SettingsMenuSSOProps {
  afterClick: () => void;
  viewerUser?: { firstName: string; lastName: string; email: string, id: string } | undefined; // TODO: LATER GET THIS VIA SELECTOR
}

const SettingsMenuApteanSSO: React.FC<SettingsMenuSSOProps> = props => {
  const { afterClick, viewerUser } = props;
  const { t: translate } = useTranslation();
  
  const handleLogout = () => {
    // clear storage items as part of the logout process
    clearApteanSSOStorage();
    window.location.href = window.location.origin + '/logout';
    afterClick();
  };

  return (
    <SettingsMenu 
      afterClick={afterClick}
      handleLogout={handleLogout} 
      viewerUser={viewerUser} 
      logoutButtonText={translate('settingsMenu.exit')}
    />
  );
};

export default SettingsMenuApteanSSO;
