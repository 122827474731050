import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime, Interval } from 'luxon';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePeriod } from '../../static/datePeriods';
import { generatePresetDatePeriods, getDatePeriodI18nKey } from '../../utils/helpers/functions';
import { getSessionLocale } from '../../utils/helpers/dateTimeUtil';

interface DateFilterProps {
  open: boolean;
  handleClose: () => void;
  dateFilterSelection: DatePeriod;
  handleDateFilterChange: (period: DatePeriod, interval: Interval | undefined) => void;
  customDateFilterInterval: Interval;
}

export const DateFilter: FC<DateFilterProps> = props => {
  const { open, handleClose, dateFilterSelection, handleDateFilterChange, customDateFilterInterval } = props;
  const theme = useTheme();
  const { t: translate } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const periods = generatePresetDatePeriods();

  const [selectedRadio, setSelectedRadio] = useState(dateFilterSelection !== DatePeriod.Custom ? 'Period' : 'Between');
  const [selectedPeriod, setSelectedPeriod] = useState<DatePeriod>(
    dateFilterSelection !== DatePeriod.Custom ? dateFilterSelection : DatePeriod.Quarter,
  );
  const [startDate, setStartDate] = useState<DateTime | null | undefined>(
    dateFilterSelection === DatePeriod.Custom ? customDateFilterInterval.start : null,
  );
  const [endDate, setEndDate] = useState<DateTime | null | undefined>(
    dateFilterSelection === DatePeriod.Custom ? customDateFilterInterval.end : null,
  );

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value);
  };
  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedPeriod(event.target.value as DatePeriod);
  };

  const handleApply = () => {
    if (selectedRadio === 'Period' && selectedPeriod) {
      handleDateFilterChange(selectedPeriod, undefined);
    }
    if (selectedRadio === 'Between' && startDate && endDate) {
      handleDateFilterChange(DatePeriod.Custom, Interval.fromDateTimes(startDate.startOf('day'), endDate.endOf('day')));
    }
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
    setSelectedRadio(dateFilterSelection !== DatePeriod.Custom ? 'Period' : 'Between');
    if (dateFilterSelection === DatePeriod.Custom) {
      setStartDate(customDateFilterInterval.start);
      setEndDate(customDateFilterInterval.end);
    } else {
      setSelectedPeriod(dateFilterSelection);
    }
  };

  const isApplyButtonDisabled = () => {
    if (selectedRadio === 'Between') {
      // do these checks first so that not dealing 
      // with potentially undefined in next validation portion
      if (!startDate || !startDate.isValid) return true;
      if (!endDate || !endDate.isValid) return true;

      // use start of day values so time isn't a factor in comparison
      let dateStart = startDate.startOf('day');
      let dateEnd = endDate.startOf('day');
      let dateNow = DateTime.now().startOf('day');
      
      if (dateStart > dateEnd || dateStart > dateNow || dateEnd > dateNow){
        return true;
      }
    } 
    return false;
  };

  let sessionLocale = getSessionLocale();

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth fullScreen={matches}>
      <DialogTitle>{translate("datePickerDialog.dateFilter")}</DialogTitle>
      <DialogContent>
        <FormControl sx={{ width: '100%' }}>
          <RadioGroup value={selectedRadio} onChange={handleRadioChange}>
            <FormControlLabel value="Period" control={<Radio />} label={translate("datePickerDialog.period")}/>
            <Select disabled={selectedRadio !== 'Period'} value={selectedPeriod} onChange={handleSelectChange} fullWidth>
              {periods.map(period => {
                const title = translate(getDatePeriodI18nKey(period));
                return (
                  <MenuItem value={period} key={period}>
                    <Typography>{title}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <FormControlLabel value="Between" control={<Radio />} label={translate("datePickerDialog.between")} sx={{ marginTop: 1 }} />
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={sessionLocale || 'en-us'}>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    disabled={selectedRadio === 'Period'}
                    value={startDate}
                    maxDate={endDate}
                    disableMaskedInput
                    disableFuture
                    onChange={value => {
                      setStartDate(value);
                    }}
                    renderInput={params => <TextField {...params} fullWidth label={translate("datePickerDialog.startDate")} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    disabled={selectedRadio === 'Period'}
                    value={endDate}
                    minDate={startDate}
                    disableMaskedInput
                    disableFuture
                    onChange={value => {
                      setEndDate(value);
                    }}
                    renderInput={params => <TextField {...params} fullWidth label={translate("datePickerDialog.endDate")} />}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>
          {translate("datePickerDialog.rejectButtonText")}
        </Button>
        <Button
          variant="contained"
          onClick={handleApply}
          disabled={isApplyButtonDisabled()}
        >
          {translate("datePickerDialog.confirmButtonText")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
