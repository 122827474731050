import { TopTradingPartnersChartData } from '../../components/molecules/TopTradingPartnersChart';
import {
  PeriodicActivityResource,
  TopTradingPartnerResource,
  NormalizedDocumentType,
  TransactionTotalResource,
} from '../../api/dashboard.api';
import { ActivityChartData } from '../../components/molecules/ActivityChart';
import { DateTime } from 'luxon';
import { stringDisplay, numberDisplay } from '../../utils/dataDisplayPipe';
import { defaultCurrencyCode } from '../../utils/Constants';

export const mapTopTradingPartners = (partners: TopTradingPartnerResource[] | undefined) => {
  if (!partners) return [];
  // Devextreme chart needs to take in the array of partners in reverse order in order to display the most active partner at the top.
  // To account for possible duplicate partner names, we are concatenating an ordered number before each partner name.
  const sortedPartners: TopTradingPartnersChartData[] = [...partners].reverse().map((p: TopTradingPartnerResource, count) => ({
    partner: `${partners.length - count}. ${stringDisplay(p.tradingPartnerName, 'trading partner name')}`,
    po: numberDisplay(p.purchaseOrderSum, 'purchase orders'),
    invoice: numberDisplay(p.invoiceSum, 'invoices'),
    id: stringDisplay(p.tradingPartnerId, 'trading partner id'),
  }));
  return sortedPartners;
};

export const getDocTypeCount = (data: TransactionTotalResource[] | undefined, docType: NormalizedDocumentType) => {
  if (!data) return 0;
  const doc = data.find(doc => {
    return doc.documentType === docType;
  });
  return numberDisplay(doc?.count, `${docType} count`);
};

export const getDocTypeAmount = (data: TransactionTotalResource[] | undefined, docType: NormalizedDocumentType) => {
  if (!data) return 0;
  const doc = data.find(doc => {
    return doc.documentType === docType;
  });
  if (!doc || !doc.amounts || !doc.amounts[0]) return 0;
  return numberDisplay(doc.amounts[0].amount, `${docType} amount`);
};

export const getDocTypeCurrency = (data: TransactionTotalResource[] | undefined, docType: NormalizedDocumentType) => {
  if (!data) return defaultCurrencyCode;
  const doc = data.find(doc => {
    return doc.documentType === docType;
  });
  if (!doc || !doc.amounts || !doc.amounts[0]) return defaultCurrencyCode;
  return doc.amounts[0].currency ?? defaultCurrencyCode;
};

export const mapActivityData = (data: PeriodicActivityResource[] | undefined) => {
  if (!data) return [];
  const mappedActivity: ActivityChartData[] = [];
  data.forEach(activity => {
    if (activity.startDate && activity.transactionTotals) {
      mappedActivity.push({
        date: DateTime.fromISO(activity.startDate).toLocaleString(),
        orders: getDocTypeCount(activity.transactionTotals, 'purchaseOrder'),
        invoices: getDocTypeCount(activity.transactionTotals, 'invoice'),
        shipments: getDocTypeCount(activity.transactionTotals, 'shipment'),
      });
    }
  });
  return mappedActivity;
};
