import { FC, useEffect, useState } from 'react';
import { Dialog } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { ConfirmationPrompt } from "../molecules/ConfirmationPrompt";

interface DeleteDialogProps {
    isOpen: boolean;
    id: string;
    heading: string;
    message: string;
    onConfirm: (id: string) => void;
    onReject: () => void;
    errorMessage?: string | undefined;
}

const DeleteDialog: FC<DeleteDialogProps> = props => {
    const { t: translate } = useTranslation();
    const { isOpen, id, heading = translate('deleteDialog.heading'), message, onConfirm, onReject, errorMessage } = props;
    const [isDeleteBusy, setIsDeleteBusy] = useState(false);

    useEffect(() => {
        if (errorMessage) {
            setIsDeleteBusy(false);
            return;
        }
    }, [errorMessage]);

    useEffect(() => {
        // set submitted to false when dialog is closed
        if (!isOpen) {
            setIsDeleteBusy(false);
        }
    }, [isOpen]);

    const handleDeleteConfirm = () => {
        setIsDeleteBusy(true);
        onConfirm(id);
    }
    const handleDeleteReject = () => {
        setIsDeleteBusy(false);
        onReject();
    }

    return (
        <Dialog
            open={isOpen}>
            <ConfirmationPrompt
                heading={heading}
                message={message}
                confirmButtonText={translate('deleteDialog.confirmButtonText')}
                rejectButtonText={translate('deleteDialog.rejectButtonText')}
                confirmButtonColor={'error'}
                rejectButtonColor={'primary'}
                handleConfirm={handleDeleteConfirm}
                handleReject={handleDeleteReject}
                isBusy={isDeleteBusy}
                errorMessage={errorMessage}
            />
        </Dialog>
    );
};

export default DeleteDialog;