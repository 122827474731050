import './index.css';
import './utils/i18n';

import { CssBaseline } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setupStore } from './app/store';
import { RouteDefinitions, RouteDefinitionsLegacy } from './routes';
import StylingProvider from './features/stylingProvider/StylingProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  APTEAN_IAM_AUTHORITY,
  APTEAN_IAM_CLIENT_ID,
  APTEAN_IAM_REALM,
  AuthProviderAuth0,
  StorageKeyAuthProvider,
  AuthProviderOIDC,
  IP_IAM_INDICATOR,
  StorageKeyTenantId,
  QUERY_PARAM_IP,
  QUERY_PARAM_TENANT_ID,
  StorageKeyAppEntryTime,
  StorageKeyFrontChannelLogoutRequestedTime,
} from './utils/Constants';
import { DateTime } from 'luxon';
import PostLogout from './components/organisms/PostLogout';
import FrontChannelLogout from './components/organisms/FrontChannelLogout';
import { clearSSOSessionStorage } from './utils/security';
import { AuthProvider } from 'react-oidc-context';

console.log('entering app index.tsx');

let sessionEntryTime = sessionStorage.getItem(StorageKeyAppEntryTime);
if (!sessionEntryTime && window.location.pathname !== '/logout' && window.location.pathname !== '/frontchannellogout') {
  let entryTime = DateTime.now().toUTC().toISO();
  sessionStorage.setItem(StorageKeyAppEntryTime, entryTime);
}

// Check if flag has been set in local storage to logout
window.addEventListener('storage', (event: StorageEvent) => {
  if (event.key === StorageKeyFrontChannelLogoutRequestedTime && event.newValue && sessionStorage.getItem(StorageKeyAppEntryTime)) {
    // Do not logout if page was opened after logout request.
    let logoutDateTime = DateTime.fromISO(event.newValue);
    let entryDateTime = DateTime.fromISO(sessionStorage.getItem(StorageKeyAppEntryTime) as string);
    if (logoutDateTime > entryDateTime) {
      clearSSOSessionStorage();
      window.location.href = 'logout';
    }
  }
}, false);

const container = document.getElementById('root')!;
const root = createRoot(container);
const store = setupStore();

const queryParams = new URLSearchParams(window.location.search);
let ipIndicator = queryParams.get(QUERY_PARAM_IP);
let tenantId = queryParams.get(QUERY_PARAM_TENANT_ID);

if (tenantId) {
  console.log('saving tenantId in local storage: ' + tenantId);
  localStorage.setItem(StorageKeyTenantId, tenantId);
}

let isApteanIAM = false;
// based on query parameter, set the auth provider in session storage
// for use in the app or use existing value if already set
if (ipIndicator === IP_IAM_INDICATOR) {
  sessionStorage.setItem(StorageKeyAuthProvider, AuthProviderOIDC);
  isApteanIAM = true;
} else if (sessionStorage.getItem(StorageKeyAuthProvider) === AuthProviderOIDC) {
  ipIndicator = IP_IAM_INDICATOR;
  isApteanIAM = true;
} else {
  sessionStorage.setItem(StorageKeyAuthProvider, AuthProviderAuth0);
}

console.log('ipIndicator: ' + ipIndicator);
console.log(sessionStorage);

const oidcConfig = {
  authority: `${APTEAN_IAM_AUTHORITY}/realms/${APTEAN_IAM_REALM}`,
  client_id: APTEAN_IAM_CLIENT_ID,
  redirect_uri: window.location.origin,
  // Not sure this actually does anything. Need to manually redirect to /logout in logout event handler.
  post_logout_redirect_uri: window.location.origin + '/logout',
  /* According to oidc-react-context: 
   * You must provide an implementation of onSigninCallback to oidcConfig to remove the payload from the URL upon successful login. 
   * Otherwise if you refresh the page and the payload is still there, signinSilent - which handles renewing your token - won't work.
  */
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  // Automatically renew the token silently when it expires
  automaticSilentRenew: true,
};

const getAuthProvider = () => {
  if (isApteanIAM) {
    return (
      <AuthProvider {...oidcConfig}>
        <StylingProvider>
          <CssBaseline />
          <BrowserRouter>
            <Provider store={store}>
              <RouteDefinitions />
            </Provider>
          </BrowserRouter>
        </StylingProvider>
      </AuthProvider>
    );
  } else {
    return (
      <Auth0Provider
        domain={`${process.env.REACT_APP_AUTH_DOMAIN}`}
        clientId={`${process.env.REACT_APP_AUTH_CLIENTID}`}
        audience={`${process.env.REACT_APP_EDI_PORTAL_API_ENDPOINT}/`}
        redirectUri={window.location.origin}
      >
        <StylingProvider>
          <CssBaseline />
          <BrowserRouter>
            <Provider store={store}>
              <RouteDefinitionsLegacy />
            </Provider>
          </BrowserRouter>
        </StylingProvider>
      </Auth0Provider>
    );
  }
};

const getRender = () => {
  const pathName = window.location.pathname;
  if (pathName === '/logout') {
    return <PostLogout />;
  }
  if (pathName === '/frontchannellogout') {
    return <FrontChannelLogout />;
  }
  return getAuthProvider();
};

root.render(<>{getRender()}</>);
