import { Link as RouterLink } from 'react-router-dom';
import { Link, LinkProps, useTheme } from '@mui/material';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}
// color="#276EF1"
export const LinkRouter: React.FC<LinkRouterProps> = props => (
  <Link color={useTheme().palette.primary.main} underline="hover" fontSize="0.875rem" component={RouterLink} {...props} />
);
