import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionResource } from '../../api/customers.api';
import { ExceptionResource } from '../../api/exceptions.api';
import { RootState } from "../../app/store";

export interface TransactionDocumentsState {
    transactions: TransactionResource[];
    transactionsPaginationCount: number;
    todaysTransactions: TransactionResource[];
    filteredTransactions: TransactionResource[];
    exceptions: ExceptionResource[];
    status: 'idle' | 'loading' | 'failed';
    transaction?: TransactionResource;
    error?: Error;
    exception?: ExceptionResource;
    transactionId?: string;
}

const initialState: TransactionDocumentsState = {
    transactions: [],
    transactionsPaginationCount: 0,
    todaysTransactions: [],
    filteredTransactions: [],
    exceptions: [],
    status: 'idle',
    transaction: undefined,
    error: undefined,
    exception: undefined,
    transactionId: undefined,
};

export const slice = createSlice({
    name: 'documentTypeTransactions',
    initialState,
    reducers: {
        clearTransaction: state => {
            if (state.transaction) {
                // eslint-disable-next-line no-param-reassign
                state.transaction = undefined;
            }
            if (state.transactionId) {
                // eslint-disable-next-line no-param-reassign
                state.transactionId = undefined;
            }
        },
        clearException: state => {
          if (state.exception) {
            // eslint-disable-next-line no-param-reassign
            state.exception = undefined;
          }
        },
        setTransactions: (state, action: PayloadAction<TransactionResource[]>) => {
          state.transactions = action.payload ?? [];
        },
        setTransactionsPaginationCount: (state, action: PayloadAction<number>) => {
          state.transactionsPaginationCount = action.payload;
        },
        setTransactionsExceptions: (state, action: PayloadAction<ExceptionResource[]>) => {
          state.exceptions = action.payload;
        },
        setTransactionId: (state, action: PayloadAction<string | undefined>) => {
          state.transactionId = action.payload;
        },
        setTransaction: (state, action: PayloadAction<TransactionResource>) => {
          state.transaction = action.payload;
        },
        setTransactionException: (state, action: PayloadAction<string | undefined>) => {
          if (action.payload) {
              if (state.exceptions.length > 0) {
                var exception = state.exceptions.find((exc: ExceptionResource) => {
                  return exc.documentNumber === action.payload;
                });
                if (exception) {
                  state.exception = exception;
                }
              }
            }
        },
        filterTransactions: (state, action: PayloadAction<{ value: string | undefined, period: 'today' | 'quarterly' }>) => {
            const { value, period } = action.payload;
            if (!value) {
              state.filteredTransactions = [];
              return;
            }
            if (period === 'today') {
              state.filteredTransactions = state.todaysTransactions.filter((document: TransactionResource) => document.id?.includes(value));
            } else {
              state.filteredTransactions = state.transactions.filter((document: TransactionResource) => document.id?.includes(value));
            }
          },
    },
  extraReducers: builder => {},
});

export const selectTransactions = (state: RootState): TransactionResource[] => state.documentTypeTransactions.transactions;
export const selectTransactionsPaginationCount = (state: RootState): number => state.documentTypeTransactions.transactionsPaginationCount;
export const selectTodaysTransactions = (state: RootState): TransactionResource[] => state.documentTypeTransactions.todaysTransactions;
export const selectFilteredTransactions = (state: RootState): TransactionResource[] => state.documentTypeTransactions.filteredTransactions;
export const selectTransaction = (state: RootState): TransactionResource | undefined => state.documentTypeTransactions.transaction;
export const selectTransactionId = (state: RootState): string | undefined => state.documentTypeTransactions.transactionId;
export const selectTransactionExceptions = (state: RootState): ExceptionResource[] => state.documentTypeTransactions.exceptions;
export const selectTransactionException = (state: RootState): ExceptionResource | undefined => state.documentTypeTransactions.exception;

export const {
    clearTransaction,
    clearException,
    setTransactions,
    setTransactionsPaginationCount,
    setTransactionsExceptions,
    setTransactionId,
    setTransaction,
    setTransactionException,
    filterTransactions: filterDocuments,
} = slice.actions;

export default slice.reducer;