import { Breadcrumbs, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { InactiveBreadcrumb } from '../atoms/InactiveBreadcrumb';
import { LinkRouter } from '../atoms/LinkRouter';
import CustomersIcon from '@mui/icons-material/Business';
import { useClearActiveCustomerTenant } from '../../utils/hooks/useClearActiveCustomerTenant';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbs: {
      [theme.breakpoints.up('xl')]: {
        paddingLeft: theme.spacing(12),
      },
      [theme.breakpoints.down('xl')]: {
        paddingLeft: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
      },
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    label: {
      fontSize: 14,
      textTransform: 'uppercase',
    },
  }),
);

interface CustomersBreadCrumbsProps {
  inUserManagement?: boolean;
};

const CustomersBreadCrumbs: React.FC<CustomersBreadCrumbsProps> = (props) => {
  const { inUserManagement=false } = props;
  const classes = useStyles();
  const { t: translate } = useTranslation();  
  const clearActiveCustomerTenant = useClearActiveCustomerTenant(); 

  const onCustomersClick = () => {
    //remove tenant id and customer from local storage and state to show customers/tenant selection page
    clearActiveCustomerTenant(true);
  };

  // this is breadcrumbs for displaying when on User Management coming directly from customers page
  // to allow a clear way for the user to get back to the customers page without having to
  // use the browser back button
  return (
    <Breadcrumbs className={classes.breadcrumbs} separator="/" aria-label="breadcrumb">
      <LinkRouter to="/" title={`${translate('settingsMenu.customers')}`} onClick={onCustomersClick}>
        <CustomersIcon color="primary" fontSize="medium" sx={{ marginTop: '4px' }} />
      </LinkRouter>
      {inUserManagement && (
        <LinkRouter to="/" variant="label1" className={classes.label} onClick={onCustomersClick}>
            {translate('settingsMenu.customers')}
        </LinkRouter>
      )}
      <InactiveBreadcrumb variant="label1" className={classes.label}>
        {inUserManagement ? translate('breadcrumbs.userManagement') : translate('settingsMenu.customers')}
      </InactiveBreadcrumb>
    </Breadcrumbs>
  );
};

export default CustomersBreadCrumbs;