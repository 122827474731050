import { Grid, Paper, Theme, Divider, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { LinkRouter } from '../atoms/LinkRouter';
import LaunchIcon from '@mui/icons-material/Launch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileDataCard: {
      padding: theme.spacing(1, 3),
    },
    withExceptions: {
      border: '0.5px solid',
      borderColor: theme.palette.error.light,
      backgroundColor: '#FFEFEE',
    },
  }),
);

interface MobileDataCardProps {
  children: React.ReactNode | React.ReactNode[];
  hasExceptions?: boolean;
  to?: string;
  title?: string;
}

export const MobileDataCard: React.FC<MobileDataCardProps> = props => {
  const classes = useStyles();
  const { hasExceptions = false, children, title, to } = props;

  return (
    <Grid item>
      <Paper className={`${classes.mobileDataCard} ${hasExceptions ? classes.withExceptions : ''}`}>
        {!!title && !!to ? <LinkRouter key={title} fontSize="1rem" fontWeight="medium" to={to}>
          {title}
          <LaunchIcon sx={{ marginLeft: 0.25, fontSize: '0.875rem' }} color="primary" fontSize="small" />
          <Divider sx={{ marginBlock: 0.25 }} />
        </LinkRouter> : !!title ? <Typography variant="body1" fontWeight="medium">
          {title}
          <Divider sx={{ marginBlock: 0.25 }} />
        </Typography> : ''}
        {children}
      </Paper>
    </Grid>
  );
};
